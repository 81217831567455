// Contains all types of actions to be used in the dashboard page
export enum VuesPageActionType {
  sortColumnChanged = "SORT_COLUMN_CHANGED",
  listBasedFilterApplied = "LIST_BASED_FILTER_APPLIED",
  clearAllFilterClicked = "CLEAR_ALL_FILTER_CLICKED",
  searchTextChanged = "SEARCH_TEXT_CHANGED",
  createdDateChanged = "CREATED_DATE_CHANGED",
  listEscalatedByDate = "LIST_ESCALATED_BY_DATE",
  completedDateChanged = "COMPLETED_DATE_CHANGED",
  completedTimeChanged = "COMPLETED_TIME_CHANGED",
  textBasedFilterApplied = "TEXT_BASED_FILTER_APPLIED",
  rangeBasedFilterApplied = "RANGE_BASED_FILTER_APPLIED",
  siteSelectedChanged = "SITE_SELECTED_CHANGED",
  batchSelectedChanged = "BATCH_SELECTED_CHANGED",
  surveySelectedChanged = "SURVEY_SELECTED_CHANGED",
  filterApplied = "FILTER_APPLIED_ON_GRID",
}

export interface VuesPageSummaryState {
  types: number[];
  statuses: number[];
  search: string;
  canonicalID: string;
  vueTitle: string;
  siteNameAndNumber: string; // searches contains on siteName + ' ' + siteNumber
  completedAtStartDate: string;
  completedAtEndDate: string;
  completedAtStartTime: string;
  completedAtEndTime: string;
  createdAtStartDate: string;
  createdAtEndDate: string;
  createdAtStartTime: string;
  createdAtEndTime: string;
  slaStartsAtStartDate: string;
  slaStartsAtEndDate: string;
  slaStartsAtStartTime: string;
  slaStartsAtEndTime: string;
  addressTwo: string;
  postalAddress: string;
  city: string;
  stateProvinceAbbreviation: string;
  zipPostalCode: string;
  countryAbbreviation: string[];
  escalated?: boolean;
  vueTags: string[];
  mediaTags: string[];
  metaKeySearches: { [key: string]: string };
  companyIds: string[];
  slaTargetMin: string;
  slaTargetMax: string;
  slaActualMin: string;
  slaActualMax: string;
  sortColumnDisplayName: string;
  sortIsAscending?: boolean;
  urgent?: boolean;
  highQuality?: boolean;
  internal?: boolean;
  batchID: string;
  surveyName: string;
  surveyTemplateId: string;
  canUseReferral: boolean;
}

export const defaultVuesPageSummaryState = {
  types: [] as number[],
  statuses: [] as number[],
  search: "",
  canonicalID: "",
  vueTitle: "",
  siteNameAndNumber: "",
  completedAtStartDate: "",
  completedAtEndDate: "",
  completedAtStartTime: "",
  completedAtEndTime: "",
  createdAtStartDate: "",
  createdAtEndDate: "",
  createdAtStartTime: "",
  createdAtEndTime: "",
  slaStartsAtStartDate: "",
  slaStartsAtEndDate: "",
  slaStartsAtStartTime: "",
  slaStartsAtEndTime: "",
  addressTwo: "",
  postalAddress: "",
  city: "",
  stateProvinceAbbreviation: "",
  zipPostalCode: "",
  countryAbbreviation: [] as string[],
  vueTags: [] as string[],
  mediaTags: [] as string[],
  metaKeySearches: {},
  companyIds: [] as string[],
  slaTargetMin: "",
  slaTargetMax: "",
  slaActualMin: "",
  slaActualMax: "",
  sortColumnDisplayName: "",
  canUseReferral: null as boolean,
  sortIsAscending: null as boolean,
  escalated: null as boolean,
  urgent: null as boolean,
  highQuality: null as boolean,
  internal: null as boolean,
  batchID: "",
  surveyName: "",
  surveyTemplateId: "",
};

// Dashboard Summary Reducer
export interface VuesPageAction {
  type: VuesPageActionType;
  payload: VuesPageSummaryState;
}

export const vuesPageSummaryReducer = (
  state: VuesPageSummaryState,
  action: VuesPageAction
) => {
  const { type, payload } = action;
  switch (type) {
    case VuesPageActionType.sortColumnChanged:
      const sortColumnDisplayName = getColumnNameFromHeaderForSort(
        payload.sortColumnDisplayName
      );
      return {
        ...state,
        sortColumnDisplayName: sortColumnDisplayName,
        sortIsAscending: payload.sortIsAscending,
      };
    case VuesPageActionType.listBasedFilterApplied:
      return {
        ...state,
        companyIds: payload.companyIds,
        types: payload.types,
        statuses: payload.statuses,
        vueTags: payload.vueTags,
        mediaTags: payload.mediaTags,
        escalated: payload.escalated,
        urgent: payload.urgent,
        canUseReferral: payload.canUseReferral,
        createdAtStartDate: payload.createdAtStartDate,
        createdAtEndDate: payload.createdAtEndDate,
        completedAtStartDate: payload.completedAtStartDate,
        completedAtEndDate: payload.completedAtEndDate,
        countryAbbreviation: payload.countryAbbreviation,
      };
    case VuesPageActionType.searchTextChanged:
      return {
        ...state,
        search: payload.search,
      };
    case VuesPageActionType.siteSelectedChanged:
      return {
        ...state,
        siteNameAndNumber: payload.siteNameAndNumber,
      };
    case VuesPageActionType.batchSelectedChanged:
      return {
        ...state,
        batchID: payload.batchID,
      };
    case VuesPageActionType.surveySelectedChanged:
      return {
        ...state,
        surveyTemplateId: payload.surveyTemplateId,
      };
    case VuesPageActionType.createdDateChanged:
      return {
        ...state,
        createdAtStartDate: payload.createdAtStartDate,
        createdAtEndDate: payload.createdAtEndDate,
        companyIds: payload.companyIds,
      };
    case VuesPageActionType.listEscalatedByDate:
      return {
        ...state,
        createdAtStartDate: payload.createdAtStartDate,
        createdAtEndDate: payload.createdAtEndDate,
        escalated: payload.escalated,
        urgent: payload.urgent,
        canUseReferral: payload.canUseReferral,
        companyIds: payload.companyIds,
      };
    case VuesPageActionType.completedDateChanged:
      return {
        ...state,
        completedAtStartDate: payload.completedAtStartDate,
        completedAtEndDate: payload.completedAtEndDate,
        companyIds: payload.companyIds,
      };
    case VuesPageActionType.completedTimeChanged:
      return {
        ...state,
        completedAtStartTime: payload.completedAtStartTime,
        completedAtEndTime: payload.completedAtEndTime,
      };
    case VuesPageActionType.textBasedFilterApplied:
      return {
        ...state,
        canonicalID: payload.canonicalID,
        vueTitle: payload.vueTitle,
        siteNameAndNumber: payload.siteNameAndNumber,
        postalAddress: payload.postalAddress,
        addressTwo: payload.addressTwo,
        stateProvinceAbbreviation: payload.stateProvinceAbbreviation,
        city: payload.city,
        zipPostalCode: payload.zipPostalCode,
      };
    case VuesPageActionType.rangeBasedFilterApplied:
      return {
        ...state,
        slaTargetMin: payload.slaTargetMin,
        slaTargetMax: payload.slaTargetMax,
        slaActualMin: payload.slaActualMin,
        slaActualMax: payload.slaActualMax,
      };
    case VuesPageActionType.clearAllFilterClicked:
      return { ...state, ...payload };
    case VuesPageActionType.filterApplied:
      return { ...state, ...payload };
    default:
      return state;
  }
};

// Get column name for sorting, as backend has different name for column header
const getColumnNameFromHeaderForSort = (headerName: string): string => {
  switch (headerName) {
    case "Created Date":
      return "Created At";
    case "Completed Date":
    case "Completed Time":
      return "Completed At";
    case "Portal":
      return "Client Name";
    default:
      return headerName;
  }
};
