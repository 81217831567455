import {
  CancelVueDialogContent,
  GridData,
  GridRowSelectionModel,
  MDButton,
  ModalPopUp,
} from "./imports";
import MakeUrgentDialog from "../components/MakeUrgentDialog";
import { VueExportItem } from "../types/types";
import { VuesPageSummaryState } from "../utils/reducer";
import { Vue } from "../../interfaces/dashboard_interfaces";
import { CustomDialogBox, ExportDialogContent, ExportModalType } from "@ivueit/vue-engine";

/********* MAKE URGENT ACTION BUTTON ****************/

/// Generates the dialog box content for reissue
export const getMakeUrgentDialog = (
  message: string,
  showMakeUrgentPopup: boolean,
  closeMakeUrgentDialog: () => void,
  onMakeUrgentClick: () => void
) => {
  return (
    <CustomDialogBox title="Make Urgent" openDialog={showMakeUrgentPopup}>
      <MakeUrgentDialog
        onCancelClick={closeMakeUrgentDialog}
        message={message}
        onMakeUrgentClick={onMakeUrgentClick}
      />
    </CustomDialogBox>
  );
};

/********* REISSUE ACTION BUTTON ****************/

/// Generates the dialog box content for reissue
export const getReIssueDialog = (
  showReIssuePopup: boolean,
  closeReIssueDialog: () => void,
  selectedRowsList: GridRowSelectionModel,
  handleReissueVue: () => void
) => {
  return (
    <CustomDialogBox title="Reissue Vues" openDialog={showReIssuePopup}>
      <ModalPopUp
        content={`Are you sure you want to reissue the selected ${selectedRowsList.length} Vues?`}
        closeDialog={closeReIssueDialog}
        actions={getReIssueActions(closeReIssueDialog, handleReissueVue)}
      />
    </CustomDialogBox>
  );
};

// Generates actions for reissue
const getReIssueActions = (
  closeReIssueDialog: () => void,
  handleReissueButtonClick: () => void
) => {
  return [
    <MDButton
      key={1}
      variant="outlined"
      color="dark"
      size="medium"
      onClick={closeReIssueDialog}
      sx={{
        borderColor: "#C7CCD0",
        padding: "11px 16px",
        fontSize: "14px",
        fontWeight: "bold",
        "&:hover": { borderColor: "#C7CCD0" },
      }}
    >
      CANCEL
    </MDButton>,
    <MDButton
      key={2}
      variant="gradient"
      color="info"
      size="medium"
      onClick={() => {
        handleReissueButtonClick();
      }}
      sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
    >
      REISSUE
    </MDButton>,
  ];
};

/********* CANCEL ACTION BUTTON ****************/

/// Generates the dialog box content for "cancel"
export const getCancelDialog = (
  showCancelPopup: boolean,
  closeCancelVueDialog: (cancelStatus: boolean) => void,
  selectedRowsList: GridRowSelectionModel
) => {
  return (
    <CustomDialogBox title="Cancel Vues" openDialog={showCancelPopup}>
      <CancelVueDialogContent
        selectedVueList={selectedRowsList}
        closeDialog={(confirmCancel: boolean) => {
          closeCancelVueDialog(confirmCancel);
        }}
      />
    </CustomDialogBox>
  );
};

/********* EXPORT ACTION BUTTON ****************/

/// Generates the dialog box content for "cancel"
export const getExportDialog = (
  excludedVueIds: string[],
  rows: GridData[],
  selectedRowsList: GridRowSelectionModel,
  showExportPopup: boolean,
  didSelectAll: boolean,
  clientVueState: VuesPageSummaryState,
  closeExportVueDialog: () => void
) => {
  const listOfSelectedRows: GridData[] = rows.filter((row) =>
    selectedRowsList.includes(row.id)
  );
  const selectedExportItem: VueExportItem[] = listOfSelectedRows.map((item) => {
    return getExporVueItemFromGridData(item);
  });
  const selectdIds: string[] = selectedRowsList.map((item) => `${item}`);
  return (
    <CustomDialogBox title="Export" openDialog={showExportPopup} width="560px">
      <ExportDialogContent
        closeDialog={closeExportVueDialog}
        type={ExportModalType.vues}
        hasNonCompletedVues={listOfSelectedRows.some(
          (row) => row.status.toLowerCase() !== "completed".toLowerCase()
        )}
        selectedItemIds={selectdIds}
        selectedVues={selectedExportItem}
        didSelectAll={didSelectAll}
        clientVueState={clientVueState}
        excludedVueIds={excludedVueIds}
        excludedBatchIds={[]}
      />
    </CustomDialogBox>
  );
};

export const getExporVueItemFromGridData = (vue: GridData): VueExportItem => {
  return {
    id: vue.id,
    vueId: vue.vueId,
    pdfFileId: vue.pdfFileId,
    pdfFileIdOrdered: vue.pdfFileIdOrdered,
    pdfFileIdPhotos: vue.pdfFileIdPhotos,
    pdfFileIdSurvey: vue.pdfFileIdSurvey,
  };
};
export const getExporVueItemFromVue = (vue: Vue): VueExportItem => {
  return {
    id: vue.id,
    vueId: vue.canonicalId,
    pdfFileId: vue.pdfFileId,
    pdfFileIdOrdered: vue.pdfFileIdOrdered,
    pdfFileIdPhotos: vue.pdfFileIdPhotos,
    pdfFileIdSurvey: vue.pdfFileIdSurvey,
  };
};
