import { CustomDialogBox, CustomIndicator } from "@ivueit/vue-engine";
import MDBox from "components/MDBox";
import { CustomAddoption } from "pages/surveys/components/CustomAddOption";
import { useEffect, useMemo, useState } from "react";
import {
  createNewTag,
  destroyTag,
  getTagsByCompanyID,
  updateTag,
} from "../services/services";
import { WebServiceStatus } from "utils/services/AppUrls";
import { Tag } from "../utlis/interface";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { listSyles } from "../styles/ClientStyles";
import { List } from "@mui/material";
import TagItemComponent from "./TagItemComponent";
import { ConfirmationDialog } from "components/CustomComponents/ConfirmationDialog";
import EditTagDialogComponent from "./EditTagDialogContent";

enum TagActionType {
  update,
  delete,
}

const TagsTabContent = ({ companyID }: { companyID: string }) => {
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [tagsList, setTagsList] = useState<Tag[]>([]);
  const [actionType, setActionType] = useState<TagActionType>(null);
  const [selectedTag, setSelectedTag] = useState<Tag>();
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const listOfTagNames = useMemo(() => {
    return tagsList.map((tag) => tag.name);
  }, [tagsList]);

  const handleCreateTag = async (optionsList: string[]) => {
    if (!companyID) return;
    setShowLoader(true);
    const newTagName = optionsList[optionsList.length - 1];
    const response = await createNewTag(newTagName, companyID);
    if (response.status === WebServiceStatus.success) {
      const tagID = response.data.id as string;
      if (tagID) {
        fetchTags();
        setSnackBar({
          title: "Success!",
          message: "Tag created successfully",
          isError: false,
        });
      }
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to create new tag : ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const fetchTags = async () => {
    if (!companyID) {
      setSnackBar({
        title: "Attention!",
        message: "Company ID not available to fetch tags",
        isError: true,
      });
      return;
    }
    setShowLoader(true);
    const response = await getTagsByCompanyID(companyID);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.tags as Tag[];
      setTagsList(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to fetch the company tags : ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const handleTagEditDeleteOperation = async (toUpdate: boolean) => {
    if (!companyID) {
      setSnackBar({
        title: "Attention!",
        message: `Company ID not available to ${
          toUpdate ? "update" : "delete"
        } tag`,
        isError: true,
      });
      return;
    }
    setShowLoader(true);
    const { name, id } = selectedTag;
    const response = toUpdate
      ? await updateTag(name, companyID, id)
      : await destroyTag(id, companyID);
    if (response.status === WebServiceStatus.success) {
      fetchTags();
      setSnackBar({
        title: "Success!",
        message: `Tag ${toUpdate ? "updated" : "deleted"} successfully`,
        isError: false,
      });
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
    setActionType(null);
    setOpenConfirmationDialog(false);
  };

  const getEditTagNameDialog = () => {
    return (
      <CustomDialogBox
        title={"Edit Tag Name"}
        width="450px"
        openDialog={actionType === TagActionType.update}
      >
        <EditTagDialogComponent
          tagName={selectedTag ? selectedTag.name : ""}
          onClickCancel={() => {
            setActionType(null);
          }}
          onClickSave={(newTagName) => {
            setSelectedTag({ ...selectedTag, name: newTagName });
            setOpenConfirmationDialog(true);
          }}
          availableTags={tagsList}
        />
      </CustomDialogBox>
    );
  };

  const getConfirmationDialog = () => {
    const toUpdate = actionType === TagActionType.update;
    const tagName = selectedTag.name;
    const title = toUpdate ? "Edit Tag" : "Delete Tag";
    const message = toUpdate
      ? `Are you sure you want to edit this tag name? This is a permanent action that cannot be undone. All vues tagged with the tag "${tagName}" will be updated to have this new tag name`
      : `Are you sure you want to remove this tag? This is a permanent action that cannot be undone. All vues tagged with the tag "${tagName}" will have the tag removed.`;
    return (
      <CustomDialogBox
        title={title.toUpperCase()}
        width="450px"
        openDialog={openConfirmationDialog}
      >
        <ConfirmationDialog
          message={message}
          buttonName={"Yes"}
          onClose={() => {
            setOpenConfirmationDialog(false);
            setActionType(null);
          }}
          onClick={() => {
            handleTagEditDeleteOperation(toUpdate);
          }}
        />
      </CustomDialogBox>
    );
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <>
      {openConfirmationDialog && getConfirmationDialog()}
      {actionType === TagActionType.update && getEditTagNameDialog()}
      <MDBox pl="16px">
        {showLoader && <CustomIndicator />}
        <MDBox pt={3.2}>
          <CustomAddoption
            onAddOption={handleCreateTag}
            optionsList={listOfTagNames}
          />
        </MDBox>
        <MDBox display="flex" flexWrap="wrap" pt="16px">
          <List sx={listSyles}>
            {tagsList.map((tag, index) => (
              <TagItemComponent
                key={index}
                tagName={tag.name}
                onDeleteClick={() => {
                  setSelectedTag(tag);
                  setOpenConfirmationDialog(true);
                  setActionType(TagActionType.delete);
                }}
                onEditClick={() => {
                  setSelectedTag(tag);
                  setActionType(TagActionType.update);
                }}
              />
            ))}
          </List>
        </MDBox>
        <CustomSnackbar
          snackbarContent={snackBar}
          onClose={() => {
            setSnackBar(null);
          }}
        />
      </MDBox>
    </>
  );
};

export default TagsTabContent;
