import { WebServiceResponse } from "@ivueit/vue-engine";
import { UserPageAPIServices } from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";

export const getAllCompanyGroups = async (
  parameterString: string
): Promise<WebServiceResponse> => {
  const url = `${UserPageAPIServices.getCompanyGroups}?${parameterString}`;
  return await performGetRequest(url);
};

export const createCompanyGroup = async (
  name: string,
  groupDescription: string
): Promise<WebServiceResponse> => {
  const url = `${UserPageAPIServices.createCompanyGroup}`;
  const data = {
    name: name,
    description: groupDescription,
  };
  return await performPostRequest(url, data);
};

export const deleteCompanyGroup = async (
  groupId: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(UserPageAPIServices.deleteCompanyGroup, {
    id: groupId,
  });
};

//// COMPANIES /////

export const getCompanyGroup = async (
  companyGroupID: string
): Promise<WebServiceResponse> => {
  const url = `${UserPageAPIServices.getCompanyGroup}/${companyGroupID}`;
  return await performGetRequest(url);
};

export const updateCompanyGroupCompanies = async (
  companyId: string,
  memberCompanies: any[]
): Promise<WebServiceResponse> => {
  return await performPostRequest(
    UserPageAPIServices.updateCompanyGroupCompanies,
    {
      id: companyId,
      memberCompanies: memberCompanies,
    }
  );
};

export const addAdminRoleForNewCompany = async (
  userEmails: string[],
  newCompanyID: string,
  isReadOnly: boolean
): Promise<WebServiceResponse> => {
  return await performPostRequest(
    UserPageAPIServices.addAdminRoleForNewCompany,
    {
      userEmails: userEmails,
      newCompanyID: newCompanyID,
      isReadOnly: isReadOnly,
    }
  );
};

//// USERS /////

export const getOrgUsersByCompanyGroupId = async (
  companyGroupID: string,
  parameterString: string
): Promise<WebServiceResponse> => {
  const url = `${UserPageAPIServices.getOrgUsersByCompanyGroupId.replace(
    "{id}",
    companyGroupID
  )}?${parameterString}`;
  return await performGetRequest(url);
};

export const addOrgUserToCompanyGroup = async (
  orgUserId: string,
  companyGroupId: string
): Promise<WebServiceResponse> => {
  const url = `${UserPageAPIServices.addOrgUserToCompanyGroup}`
    .replace("{companyGroupId}", companyGroupId)
    .replace("{orgUserId}", orgUserId);
  return await performPostRequest(url);
};

export const removeOrgUserFromCompanyGroup = async (
  orgUserId: string,
  companyGroupId: string
): Promise<WebServiceResponse> => {
  const url = `${UserPageAPIServices.removeOrgUserFromCompanyGroup}`;
  const data = {
    orgUserId: orgUserId,
    companyGroupId: companyGroupId,
  };
  return await performPostRequest(url, data);
};

export const searchOrgUsers = async (
  parameterString: string
): Promise<WebServiceResponse> => {
  const url = `${UserPageAPIServices.searchOrgUsers}?${parameterString}`;
  return await performGetRequest(url);
};
