import DialogContent from "@mui/material/DialogContent";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import tickImage from "assets/images/icon_tick.svg";
import failedImage from "assets/images/close.png";
import hourGlass from "assets/images/hourglass.png";
import { useEffect, useState } from "react";
import {
  fetchBlacklistedPhoneNumbers,
  generateVuerListExport,
} from "../services/VuerServices";
import { DEFAULT_PAGE_SIZE, GENERIC_ERROR_MESSAGE } from "../../../constants";
import { WebServiceStatus } from "utils/services/AppUrls";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { generatePresignedFileURL } from "pages/dashboard/home/vues/services/VueServices";
import { downloadFileToDevice } from "@ivueit/vue-engine";
import { getFileNameInUTC } from "utils/helpers/extensions";

interface Props {
  closeDialog: () => void;
}

enum ExportStatus {
  preparing,
  generateList,
  generateFile,
  completed,
  failed,
}

interface ExportContent {
  title: string;
  subTitle: string;
  description?: string;
  image: string;
}

export const ExportVuerDialog = (props: Props) => {
  const [exportStatus, setExportStatus] = useState<ExportStatus>(
    ExportStatus.preparing
  );
  const [exportContent, setExportContent] = useState<ExportContent>({
    title: "Preparing to generate Vuer Export",
    subTitle: "This may take some time.",
    image: hourGlass,
  });
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);

  useEffect(() => {
    const generatePresignedFile = async (fileId: string) => {
      setExportStatus(ExportStatus.generateFile);
      const response = await generatePresignedFileURL(fileId);
      if (response.status === WebServiceStatus.success) {
        setExportStatus(ExportStatus.completed);
        const presignedUrl = response.data.url;
        const fileName = `export_Vuer_Export_${getFileNameInUTC()}_UTC.csv`;
        try {
          await downloadFileToDevice(presignedUrl, fileName, "text/csv");
        } catch (error) {
          setSnackBarContent({
            title: "Attention!",
            message: `Failed to download ${fileName}`,
            isError: true,
          });
        }
      } else {
        setExportStatus(ExportStatus.failed);
        setSnackBarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
    };

    const generateVuerListExportURL = async (phoneNumbers: string[]) => {
      setExportStatus(ExportStatus.generateList);
      const response = await generateVuerListExport(phoneNumbers);
      if (response.status === WebServiceStatus.success) {
        const responseData = response.data;
        const fileId = responseData.fileId;
        await generatePresignedFile(fileId);
      } else {
        setExportStatus(ExportStatus.failed);
        setSnackBarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
    };
    const getBlackListedUsers = async () => {
      setExportStatus(ExportStatus.preparing);
      const parameter = `pageNumber=0&pageSize=${DEFAULT_PAGE_SIZE}`;
      const response = await fetchBlacklistedPhoneNumbers(parameter);
      if (response.status === WebServiceStatus.success) {
        const phoneNumbers = response.data.phoneNumbers ?? [];
        await generateVuerListExportURL(phoneNumbers);
      } else {
        setSnackBarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
    };

    getBlackListedUsers();
  }, []);

  const generateDialogContent = () => {
    let title = "";
    let subTitle = "";
    let description = "";
    let image = hourGlass;
    switch (exportStatus) {
      case ExportStatus.preparing:
        title = "Preparing to generate Vuer Export";
        subTitle = "This may take some time.";
        break;
      case ExportStatus.generateList:
        title = "Generating Vuer List";
        subTitle = "This may take some time.";
        break;
      case ExportStatus.generateFile:
        title = "Generating Vuer Export";
        subTitle = "We are almost there.";
        break;
      case ExportStatus.completed:
        title = "Export Success";
        subTitle = "Vuer Export completed";
        description = "File will be downloaded to your device.";
        image = tickImage;
        break;
      case ExportStatus.failed:
        title = "Export Failed";
        subTitle = "Failed to generate the Vuer export.";
        description =
          "Please try again. If the issue persists, please contact iVueit Admin.";
        image = failedImage;
        break;
    }
    setExportContent({
      title: title,
      subTitle: subTitle,
      description: description,
      image: image,
    });
  };

  useEffect(generateDialogContent, [exportStatus]);

  return (
    <>
      <DialogContent>
        <MDBox pt={4} px={16} pb={4} display="flex" justifyContent="center">
          <MDAvatar
            size="xxl"
            bgColor="info"
            alt="Avatar"
            sx={{
              fontSize: "3.6em",
              width: "134px",
              height: "134px",
              img: {
                width: "64px",
                height: "auto",
              },
            }}
          >
            <img src={exportContent.image} alt="" />
          </MDAvatar>
        </MDBox>
        <MDTypography
          pb={2}
          textAlign="center"
          sx={{ color: "344767", fontSize: "22px", fontWeight: "bold" }}
        >
          {exportContent.title}
        </MDTypography>
        <MDTypography
          pb={2}
          textAlign="center"
          sx={{ color: "344767", fontSize: "14px", fontWeight: "regular" }}
        >
          {exportContent.subTitle}
        </MDTypography>
        {exportContent.description && (
          <MDTypography
            pb={2}
            textAlign="center"
            sx={{ color: "344767", fontSize: "14px", fontWeight: "regular" }}
          >
            {exportContent.description}
          </MDTypography>
        )}
        <MDBox display="flex" justifyContent="center" pb={2} pt={5}>
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              alignContent: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
            onClick={props.closeDialog}
            disabled={
              exportStatus !== ExportStatus.completed &&
              exportStatus !== ExportStatus.failed
            }
          >
            OK
          </MDButton>
        </MDBox>
        <CustomSnackbar
          snackbarContent={snackBarContent}
          onClose={() => {
            setSnackBarContent(null);
          }}
        />
      </DialogContent>
    </>
  );
};
