import {
  CustomIndicator,
  GENERIC_ERROR_MESSAGE,
  WebServiceStatus,
  gridStyle,
  textEllipsis,
} from "@ivueit/vue-engine";
import Card from "@mui/material/Card";
import {
  DataGridPremium,
  GridRenderCellParams,
  useGridApiRef,
  gridClasses,
} from "@mui/x-data-grid-premium";
import MDBox from "components/MDBox";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { vuersGridStyle } from "../styles/VuersStyles";
import { useCallback, useEffect, useState } from "react";
import { Transaction } from "../interface/interface";
import MDTypography from "components/MDTypography";
import {
  getTransactionRowData,
  getTransactionTypeInString,
  transactionGridColumnData,
} from "../utils/helpers";
import DataGridBottomBar from "./BottomBar";
import { getTransactionsByUserID } from "../services/VuerServices";
import { gridTextStyle } from "pages/surveys/styles/SurveyStyles";

export const TransactionsGrid = ({ vuerID }: { vuerID: string }) => {
  const gridRef = useGridApiRef();
  const [rowData, setRowData] = useState<Transaction[]>([]);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const getCustomCells = (params: GridRenderCellParams) => {
    const { field, value } = params;
    const { row }: { row: Transaction } = params;
    const { canonical_id, typeOf, amount, shortDescription, notes, createdAt } =
      row;
    const type = getTransactionTypeInString(typeOf);
    const roundedOffAmount = `$${Math.abs(amount).toFixed(2)}`;
    const amountValue =
      amount < 0 ? `-${roundedOffAmount}` : `${roundedOffAmount}`;
    if (field === "id") {
      return <MDTypography sx={gridTextStyle}>#{canonical_id}</MDTypography>;
    } else if (field === "type") {
      return <MDTypography sx={gridTextStyle}>{type}</MDTypography>;
    } else if (field === "amount") {
      return <MDTypography sx={gridTextStyle}>{amountValue}</MDTypography>;
    } else if (field === "time") {
      const dateTimeFormat = "MM/DD/YYYY - hh:mm:ss A";
      const createdDateTime = createdAt.formatUTCNanoSecondsToString(
        dateTimeFormat,
        true
      );
      return <MDTypography sx={gridTextStyle}>{createdDateTime}</MDTypography>;
    } else if (field === "notes") {
      return (
        <MDBox display="flex" maxWidth="500px" minWidth="0">
          <MDTypography sx={{ ...gridTextStyle, whiteSpace: "normal" }}>
            {notes}
          </MDTypography>
        </MDBox>
      );
    } else if (field === "description") {
      return shortDescription ? (
        <MDTypography sx={gridTextStyle}>{shortDescription}</MDTypography>
      ) : (
        "-"
      );
    }
    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
      >
        <MDBox sx={textEllipsis}>{value}</MDBox>
      </MDBox>
    );
  };

  const fetchTransactionListFromServer = useCallback(async () => {
    setShowLoader(true);
    const response = await getTransactionsByUserID(vuerID);
    var gridData: Transaction[] = [];
    if (response.status === WebServiceStatus.success) {
      const {
        transactions,
      }: {
        transactions: Transaction[];
      } = response.data;
      gridData = transactions.map((transaction) => {
        return getTransactionRowData(transaction);
      });
      setRowData(gridData);
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  }, []);

  useEffect(() => {
    fetchTransactionListFromServer();
  }, []);

  const columnData = transactionGridColumnData.map((data) => {
    data.renderCell = getCustomCells;
    return data;
  });

  const getFooter = () => {
    return (
      <MDBox display="flex" alignItems="center">
        <DataGridBottomBar title={"Total Count"} totalItems={rowData.length} />
      </MDBox>
    );
  };

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <MDTypography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No transactions available.
        </MDTypography>
      </MDBox>
    );
  };

  return (
    <MDBox pt={2}>
      {showLoader && <CustomIndicator />}
      <Card sx={{ height: "calc(100vh - 176px)" }}>
        <DataGridPremium
          apiRef={gridRef}
          columns={columnData}
          rows={rowData}
          sx={{
            ...gridStyle,
            ...vuersGridStyle,
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          density="compact"
          slots={{
            footer: getFooter,
            noRowsOverlay: getNoRowsMessage,
          }}
          slotProps={{
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "linear-progress",
            },
          }}
          disableColumnMenu
          disableRowSelectionOnClick
          loading={showLoader}
          getRowHeight={() => "auto"}
        />
      </Card>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};
