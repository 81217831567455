import {
  CustomDialogBox,
  CustomIndicator,
  gridStyle,
} from "@ivueit/vue-engine";
import { DialogContent, DialogActions } from "@mui/material";
import {
  DataGridPremium,
  MDBox,
  MDButton,
} from "../../vue-grid/helpers/imports";
import MDTypography from "components/MDTypography";
import DataGridBottomBar from "pages/vuers/components/BottomBar";
import {
  useGridApiRef,
  gridClasses,
  GridColDef,
} from "@mui/x-data-grid-premium";
import {
  editHistoryColumns,
  getGridData,
  getStatusGridData,
  getVueGridData,
  statusHistoryColumns,
} from "../helpers/helper_methods";
import {
  EditHistoryGridData,
  StatusHistoryGridData,
  VueHistory,
  VueHistoryType,
} from "../utils/vue_detail_interface";
import { useEffect, useState } from "react";

interface Props {
  vueId: string;
  title: string;
  type: VueHistoryType;
  openDialog: boolean;
  vueHistory: VueHistory;
  handleCloseClick: () => void;
}

export const VueHistoryDialog = (props: Props) => {
  const gridRef = useGridApiRef();
  const [showLoader, setShowLoader] = useState(false);
  const [rowData, setRowData] = useState<
    EditHistoryGridData[] | StatusHistoryGridData[]
  >();
  const columnsList =
    props.type === VueHistoryType.statusHistory
      ? statusHistoryColumns
      : editHistoryColumns;
  const columnData: GridColDef[] = getGridData(columnsList);

  const getVueHistoryData = async () => {
    if (props.vueHistory) {
      const { vues, submissions } = props.vueHistory;
      const historyData =
        props.type === VueHistoryType.statusHistory
          ? submissions.map((submission, index) =>
              getStatusGridData(submission, index)
            )
          : vues.map((vue, index) => getVueGridData(vue, index));
      setRowData(historyData);
    } else {
      setRowData([]);
    }
  };

  const getFooter = () => {
    return (
      <MDBox display="flex" alignItems="center">
        <DataGridBottomBar
          title={"Total Count"}
          totalItems={rowData && rowData.length}
        />
      </MDBox>
    );
  };

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <MDTypography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No records available.
        </MDTypography>
      </MDBox>
    );
  };

  useEffect(() => {
    getVueHistoryData();
  }, []);

  return (
    <>
      {showLoader && <CustomIndicator />}
      <CustomDialogBox
        title={props.title}
        width="1320px"
        openDialog={props.openDialog}
      >
        <>
          <DialogContent
            sx={{
              pt: "20px !important",
              pb: "0 !important",
              minHeight: "250px",
            }}
          >
            <>
              {rowData && (
                <MDBox
                  sx={{
                    minHeight: `calc(100vh - 240px)`,
                    height: `calc(100vh - 240px)`,
                    justifyContent: "flex-start",
                    position: "relative",
                  }}
                >
                  <DataGridPremium
                    apiRef={gridRef}
                    columns={columnData}
                    rows={rowData}
                    loading={showLoader}
                    sx={{
                      ...gridStyle,
                      [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                    }}
                    density="compact"
                    slots={{
                      footer: getFooter,
                      noRowsOverlay: getNoRowsMessage,
                    }}
                    slotProps={{
                      loadingOverlay: {
                        variant: "linear-progress",
                        noRowsVariant: "linear-progress",
                      },
                    }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    getRowHeight={() => "auto"}
                  />
                </MDBox>
              )}
            </>
          </DialogContent>
          <DialogActions>
            <>
              <MDButton
                key={1}
                variant="outlined"
                color="dark"
                size="medium"
                onClick={props.handleCloseClick}
                sx={{
                  borderColor: "#C7CCD0",
                  padding: "11px 16px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  "&:hover": { borderColor: "#C7CCD0" },
                }}
              >
                CLOSE
              </MDButton>
            </>
          </DialogActions>
        </>
      </CustomDialogBox>
    </>
  );
};
