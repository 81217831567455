import { mdiArrowCollapse } from "@mdi/js";
import { Card, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useVueProvider } from "pages/dashboard/home/vues/context/VueProvider";
import { useEffect, useState } from "react";
import { mapFullViewStyle } from "../../styles/VueDetailStyles";
import { VueMediaSection } from "./VueMediaSection";
import { CustomMapMarker } from "./googlemap/CustomMapMarker";
import {
  IndividualVue,
  PhotoFileInfo,
  VideoFileInfo,
} from "../utils/vue_detail_interface";
import { PlaceholderImage } from "./PlaceholderImage";
import { LatLng } from "@ivueit/vue-engine";
import { CustomMapComponent } from "./googlemap/CustomMapComponent";
import { OverlayViewF, OverlayView } from "@react-google-maps/api";
import { PlaceholderComponent } from "components/CustomComponents/PlaceholderComponent";
import VideoThumbnail from "./VideoThumbnail";

interface Props {
  toggleButtonClick: () => void;
  vueDetail: IndividualVue;
}

/// To control the keyboard events while interacting with the map
enum KeyboardEvents {
  arrowLeft = "ArrowLeft",
  arrowRight = "ArrowRight",
  keydown = "keydown",
}

export const MapFullViewDialogContent = (props: Props) => {
  const { vue, photos, videos } = props.vueDetail;
  const { latitude, longitude, canonicalId } = vue;
  const lastPhotoIndex = [...photos, ...videos].length - 1;
  const { latestSelectedVueImage, storeLatestSelectedVueImage } =
    useVueProvider();
  /// State to hold the selected photo
  const [photo, setPhoto] = useState<PhotoFileInfo>(null);
  /// State to hold the selected video
  const [video, setVideo] = useState<VideoFileInfo>(null);
  /// State to handle the marker and image index
  const [hoveredMarkerId, setHoveredMarkerId] = useState<string>("");
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number>();
  /// Default coordinates for the map
  const [coords, setCoords] = useState<LatLng | null>({
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    if (!latestSelectedVueImage) {
      setCoords({
        latitude: latitude,
        longitude: longitude,
      });
    } else {
      /// Checking whether there is any previously selected photo / locally stored
      /// If so, then checks whether the vue is same & then setting the marker ID, coords
      /// So that, map will render with that coords in the center
      const key = Object.keys(latestSelectedVueImage)[0];
      const locallyStoredMedia = latestSelectedVueImage[key];
      if (locallyStoredMedia && key === canonicalId) {
        const isTypeVideo = locallyStoredMedia.mimeType === "video/mp4";
        if (isTypeVideo) {
          setVideo(locallyStoredMedia);
        } else {
          setPhoto(locallyStoredMedia);
        }
        setHoveredMarkerId(locallyStoredMedia.id);
        setCoords({
          latitude: locallyStoredMedia.location.latitude,
          longitude: locallyStoredMedia.location.longitude,
        });
        const index = [...photos, ...videos].findIndex(
          (media) => locallyStoredMedia.id === media.id
        );
        setSelectedMediaIndex(index);
      } else {
        setCoords({
          latitude: latitude,
          longitude: longitude,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vueDetail]);

  /// To handle keyboard events
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      setHoveredMarkerId(null);
      let index = selectedMediaIndex;
      if (event.key === KeyboardEvents.arrowLeft) {
        index =
          selectedMediaIndex === 0 ? lastPhotoIndex : selectedMediaIndex - 1;
      } else if (event.key === KeyboardEvents.arrowRight) {
        index =
          selectedMediaIndex === lastPhotoIndex ? 0 : selectedMediaIndex + 1;
      }
      setSelectedMediaIndex(index);
      const media = [...photos, ...videos][index];
      if (media) {
        if (media.mimeType === "video/mp4") {
          const video = media as VideoFileInfo;
          setVideo(video);
          setPhoto(null);
        } else {
          const photo = media as PhotoFileInfo;
          setPhoto(photo);
          setVideo(null);
        }
        storeLatestSelectedVueImage(canonicalId, media);
      } else {
        storeLatestSelectedVueImage(canonicalId, null);
      }
    };
    document.addEventListener(KeyboardEvents.keydown, handleKeyDown);
    return () => {
      document.removeEventListener(KeyboardEvents.keydown, handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMediaIndex]);

  /// Handles the onClick of the selected media and marker
  const storeSelectedMarkerAndImage = (
    index: number,
    media: PhotoFileInfo | VideoFileInfo,
    isVideo?: boolean
  ) => {
    if (isVideo) {
      const video = media as VideoFileInfo;
      setVideo(video);
      setPhoto(null);
      storeLatestSelectedVueImage(canonicalId, video);
      setSelectedMediaIndex(index);
      setHoveredMarkerId(video.id);
    } else {
      const photo = media as PhotoFileInfo;
      setPhoto(photo);
      setVideo(null);
      storeLatestSelectedVueImage(canonicalId, photo);
      setSelectedMediaIndex(index);
      setHoveredMarkerId(photo.id);
    }
  };

  const getListOfMarkers = () => {
    const medias = [...photos, ...videos];
    return [
      <OverlayViewF
        key={"vueLocation"}
        position={{
          lat: latitude,
          lng: longitude,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <CustomMapMarker
          lat={latitude}
          lng={longitude}
          onHover={() => {}}
          color="#57FF6F"
        />
      </OverlayViewF>,
      ...medias.map((media, index) => {
        const { location, escalated, id, serialNumber } = media;
        const { latitude, longitude } = location;
        const hasDefinedLocation =
          location && location.latitude && location.longitude;
        const isActiveMarker =
          hoveredMarkerId === id || selectedMediaIndex === index;
        return hasDefinedLocation ? (
          <OverlayViewF
            key={id}
            position={{
              lat: latitude,
              lng: longitude,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <CustomMapMarker
              lat={latitude}
              lng={longitude}
              onHover={() => {
                if (media.mimeType === "video/mp4") {
                  const video = media as VideoFileInfo;
                  storeSelectedMarkerAndImage(index, video, true);
                } else {
                  const photo = media as PhotoFileInfo;
                  storeSelectedMarkerAndImage(index, photo);
                }
              }}
              color={
                escalated ? "#AE1709" : isActiveMarker ? "#4CAF50" : "white"
              }
              textColor={escalated ? "#FFFFFF" : "#000000"}
              content={serialNumber}
            />
          </OverlayViewF>
        ) : (
          <></>
        );
      }),
    ];
  };

  return (
    <>
      <MDBox
        height="100%"
        display="flex"
        padding="10px"
        sx={{ minHeight: "640px", maxHeight: "calc(100vh - 68px)" }}
      >
        <MDBox
          sx={{
            flexShrink: "0",
            minWidth: "0",
            flexGrow: "0",
            flexBasis: "604px",
            border: "1px solid #C7CCD0",
            marginRight: "15px",
            padding: "15px",
            display: "flex",
            flexDirection: "column",
            ".MuiCardMedia-media, video": {
              maxHeight: "42vh",
              marginLeft: "auto !important",
              marginRight: "auto !important",
            },
            video: {
              height: "42vh !important",
            },
            ".heightFix": {
              height: "42vh !important",
            },
          }}
        >
          {photo || video ? (
            <Card
              sx={{
                p: "0px",
                boxShadow: "none",
                flexShrink: "0",
                flexGrow: "1",
                img: {
                  maxHeight: "100%",
                  objectFit: "contain",
                },
              }}
            >
              {photo ? (
                <VueMediaSection photoFileInfo={photo} />
              ) : (
                <VueMediaSection videoFileInfo={video} isStepTypeVideo={true} />
              )}
            </Card>
          ) : (
            <PlaceholderImage />
          )}
          <Divider
            sx={{
              backgroundColor: "#c7ccd0",
              height: "1x",
              opacity: "0.5",
              backgroundImage: "none !important",
              margin: "0.7rem 0",
            }}
          />
          <MDTypography variant="h6" sx={{ mb: "10px" }}>
            Available Images
          </MDTypography>
          <MDBox
            sx={{
              display: "flex",
              overflowY: "auto",
              ...mapFullViewStyle,
              minHeight: "80px",
              maxHeight: "100px",
            }}
          >
            <Grid container spacing={1}>
              {[...photos, ...videos].map((media, index) => (
                <Grid item xs={4} key={media.id}>
                  <MDBox
                    sx={{
                      border:
                        selectedMediaIndex === index
                          ? "3px solid green"
                          : media.escalated
                          ? "3px solid red"
                          : "3px solid transparent",
                      borderRadius: "5px",
                      width: "100%",
                      height: "88px",
                      overflow: "hidden",
                      cursor: "pointer",
                      flexShrink: "0",
                      marginRight: "15px",
                    }}
                    onClick={() => {
                      if (media.mimeType === "video/mp4") {
                        const video = media as VideoFileInfo;
                        storeSelectedMarkerAndImage(index, video, true);
                      } else {
                        const photo = media as PhotoFileInfo;
                        storeSelectedMarkerAndImage(index, photo);
                      }
                    }}
                  >
                    {media.mimeType === "video/mp4" ? (
                      <VideoThumbnail
                        thumbnailFileId={
                          (media as VideoFileInfo).thumbnailFileId
                        }
                        imageWidth="100%"
                        imageHeight="100%"
                      />
                    ) : (
                      <img
                        src={`data:${
                          (media as PhotoFileInfo).mimeType
                        };base64,${(media as PhotoFileInfo).data}`}
                        alt="Pic"
                        style={{
                          filter: media.adminHide && "blur(12px)",
                          width: "100%",
                          height: "88px",
                          objectFit: "contain",
                          borderRadius: "3px",
                        }}
                      />
                    )}
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </MDBox>
          {photos.length === 0 && videos.length === 0 && (
            <PlaceholderComponent label={"No medias available"} />
          )}
        </MDBox>
        <MDBox flexGrow="1">
          <CustomMapComponent
            onlyUpdateViewPortOnDrag
            onClickToggleButton={props.toggleButtonClick}
            fullViewIconPath={mdiArrowCollapse}
            iconSize={1.4}
            handleMapDrag={() => {}}
            coordinates={coords}
            markerComponents={getListOfMarkers()}
          />
        </MDBox>
      </MDBox>
    </>
  );
};
