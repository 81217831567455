import { DialogContent, DialogActions } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";
import { MDBox, MDButton } from "../../vue-grid/helpers/imports";
import { useState } from "react";

interface Props {
  description: string;
  onVueAction: (reason: string) => void;
  onClose: () => void;
  buttonName: string;
}

export const VueDetailActionsDialogContent = (props: Props) => {
  const [reason, setReason] = useState<string>("");

  /// Handles the onChange of "Reason" field
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    /// Max character length should be 500
    if (value.length <= 500) {
      setReason(value);
    } else {
      setReason(value.slice(0, 500));
    }
  };

  return (
    <MDBox px={1} py={1}>
      <DialogContent>
        <MDTypography
          sx={{
            color: "#344767",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {props.description}
        </MDTypography>
        <MDBox display={"flex"} pt={2}>
          <MDTypography
            sx={{
              color: "#344767",
              fontSize: "14px",
            }}
          >
            <strong>Reason</strong>
          </MDTypography>
          <RequiredFieldMarker />
        </MDBox>
        <MDBox>
          <MDInput
            value={reason}
            placeholder="Enter reason here"
            fullWidth
            multiline
            rows={5}
            onChange={handleInputChange}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="outlined"
          color="info"
          size="medium"
          sx={{
            borderColor: "secondary.light",
            color: "dark.main",
            fontSize: 14,
            "&:hover": {
              backgroundColor: "white.main",
              borderColor: "secondary.light",
              color: "dark.main",
            },
            "&:focus": { color: "dark.main" },
          }}
          onClick={props.onClose}
        >
          CLOSE
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="medium"
          disabled={reason.trim().isEmpty()}
          onClick={() => {
            props.onVueAction(reason);
          }}
        >
          {props.buttonName}
        </MDButton>
      </DialogActions>
    </MDBox>
  );
};
