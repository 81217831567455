import { Card, List } from "@mui/material";
import {
  CustomDropdown,
  CustomDropdownOption,
  TEXTFIELD_CHANGE_DELAY,
  VueSurvey,
  WebServiceStatus,
  searchBarStyle,
} from "@ivueit/vue-engine";
import { Search } from "@mui/icons-material";
import { TextField, InputAdornment } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
  availableSurveyStatuses,
  DEFAULT_DROP_DOWN_VALUE,
  DEFAULT_NEW_SURVEY_NAME,
  defaultMetaData,
  getAvailableSurveyTypes,
  getSurveyStatusFromFilterValue,
  getSurveyTypeFilterValue,
} from "../utils/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import MDTypography from "components/MDTypography";
import SurveyListItem from "./SurveyListItem";
import {
  tableConStyle,
  tableRowHeadStyle,
  tableHeadCellStyle,
  surveysGridCardStyle,
  inputNumberStyle,
  regularTextStyle,
} from "../styles/SurveyStyles";
import {
  createSurveyTemplate,
  getSurveyTemplates,
} from "../services/SurveyServices";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { PlaceholderComponent } from "components/CustomComponents/PlaceholderComponent";
import { SurveyAPIMetaData } from "../types/enums";
import { DEFAULT_PAGE_SIZE, RoutePath } from "../../../constants";
import { useNavigate } from "react-router";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import DataGridBottomBar from "pages/vuers/components/BottomBar";

const SurveysDataGrid = () => {
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [searchTextChanged, setSearchTextChanged] = useState<boolean>(false);
  const [meta, setMeta] = useState<SurveyAPIMetaData>(defaultMetaData);
  const [status, setStatus] = useState<string>(DEFAULT_DROP_DOWN_VALUE);
  const [type, setType] = useState<string>(DEFAULT_DROP_DOWN_VALUE);
  const [surveyList, setSurveyList] = useState<VueSurvey[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState<string>("");
  const cardRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState<string>("");
  const [id, setID] = useState<string>("");
  const navigate = useNavigate();

  const handleStatusDropDownClick = (newValue: CustomDropdownOption) => {
    setStatus(newValue.value);
  };

  const handleTypeDropDownClick = (newValue: CustomDropdownOption) => {
    setType(newValue.value);
  };

  const getSearchField = (isSearchByName: boolean) => {
    return (
      <MDBox flexGrow={1} maxWidth={"250px"} mr={0.4}>
        <TextField
          fullWidth
          type={isSearchByName ? "text" : "number"}
          value={isSearchByName ? name : id}
          placeholder={`Search By Survey ${isSearchByName ? "Name" : "ID"}`}
          sx={{ ...searchBarStyle, ...inputNumberStyle }}
          onChange={(event) => {
            const value = event.target.value;
            isSearchByName ? setName(value) : setID(value);
            setSearchTextChanged(true);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="medium" sx={{ color: "#344767" }} />
              </InputAdornment>
            ),
          }}
        />
      </MDBox>
    );
  };

  const getAvailableSurveyTypesForDropdown = (): CustomDropdownOption[] => {
    return getAvailableSurveyTypes().map((value) => {
      return { value: value, displayTitle: value };
    });
  };

  const getAvailableSurveyStatusesForDropdown = (): CustomDropdownOption[] => {
    return availableSurveyStatuses.map((value) => {
      return { value: value, displayTitle: value };
    });
  };

  const getDropDowns = () => {
    const availableSurveyTypes = getAvailableSurveyTypesForDropdown();
    const selectedType = availableSurveyTypes.find(
      (option) => option.value === type
    );
    const availableSurveyStatuses = getAvailableSurveyStatusesForDropdown();
    const selectedStatus = availableSurveyTypes.find(
      (option) => option.value === type
    );

    return (
      <>
        <MDBox mr={0.4}>
          <CustomDropdown
            title={"Type"}
            selectedItem={selectedType}
            availableValues={availableSurveyTypes}
            onChange={handleTypeDropDownClick}
            prefixTitle={true}
          />
        </MDBox>
        <MDBox mr={0.4}>
          <CustomDropdown
            title={"Status"}
            selectedItem={selectedStatus}
            availableValues={availableSurveyStatuses}
            onChange={handleStatusDropDownClick}
            prefixTitle={true}
          />
        </MDBox>
      </>
    );
  };

  const handleAddSurveyClick = async () => {
    setShowLoader(true);
    const param = { name: DEFAULT_NEW_SURVEY_NAME };
    const response = await createSurveyTemplate(param);
    if (response.status === WebServiceStatus.success) {
      await getSurveyList(false);
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  useEffect(() => {
    if (searchTextChanged) {
      /// The method delays the search for 700 millseconds
      const delaySearchAction = setTimeout(() => {
        getSurveyList(false);
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, id]);

  const getParameterString = useCallback(
    (isScrolling: boolean) => {
      // Default page number will be 1 if not scrolling
      var pageNumber = isScrolling ? meta.pageNumber + 1 : 1;
      var pageSize = DEFAULT_PAGE_SIZE;
      var parameterString = `pageSize=${pageSize}&pageNumber=${pageNumber}&sortByRecentBatchUsage=false`;
      const formattedId = id.replaceAll("#", "");
      /// Appending Survey name
      if (name.isNotEmpty()) {
        parameterString += `&name=${name}`;
      }
      /// Appending Survey ID
      if (formattedId.isNotEmpty()) {
        parameterString += `&id.value=${formattedId}`;
      }
      /// Appending Survey Type
      if (type.isNotEmpty()) {
        const typeOf = getSurveyTypeFilterValue(type);
        parameterString += `&typeOf=${typeOf}`;
      }
      /// Appending Survey Status
      if (status.isNotEmpty()) {
        const statusOf = getSurveyStatusFromFilterValue(status);
        parameterString += `&statusOf=${statusOf}`;
      }
      return parameterString;
    },
    [id, meta.pageNumber, name, status, type]
  );

  const getSurveyList = useCallback(
    async (isScrolling: boolean) => {
      const parameters = getParameterString(isScrolling);
      setShowLoader(true);
      const response = await getSurveyTemplates(parameters);
      var listOfSurveys: VueSurvey[] = [];
      if (response.status === WebServiceStatus.success) {
        const {
          surveys,
          page,
        }: { surveys: VueSurvey[]; page: SurveyAPIMetaData } = response.data;
        listOfSurveys = surveys as VueSurvey[];
        setError(listOfSurveys.length === 0 ? "No surveys available." : "");
        const metaData = {
          pageSize: Number(page.pageSize),
          pageNumber: Number(page.pageNumber),
          totalPages: Number(page.totalPages),
          totalElements: Number(page.totalElements),
        };
        setMeta(metaData);
      } else {
        const error = response.error;
        setError(error);
      }
      if (isScrolling) {
        setSurveyList((prev) => prev.concat(listOfSurveys));
      } else {
        setSurveyList(listOfSurveys);
      }
      setShowLoader(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  useEffect(() => {
    getSurveyList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, status]);

  useEffect(() => {
    const card = cardRef.current;
    if (card) {
      const handleOnRowsScrollEnd = async () => {
        const { scrollTop, scrollHeight, clientHeight } = card;
        const hasReachedEnd =
          Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
        const shouldScroll =
          surveyList.length < meta.totalElements &&
          meta.pageNumber < meta.totalPages;
        if (shouldScroll && hasReachedEnd) {
          await getSurveyList(true);
        }
      };
      card.addEventListener("scrollend", handleOnRowsScrollEnd);
      return () => {
        card.removeEventListener("scrollend", handleOnRowsScrollEnd);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, surveyList]);

  return (
    <>
      {showLoader && <CustomIndicator />}
      <MDBox display={"flex"} pt={0.6} pb={1.4}>
        <MDBox>{getSearchField(true)}</MDBox>
        <MDBox ml={1.2}>{getSearchField(false)}</MDBox>
        {getDropDowns()}
        <MDBox ml={"auto"}>
          <MDButton
            variant="contained"
            color="success"
            onClick={handleAddSurveyClick}
            sx={{ fontSize: "14px", fontWeight: "bold" }}
          >
            Add Survey
          </MDButton>
        </MDBox>
      </MDBox>
      <Card
        sx={{
          ...surveysGridCardStyle,
          paddingBottom: "0",
          display: "flex",
          flexDirection: "column",
          minHeight: "0",
        }}
        ref={cardRef}
      >
        <MDBox
          sx={{
            ...tableConStyle,
            position: "sticky",
            top: "0",
            zIndex: "124",
            background: "white",
          }}
        >
          <MDBox sx={tableRowHeadStyle}>
            <MDBox
              sx={{
                ...tableHeadCellStyle,
                flexBasis: "120px",
                flexGrow: "0",
                flexShrink: "0",
              }}
            >
              <MDTypography>ID</MDTypography>
            </MDBox>
            <MDBox sx={tableHeadCellStyle}>
              <MDTypography>NAME</MDTypography>
            </MDBox>
            <MDBox
              sx={{
                ...tableHeadCellStyle,
                flexBasis: "120px",
                flexGrow: "0",
                flexShrink: "0",
              }}
            >
              <MDTypography>STATUS</MDTypography>
            </MDBox>
            <MDBox
              sx={{
                ...tableHeadCellStyle,
                flexBasis: "120px",
                flexGrow: "0",
                flexShrink: "0",
                justifyContent: "center",
              }}
            >
              <MDTypography>ARCHIVED</MDTypography>
            </MDBox>
            <MDBox
              sx={{
                ...tableHeadCellStyle,
                flexBasis: "200px",
                flexGrow: "0",
                flexShrink: "0",
              }}
            >
              <MDTypography>SURVEY TYPE</MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <List sx={tableConStyle}>
          {surveyList.map((survey, index) => {
            const { id, canonicalId, name, typeOf, published, archived } =
              survey;
            const status = published ? "Published" : "Draft";
            return (
              <SurveyListItem
                key={index}
                id={canonicalId}
                title={name}
                status={status}
                type={typeOf}
                isArchived={archived}
                onClick={() => {
                  navigate(`${RoutePath.surveys}/${canonicalId}`, {
                    state: {
                      id: id,
                    },
                  });
                }}
              />
            );
          })}
        </List>
        {error && (
          <MDBox margin="auto">
            <PlaceholderComponent label={error} />
          </MDBox>
        )}
        <DataGridBottomBar
          title={"Total Surveys"}
          totalItems={meta.totalElements}
          sx={{
            position: "sticky",
            bottom: "0",
            zIndex: "125",
            background: "white",
            marginTop: "auto",
          }}
        />
        <CustomSnackbar
          snackbarContent={snackBarContent}
          onClose={() => {
            setSnackBarContent(null);
          }}
        />
      </Card>
    </>
  );
};

export default SurveysDataGrid;
