import {
  mdiAccountCircle,
  mdiClipboardList,
  mdiTagMultiple,
  mdiAccountGroup,
  mdiShieldAccount
} from "@mdi/js";
import Icon from "@mdi/react";
import { Tabs, Tab, AppBar } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import { useState, useEffect } from "react";

export enum ClientsTabsType {
  clientDetails,
  tags,
  admins,
  internalVuerNetwork,
}

interface Props {
  onTabChange: (tab: ClientsTabsType) => void;
}

const ClientsTabs = ({ onTabChange }: Props) => {
  const [tabsOrientation, setTabsOrientation] = useState<
    "horizontal" | "vertical"
  >("horizontal");
  const [tabValue, setTabValue] = useState<ClientsTabsType>(
    ClientsTabsType.clientDetails
  );

  /// A function that sets the orientation state of the tabs.
  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsOrientation]);

  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
    onTabChange(newValue);
  };

  return (
    <AppBar position="static">
      <Tabs
        orientation={tabsOrientation}
        value={tabValue}
        onChange={handleSetTabValue}
      >
        <Tab
          label="Client Details"
          icon={<Icon path={mdiAccountCircle} size={0.85} />}
          sx={{ px: 2, fontSize: tabValue === 0 ? 14 : 16 }}
        />
        <Tab
          label="Tags"
          icon={<Icon path={mdiTagMultiple} size={0.85} />}
          sx={{ px: 2, fontSize: tabValue === 1 ? 14 : 16 }}
        />
        <Tab
          label="Admins"
          icon={<Icon path={mdiShieldAccount} size={0.85} />}
          sx={{ px: 2, fontSize: tabValue === 2 ? 14 : 16 }}
        />
        <Tab
          label="Internal Vuer Network"
          icon={<Icon path={mdiClipboardList} size={0.85} />}
          sx={{ px: 2, fontSize: tabValue === 2 ? 14 : 16 }}
        />
      </Tabs>
    </AppBar>
  );
};

export default ClientsTabs;
