import { TEXTFIELD_CHANGE_DELAY, WebServiceStatus } from "@ivueit/vue-engine";
import { useCallback, useEffect, useMemo, useState } from "react";
import { searchCompanies } from "pages/dashboard/home/common/services/services";
import {
  SEARCH_COMPANIES_PAGE_NUMBER,
  SEARCH_COMPANIES_PAGE_SIZE,
} from "../utils/constants";
import {
  AutocompleteOption,
  CustomAutoCompleteComponent,
} from "components/CustomComponents/CustomAutoCompleteComponent";
import { Company } from "pages/profile/utils/ProfileInterfaces";

interface Props {
  company?: Company;
  onSuccess: (company?: Company) => void;
}

export const ClientSearchComponent = (props: Props) => {
  const { onSuccess, company } = props;
  const [searchText, setSearchText] = useState<string>("");
  const [isFetchingCompanies, setIsFetchingCompanies] =
    useState<boolean>(false);
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [userIsTyping, setUserIsTyping] = useState<boolean>(false);
  const companyNamesList: AutocompleteOption[] = useMemo(
    () =>
      companyList.map((company) => {
        return {
          id: company.id,
          value: company.name,
        };
      }),
    [companyList]
  );

  // This executes when an option is selected from the drop-down
  const handleOptionSelect = (
    selectedValue: AutocompleteOption,
    reason: string
  ) => {
    if (reason === "clear") {
      setSearchText("");
      onSuccess(null);
      return;
    }
    setUserIsTyping(false);
    const companyName = selectedValue.value as string;
    const selectedCompany = companyList.filter(
      (company) => company.name.toLowerCase() === companyName.toLowerCase()
    )[0];
    setCompanyList([selectedCompany]);
    onSuccess(selectedCompany);
  };

  const handleInputChange = (newValue: string) => {
    const sameCompany =
      newValue.toLowerCase() === company?.name.toLowerCase() ?? "";
    if (sameCompany) return;
    setUserIsTyping(true);
    setSearchText(newValue);
    if (!newValue) {
      setCompanyList([]);
    }
  };

  const handleSearchCompanies = useCallback(async () => {
    if (!searchText) {
      return;
    }
    setIsFetchingCompanies(true);
    const parameters = `page.pageNumber=${SEARCH_COMPANIES_PAGE_NUMBER}&page.pageSize=${SEARCH_COMPANIES_PAGE_SIZE}&query=${searchText}`;
    const response = await searchCompanies(parameters);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.companies as Company[];
      setCompanyList(data);
    } else {
      setCompanyList([]);
    }
    setIsFetchingCompanies(false);
  }, [searchText]);

  useEffect(() => {
    if (company) {
      setSearchText(company.name);
    } else {
      setSearchText("");
    }
  }, [company]);

  useEffect(() => {
    if (userIsTyping && searchText) {
      const delaySearch = setTimeout(() => {
        handleSearchCompanies();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearch);
    }
  }, [handleSearchCompanies, userIsTyping, searchText]);

  return (
    <CustomAutoCompleteComponent
      optionsList={companyNamesList}
      noOptionsText={
        !isFetchingCompanies ? "No companies available" : "Searching..."
      }
      value={
        company && {
          id: company.id,
          value: company.name,
        }
      }
      inputValue={searchText}
      onOptionSelect={(event, selectedValue, reason) => {
        handleOptionSelect(selectedValue, reason);
      }}
      onInputChange={(event, newValue, reason) => {
        handleInputChange(newValue);
      }}
      placeholderText="Enter company name"
      isLoading={isFetchingCompanies}
    />
  );
};
