import MDTypography from "components/MDTypography";
import { MDBox } from "../../vue-grid/helpers/imports";
import { VueSubmissionUser } from "../utils/vue_detail_interface";
import { linkElementStyle } from "@ivueit/vue-engine";
import { Link } from "@mui/material";
import { getTextlineURL } from "pages/vuers/utils/helpers";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../../../../constants";

export const VuerMetaData = ({ vuerData }: { vuerData: VueSubmissionUser }) => {
  const { name, canonicalId, phoneNumber } = vuerData;
  const navigate = useNavigate();

  const navigateToVuerScreen = () => {
    navigate(`${RoutePath.vuers}/${vuerData.name}`, {
      state: {
        item: vuerData,
      },
    });
  };

  const onClickPhoneNumber = () => {
    const textLineURL = getTextlineURL(phoneNumber, name);
    window.open(textLineURL, "_blank");
  };

  return (
    <MDBox>
      <MDTypography
        variant="subtitle2"
        color="dark"
        onClick={navigateToVuerScreen}
        sx={{ cursor: "pointer", fontSize: "14px", fontWeight: "400" }}
      >
        {`${name} ${canonicalId ? `(# ${canonicalId})` : ""}`}
      </MDTypography>
      <Link
        sx={{ ...linkElementStyle, cursor: "pointer" }}
        onClick={onClickPhoneNumber}
        variant="button"
        color="primary"
        fontWeight="regular"
        underline="always"
      >
        {phoneNumber}
      </Link>
    </MDBox>
  );
};
