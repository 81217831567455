import { Company } from "pages/profile/utils/ProfileInterfaces";
import {
  ClientsPageAPIServices,
  ManageUserAPIServices,
  WebServiceResponse,
} from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";

/// CLIENT LISTING SCREEN
export const getAllCompanies = async (
  parameterString: string
): Promise<WebServiceResponse> => {
  const url = `${ClientsPageAPIServices.getCompanies}?${parameterString}`;
  return await performGetRequest(url);
};

export const searchCompanies = async (
  parameterString: string
): Promise<WebServiceResponse> => {
  const url = `${ClientsPageAPIServices.searchCompanies}?${parameterString}`;
  return await performGetRequest(url);
};

/// CLIENT DETAIL SCREEN
export const updateCompanyByID = async (
  params: any
): Promise<WebServiceResponse> => {
  const url = ClientsPageAPIServices.updateCompanyById;
  return await performPostRequest(url, params);
};

export const getApiKeysForCompanyID = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = ClientsPageAPIServices.getApiKeysForCompanyId.replace("{id}", id);
  return await performGetRequest(url);
};

export const editCompanyActivation = async (
  params: any
): Promise<WebServiceResponse> => {
  const url = ClientsPageAPIServices.editCompanyActivation;
  return await performPostRequest(url, params);
};

export const createNewCompany = async (
  name: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(ClientsPageAPIServices.createCompany, {
    name: name,
  });
};

export const getOrgUsersByCompanyID = async (
  companyId: string
): Promise<WebServiceResponse> => {
  const pageSize = 1000;
  const pageNumber = 1;
  const url = `${ClientsPageAPIServices.getOrgUsersByCompanyId}?companyId.companyID=${companyId}&page.pageSize=${pageSize}&page.pageNumber=${pageNumber}`;
  return await performGetRequest(url);
};

/// TAGS SECTION
export const getTagsByCompanyID = async (
  companyId: string
): Promise<WebServiceResponse> => {
  const pageSize = 1000;
  const pageNumber = 1;
  const url = `${ClientsPageAPIServices.getTagsByCompanyId}/${companyId}?pagination.pageSize=${pageSize}&pagination.pageNumber=${pageNumber}`;
  return await performGetRequest(url);
};

export const createNewTag = async (
  tagName: string,
  companyId: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(ClientsPageAPIServices.createTag, {
    name: tagName,
    companyId: companyId,
  });
};

export const updateTag = async (
  newName: string,
  companyId: string,
  tagId: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(ClientsPageAPIServices.updateTag, {
    id: tagId,
    name: newName,
    companyId: companyId,
  });
};

export const destroyTag = async (
  tagId: string,
  companyId: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(ClientsPageAPIServices.destroyTag, {
    id: tagId,
    companyId: companyId,
  });
};

/// Manage User section -- To add/remove user
export const getCompanyGroupsByID = async (
  companyID: string
): Promise<WebServiceResponse> => {
  const url = `${ClientsPageAPIServices.getCompanyGroupByCompanyId}/${companyID}`;
  return await performGetRequest(url);
};

export const createOrgUser = async (
  email: string,
  companyID: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(ClientsPageAPIServices.createOrgUser, {
    inviteeEmail: email,
    companyId: companyID,
  });
};

export const removeOrgUser = async (
  userID: string,
  companyID: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(ManageUserAPIServices.removeOrgUser, {
    orgUserId: userID,
    companyId: companyID,
  });
};

export const whitelistEmailWithCompanyAdminRoles = async (
  email: string,
  companies: Company[]
): Promise<WebServiceResponse> => {
  const listOfCompanyObj = companies.map((company) => {
    return {
      id: company.id,
      isReadOnly: company.isReadOnlyMemberOfGroup,
    };
  });
  return await performPostRequest(
    ClientsPageAPIServices.whitelistEmailWithCompanyAdminRoles,
    {
      email: email,
      memberCompanies: listOfCompanyObj,
    }
  );
};

export const whitelistAndInviteByEmail = async (
  email: string,
  companyID: string,
  inviteAdminPortal: boolean
): Promise<WebServiceResponse> => {
  return await performPostRequest(
    ClientsPageAPIServices.whitelistAndInviteByEmail,
    {
      inviteeEmail: email,
      companyId: companyID,
      inviteAdminPortal: inviteAdminPortal,
    }
  );
};
