import { GridColDef } from "@mui/x-data-grid-premium";
import moment from "moment";
import { convertNanoToMilliSeconds } from "utils/helpers/extensions";
import {
  EditHistory,
  EditHistoryGridData,
  StatusHistory,
  StatusHistoryGridData,
  VueHistory,
} from "../utils/vue_detail_interface";

interface DownloadedImageDimension {
  height: number;
  width: number;
  xPosition: number;
  yPosition: number;
}

/// Handles the image download
export const handleLocalFileDownload = async (
  source: string,
  fileName: string
) => {
  try {
    /// Fetches the image from the imageSource URL using the fetch function
    const blob = await fetch(source)
      /// create a new Blob object, representing the image data
      .then((response) => response.blob());
    /// Dynamically created a temporary anchor element to trigger the download of the image
    const link = document.createElement("a");
    /// Assigned the href for the anchor element
    link.href = URL.createObjectURL(blob);
    /// The download attribute of the anchor element is set to the imageName
    /// which will be the filename of the downloaded image
    link.download = fileName;
    link.target = "_blank";
    /// The anchor element is appended to the document body and removes when download is done
    document.body.appendChild(link);
    /// Click event triggers the download
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log("Error occurred on local download: ", error);
  }
};

export const downloadVuePhotoWithDetails = async (
  imageSource: string,
  fileName: string,
  address: string,
  dateTime: string
) => {
  /// Create a canvas object
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  // Create an Image object
  const image = new Image();

  // Set up an event listener to handle the image load
  image.addEventListener("load", () => {
    const imageDimension = getPositionAndDimension(canvas, image);

    const height = imageDimension.height;
    const width = imageDimension.width;
    const xPosition = imageDimension.xPosition;
    const yPosition = imageDimension.yPosition;

    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // Draw the image on the canvas
    ctx.drawImage(image, xPosition, yPosition, width, height);

    /// Fill the text beneath the image
    ctx.font = "20px bold Arial";
    ctx.fillStyle = "white";
    ctx.fillText(address, 10, canvas.height - 60);
    ctx.fillText(dateTime, 10, canvas.height - 30);

    // Convert the canvas content to a data URL
    const dataURL = canvas.toDataURL("image/png");
    // Dynamically created a temporary anchor element to trigger the download of the image
    const link = document.createElement("a");
    /// Assigned the href for the anchor element
    link.href = dataURL;
    // The download attribute of the anchor element is set to the imageName
    // which will be the filename of the downloaded image
    link.download = fileName;
    link.target = "_blank";
    /// The anchor element is appended to the document body and removes when download is done
    document.body.appendChild(link);
    /// Click event triggers the download
    link.click();
    document.body.removeChild(link);
  });
  // Set the source of the image to the provided imageSource
  image.src = imageSource;
};

/// This returns the position and dimensions based on the canvas and image
const getPositionAndDimension = (
  canvas: HTMLCanvasElement,
  image: HTMLImageElement
): DownloadedImageDimension => {
  // Set the desired canvas dimensions
  const canvasWidth = 500;
  const canvasHeight = 650;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  // Calculate the scale to fit the image within the canvas while maintaining aspect ratio
  const imageAspect = image.width / image.height;
  const canvasAspect = canvasWidth / canvasHeight;
  let scale = 1;
  if (imageAspect > canvasAspect) {
    /// This means that the image is bigger than the canvas
    scale = canvasWidth / image.width;
  } else {
    /// Or else vice-versa
    scale = canvasHeight / image.height;
  }
  const width = image.width * scale;
  const height = image.height * scale;
  // Calculate the position to center the image on the canvas
  const xCoordinate = (canvasWidth - width) / 2;
  const yCoordinate = (canvasHeight - height) / 2;

  const imageDimension: DownloadedImageDimension = {
    height: height - 70,
    width: width,
    xPosition: xCoordinate,
    yPosition: yCoordinate,
  };
  return imageDimension;
};

export const getDay = (dayNumber: number): string => {
  switch (dayNumber) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
    default:
      return "Invalid day";
  }
};

export const getGridData = (columnsList: any[]): GridColDef[] => {
  return columnsList.map((column) => {
    let columnData = {
      field: column.field,
      headerName: column.header.toUpperCase(),
      sortable: false,
      editable: false,
    };

    if (column.config) {
      columnData = { ...columnData, ...column.config };
    }

    return columnData;
  });
};

export const editHistoryColumns = [
  {
    field: "address",
    header: "ADDRESS",
    config: {
      width: 200,
      minWidth: 200,
      maxWidth: 220,
    },
  },
  {
    field: "cost",
    header: "COST",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "payout",
    header: "PAYOUT",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "startsAt",
    header: "Starts At",
    config: {
      width: 110,
      minWidth: 110,
      maxWidth: 110,
    },
  },
  {
    field: "endsAt",
    header: "ENDS AT",
    config: {
      width: 110,
      minWidth: 110,
      maxWidth: 110,
    },
  },
  {
    field: "claimLimit",
    header: "CLAIM LIMIT",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "notes",
    header: "NOTES",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "open",
    header: "OPEN",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "urgent",
    header: "URGENT",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "canUseReferral",
    header: "CAN USE REFERRAL",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "editedAt",
    header: "EDITED AT",
    config: {
      width: 210,
      minWidth: 210,
      maxWidth: 210,
    },
  },
  {
    field: "editedBy",
    header: "EDITED BY",
    config: {
      width: 250,
      minWidth: 250,
      maxWidth: 300,
    },
  },
];

export const statusHistoryColumns = [
  {
    field: "vuerID",
    header: "Vuer ID",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "vuer",
    header: "VUER",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "status",
    header: "STATUS",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "notes",
    header: "NOTES",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "pending",
    header: "PENDING",
    config: {
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
  },
  {
    field: "pendingNotes",
    header: "PENDING NOTES",
    config: {
      width: 250,
      minWidth: 250,
      maxWidth: 300,
    },
  },
  {
    field: "referralCode",
    header: "REFERRAL CODE",
    config: {
      width: 200,
      minWidth: 200,
      maxWidth: 300,
    },
  },
  {
    field: "updatedAt",
    header: "UPDATED AT",
    config: {
      width: 250,
      minWidth: 250,
      maxWidth: 300,
    },
  },
  {
    field: "updatedBy",
    header: "UPDATED BY",
    config: {
      width: 250,
      minWidth: 250,
      maxWidth: 300,
    },
  },
];

export const getAgingSLAValueInHours = (
  isCompletedVue: boolean,
  completedAt: string,
  slaStartsAt: string,
  slaTarget: string
) => {
  const slaStartInMilliSeconds = convertNanoToMilliSeconds(
    parseInt(slaStartsAt)
  );
  const completedAtInMilliSeconds = convertNanoToMilliSeconds(
    parseInt(completedAt)
  );
  if (isCompletedVue && completedAt !== "0") {
    return `${Math.floor(
      moment(completedAtInMilliSeconds).diff(
        moment(slaStartInMilliSeconds),
        "hours"
      )
    )} / ${slaTarget} hrs`;
  } else if (
    Math.floor(moment().diff(moment(slaStartInMilliSeconds), "hours")) >= 0
  ) {
    return `${Math.floor(
      moment().diff(moment(slaStartInMilliSeconds), "hours")
    )} / ${slaTarget} hrs`;
  } else {
    return "Not Started";
  }
};

export const getStatusGridData = (
  data: StatusHistory,
  index: number
): StatusHistoryGridData => {
  return {
    id: index,
    vuerID: data.vuerCanonicalId ? data.vuerCanonicalId.value : "-",
    vuer: data.vuerName ? data.vuerName.value : "-",
    status: data.status,
    notes: data.adminRejectionNotes ?? "-",
    pending: data.pending,
    pendingNotes: data.pendingNotes ?? "-",
    referralCode: data.referralCode ?? "-",
    updatedAt: data.modifiedAt.formatUTCNanoSecondsToString(
      "MM/DD/YYYY - hh:mm:ss A"
    ),
    updatedBy: data.modifiedBy ?? "-",
  };
};

export const getVueGridData = (
  data: EditHistory,
  index: number
): EditHistoryGridData => {
  return {
    id: index,
    address: data.postalAddress ? data.postalAddress.value : "-",
    cost: `$${Math.abs(data.cost).toFixed(2)}`,
    payout: `$${Math.abs(data.payout).toFixed(2)}`,
    startsAt: data.startsAt
      ? `${data.startsAt.value.parsedTimeFromBackend(true)}`
      : "-",
    endsAt: data.endsAt
      ? `${data.endsAt.value.parsedTimeFromBackend(true)}`
      : "-",
    claimLimit:
      data.claimTimeLimitInMinutes > 0
        ? data.claimTimeLimitInMinutes
        : "Not Set",
    notes: data.notes ? data.notes.value : "-",
    open: data.submissionId ? "No" : "Yes",
    urgent: data.urgent ? "Yes" : "No",
    canUseReferral: data.canUseReferral ? "Yes" : "No",
    editedAt: data.modifiedAt.formatUTCNanoSecondsToString(
      "MM/DD/YYYY - hh:mm:ss A"
    ),
    editedBy: data.modifiedBy,
  };
};

// This is used to get the numerical value for Urgent & Can Use Referral
export const getValue = (index: number) => {
  switch (index) {
    case 1:
      return true;
    case 2:
      return false;
    default:
      break;
  }
};
