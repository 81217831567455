import { autoCompleteStyle } from "@ivueit/vue-engine";
import { Autocomplete, CircularProgress, Grid } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import WorkIcon from "@mui/icons-material/Work";

export interface AutocompleteOption {
  id: string;
  value: string;
}

interface Props {
  isLoading: boolean;
  optionsList: AutocompleteOption[];
  noOptionsText: string;
  value: AutocompleteOption;
  inputValue: string;
  onOptionSelect: (
    event: React.SyntheticEvent,
    selectedValue: AutocompleteOption,
    reason: string
  ) => void;
  onInputChange: (
    event: React.SyntheticEvent,
    newValue: string,
    reason: string
  ) => void;
  placeholderText: string;
  icon?: string;
}

export const CustomAutoCompleteComponent = (props: Props) => {
  const {
    isLoading,
    optionsList,
    noOptionsText,
    value,
    inputValue,
    onInputChange,
    onOptionSelect,
    placeholderText,
    icon,
  } = props;
  return (
    <Autocomplete
      filterOptions={(x) => x}
      options={optionsList}
      noOptionsText={noOptionsText}
      getOptionLabel={(option) => option.value}
      value={value}
      inputValue={inputValue}
      sx={{
        ...autoCompleteStyle,
        ".MuiOutlinedInput-root": {
          paddingTop: "2px !important",
          paddingBottom: "2px !important",
        },
      }}
      onChange={(
        event: React.SyntheticEvent,
        selectedItem: AutocompleteOption,
        reason: string
      ) => {
        onOptionSelect(event, selectedItem, reason);
      }}
      onInputChange={(
        event: React.SyntheticEvent,
        newValue: string,
        reason: string
      ) => {
        onInputChange(event, newValue, reason);
      }}
      fullWidth
      renderInput={(params) => (
        <MDInput
          {...params}
          fullWidth
          placeholder={placeholderText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Grid container alignItems="center">
              {icon && (
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <WorkIcon sx={{ color: "text.secondary" }} />
                </Grid>
              )}
              <Grid
                item
                sx={{
                  width: "calc(100% - 44px)",
                  wordWrap: "break-word",
                }}
              >
                <MDTypography sx={{ fontWeight: 400, fontSize: 14 }}>
                  {option.value}
                </MDTypography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
