import { DialogActions, DialogContent } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import { useState } from "react";
import { createNewCompany } from "../services/services";
import { GENERIC_ERROR_MESSAGE, WebServiceStatus } from "@ivueit/vue-engine";

interface Props {
  closeDialog: () => void;
  onClientCreation: () => void;
}

const CreateNewClientDialog = (props: Props) => {
  const [clientName, setClientName] = useState("");
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);

  const onSaveClick = async () => {
    if (clientName.trim().isEmpty()) {
      setSnackBarContent({
        title: "Attention!",
        message: "Please enter a valid name",
        isError: true,
      });
      return;
    }
    const response = await createNewCompany(clientName.trim());
    if (response.status === WebServiceStatus.success) {
      setSnackBarContent({
        title: "Success!",
        message: "Created company successfully",
        isError: false,
      });
      props.onClientCreation();
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
  };

  return (
    <>
      <DialogContent>
        <MDBox px={1.4} pt={2}>
          <MDBox flex={1}>
            <MDTypography sx={regularTextStyle}>Name</MDTypography>
            <MDInput
              placeholder="Enter Client Name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setClientName(event.target.value);
              }}
              fullWidth
            />
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            disabled={clientName.trim().isEmpty()}
            onClick={onSaveClick}
          >
            SAVE
          </MDButton>
        </>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackBarContent}
        onClose={() => {
          setSnackBarContent(null);
        }}
      />
    </>
  );
};

export default CreateNewClientDialog;
