import { DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import { useState } from "react";
import { updateUserByID } from "../services/VuerServices";
import {
  CustomIndicator,
  VuerData,
  WebServiceStatus,
} from "@ivueit/vue-engine";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";

interface Props {
  vuer: VuerData;
  closeDialog: (vuerData: VuerData) => void;
}

const SupportNotesDialogContent = ({ vuer, closeDialog }: Props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [supportNotes, setSupportNotes] = useState(vuer.supportNotes);
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);

  const updateVuerDetail = async () => {
    if (supportNotes.isNotEmpty()) {
      const param = {
        supportNotes: {
          value: supportNotes,
        },
      };
      setShowLoader(true);
      const response = await updateUserByID(vuer.id, param);
      if (response.status === WebServiceStatus.success) {
        const data = response.data as VuerData;
        closeDialog(data);
      } else {
        setSnackBarContent({
          title: "Attention!",
          message: response.error,
          isError: true,
        });
      }
      setShowLoader(false);
    }
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <DialogContent>
        <MDBox my={2}>
          <MDTypography sx={regularTextStyle}>Support Notes</MDTypography>
          <MDInput
            placeholder="Enter Support notes"
            value={supportNotes}
            fullWidth
            multiline
            rows="5"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSupportNotes(event.target.value);
            }}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              closeDialog(vuer);
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CLOSE
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={updateVuerDetail}
            sx={{ fontSize: "14px", padding: "11px 22px" }}
            disabled={supportNotes.length <= 0}
          >
            SAVE
          </MDButton>
        </>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackBarContent}
        onClose={() => {
          setSnackBarContent(null);
        }}
      />
    </>
  );
};

export default SupportNotesDialogContent;
