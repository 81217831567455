export enum UserAccountStatusFilter {
  all = "All", // USER_ACCOUNT_STATUS_ALL
  active = "Active", // USER_ACCOUNT_STATUS_NOT_BLACKLISTED_NOT_DELETED
  blackListed = "Blacklisted", // USER_ACCOUNT_STATUS_BLACKLISTED
  deleted = "Deleted", // USER_ACCOUNT_STATUS_DELETED
}

export const getAccountStatusValueForAPI = (statusType: string) => {
  switch (statusType) {
    case "All":
      return 0;
    case "Active":
      return 1;
    case "Blacklisted":
      return 2;
    case "Deleted":
      return 3;
    default:
      return 0;
  }
};

export const availableAccountStatusFilters = [
  UserAccountStatusFilter.all,
  UserAccountStatusFilter.active,
  UserAccountStatusFilter.blackListed,
  UserAccountStatusFilter.deleted,
];
