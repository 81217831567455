import {
  CustomDropdown,
  CustomDropdownOption,
  DEFAULT_PAGE_SIZE,
  GENERIC_ERROR_MESSAGE,
  TEXTFIELD_CHANGE_DELAY,
  VuerData,
  gridStyle,
  searchBarStyle,
  textEllipsis,
} from "@ivueit/vue-engine";
import { Search } from "@mui/icons-material";
import {
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import { surveysGridCardStyle } from "pages/surveys/styles/SurveyStyles";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { defaultMetaData } from "pages/surveys/utils/constants";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowsProp,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import {
  getLinkElement,
  getTextlineURL,
  vuerColumnData,
} from "../utils/helpers";
import DataGridBottomBar from "./BottomBar";
import {
  fetchBlacklistedPhoneNumbers,
  searchUsers,
} from "../services/VuerServices";
import { GridMetaData } from "../interface/interface";
import { WebServiceStatus } from "utils/services/AppUrls";
import { RoutePath } from "../../../constants";
import UserImageAvatar from "./UserImageAvatar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { ExportVuerDialog } from "./ExportVuerDialog";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { avatarContainerStyle } from "../styles/VuersStyles";
import { GridFilter } from "types/interfaces";
import { mdiFilter, mdiFilterOff } from "@mdi/js";
import Icon from "@mdi/react";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import { CustomPopper } from "pages/dashboard/home/vues/vue-grid/components/CustomPopper";
import FilterByTextContent from "pages/dashboard/home/vues/vue-grid/components/FilterByTextContent";
import VuerDetailFilterView, { SearchQuery } from "./VuerDetailFilterView";
import {
  availableAccountStatusFilters,
  getAccountStatusValueForAPI,
  UserAccountStatusFilter,
} from "../types/enums";

const VuersDataGrid = () => {
  // Contains list of blacklisted phone numbers.
  const [blackListedPhoneNumbers, setBlackListedPhoneNumbers] = useState<
    string[]
  >([]);
  // Data source for the grid
  const [rowData, setRowData] = useState<GridRowsProp>([]);
  /// Used to perform search by name
  const [searchInput, setSearchInput] = useState("");
  /// Determines whether seach is started or not
  const [searchStarted, setSearchStarted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showExportVuerDialog, setShowExportVuerDialog] = useState(false);
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [metaValues, setMetaValues] = useState<GridMetaData>(defaultMetaData);
  const navigate = useNavigate();
  /// Reference for the grid
  const gridRef = useGridApiRef();
  /// Defines the anchor element for the popup
  const [anchorElement, setAnchorElement] = useState<
    HTMLButtonElement | HTMLDivElement
  >(null);
  /// Defines the popup fields
  const [popperName, setPopperName] = useState<string>(null);
  /// Selected filters
  const [selectedFilters, setSelectedFilters] = useState<GridFilter[]>([]);
  const [selectedAccountStatus, setSelectedAccountStatus] =
    useState<CustomDropdownOption>({
      value: UserAccountStatusFilter.all,
      displayTitle: UserAccountStatusFilter.all,
    });

  const getBlackListedPhoneNumbers = async () => {
    const parameter = `pageNumber=0&pageSize=${DEFAULT_PAGE_SIZE}`;
    const response = await fetchBlacklistedPhoneNumbers(parameter);
    if (response.status === WebServiceStatus.success) {
      const phoneNumbers = response.data.phoneNumbers ?? [];
      setBlackListedPhoneNumbers(phoneNumbers);
    } else {
      setBlackListedPhoneNumbers([]);
      console.log(
        `Could not fetch blacklisted phone numbers: ${
          response.error ?? GENERIC_ERROR_MESSAGE
        }`
      );
    }
  };

  useEffect(() => {
    // Fetching black listed phones as this is needed to get the vuer list
    getBlackListedPhoneNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchVuerListFromServer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blackListedPhoneNumbers, selectedFilters, selectedAccountStatus]);

  ////// SEARCH //////

  // Handling the text search for the grid
  useEffect(() => {
    const handleSearchTextChange = async () => {
      if (!searchStarted) {
        return;
      }
      fetchVuerListFromServer(false);
    };
    /// The method delays the api call for 700ms
    /// It helps us to avoid frequent api calls & state updates, on text change
    const delayAPIInvocation = setTimeout(() => {
      handleSearchTextChange();
    }, TEXTFIELD_CHANGE_DELAY);

    return () => clearTimeout(delayAPIInvocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  ////// Export Feature //////

  /// Export vuer list dialog
  const getExportVuerDialog = () => {
    return (
      <CustomDialogBox
        title="Export Vuers"
        width="560px"
        openDialog={showExportVuerDialog}
      >
        <ExportVuerDialog
          closeDialog={() => {
            setShowExportVuerDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  ////// GRID //////

  const getFilterParameters = () => {
    let filterParams = "";
    selectedFilters.forEach((filter) => {
      const { filters, field } = filter;
      if (filters.length > 0) {
        switch (field) {
          case "canonicalId":
            const canonicalId = filters[0] as string;
            // when populated - other filters ignored
            filterParams += `&canonicalId.value=${canonicalId
              .trim()
              .replaceAll("#", "")}`;
            break;
          case "vuer":
            const searchQuery = filters[0] as SearchQuery;
            const { phoneNumber, email } = searchQuery;
            if (phoneNumber.isNotEmpty()) {
              // when populated - other filters ignored (unless canonicalId or id is populated)
              filterParams += `&phoneNumber.value=${phoneNumber.trim()}`;
            }
            if (email.isNotEmpty()) {
              filterParams += `&emailSearch.value=${email.trim()}`;
            }
            break;
          default:
            break;
        }
      }
    });
    return filterParams;
  };

  const getUserStatusFilter = () => {
    const accountStatus = getAccountStatusValueForAPI(
      selectedAccountStatus.displayTitle
    );
    let queryString = `&statusFilter=${accountStatus}`;
    if (
      selectedAccountStatus.value === UserAccountStatusFilter.blackListed ||
      selectedAccountStatus.value === UserAccountStatusFilter.active
    ) {
      //only needs populated when statusFilter is either BLACKLISTED or
      // NOT_BLACKLISTED_NOT_DELETED
      blackListedPhoneNumbers.forEach((phone) => {
        queryString += `&blacklistedPhoneNumbers=${phone}`;
      });
    }
    return queryString;
  };

  //Generating parameters for API
  const getParameterString = useCallback(
    (isScrolling: boolean): string => {
      // Default page number will be 1 if not scrolling
      const newPageNumber = isScrolling
        ? (metaValues.pageNumber + 1).toString()
        : "1";
      const pageSize = DEFAULT_PAGE_SIZE;
      let queryString = `pageSize=${pageSize}&pageNumber=${newPageNumber}`;
      // Attaching status filters and blacklisted phone numbers
      queryString += getUserStatusFilter();

      if (selectedFilters.length > 0) {
        queryString += getFilterParameters();
      }

      if (searchInput.trim().isNotEmpty()) {
        // removing # symbol
        const searchValue = searchInput.trim().replaceAll("#", "");
        queryString += `&nameSearch.value=${searchValue}`;
      }
      return queryString;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFilterParameters, selectedFilters.length]
  );

  // Fetches the vuer list from the server
  const fetchVuerListFromServer = useCallback(
    async (isScrolling: boolean) => {
      setLoader(true);
      const parameters = getParameterString(isScrolling);
      const response = await searchUsers(parameters);
      var gridData: VuerData[] = [];
      if (response.status === WebServiceStatus.success) {
        const {
          users,
          meta,
        }: {
          users: VuerData[];
          meta: GridMetaData;
        } = response.data;
        gridData = users;
        setMetaValues({
          pageSize: Number(meta.pageSize),
          pageNumber: Number(meta.pageNumber),
          totalPages: Number(meta.totalPages),
          totalElements: Number(meta.totalElements),
        });
      } else {
        setSnackBarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
      setLoader(false);
      if (isScrolling) {
        setRowData(rowData.concat(gridData));
      } else {
        setRowData(gridData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  //////////////////// FILTER ////////////////////////////////

  /// Handles the onClick of "Filter" icon in the column header
  const handleFilterIconClick = (field: string) => {
    // Helps to show the popup. If the field is already showing, setting null will hide the popup
    setPopperName(popperName === field ? null : field);
  };

  const getFilterPopper = () => {
    var children: JSX.Element = <></>;
    switch (popperName) {
      case "canonicalId":
        children = getPopperForCanoncialId();
        break;
      case "vuer":
        children = getPopperForVuerDetails();
        break;
      default:
        return <></>;
    }

    return (
      <CustomPopper
        onClickAway={(event: MouseEvent | TouchEvent) => {
          handleClosePopper(event);
        }}
        shouldOpen={true}
        anchorElement={anchorElement}
        id={popperName}
        placement={"right"}
      >
        {children}
      </CustomPopper>
    );
  };

  const getPopperForCanoncialId = () => {
    const headerName: string = vuerColumnData.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";
    return (
      <CustomPopper
        onClickAway={(event: MouseEvent | TouchEvent) => {
          handleClosePopper(event);
        }}
        shouldOpen={true}
        anchorElement={anchorElement}
        id={popperName}
        placement={"right"}
      >
        <FilterByTextContent
          removeColumn={() => {}}
          columnName={columnName}
          closePopper={(
            event: Event | React.SyntheticEvent<Element, Event>
          ) => {
            handleClosePopper(event);
          }}
          onTextChange={(text) => {
            const filterObject = {
              field: popperName,
              filters: [text],
            };
            const indexOfItem = selectedFilters.findIndex(
              (item) => item.field === popperName
            );
            var updatedFilters = [...selectedFilters];
            if (text.isEmpty()) {
              // Filters on this columns has been removed
              if (indexOfItem !== -1) {
                // Item already available. Removing the item
                updatedFilters.splice(indexOfItem, 1);
              }
            } else {
              if (indexOfItem !== -1) {
                updatedFilters[indexOfItem] = filterObject;
              } else {
                updatedFilters.push(filterObject);
              }
            }
            setSelectedFilters(updatedFilters);
          }}
          shouldShowRemoveColumnButton={false}
          searchedText={getSearchTextForField(popperName)}
        />
      </CustomPopper>
    );
  };

  const getPopperForVuerDetails = () => {
    const headerName: string = vuerColumnData.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";
    const currentFilter = selectedFilters.find(
      (filter) => filter.field === popperName
    )?.filters;
    let previousSearch = { email: "", phoneNumber: "" };
    if (currentFilter && currentFilter.length > 0) {
      previousSearch = currentFilter[0];
    }
    return (
      <CustomPopper
        onClickAway={(event: MouseEvent | TouchEvent) => {
          handleClosePopper(event);
        }}
        shouldOpen={true}
        anchorElement={anchorElement}
        id={popperName}
        placement={"right"}
      >
        <VuerDetailFilterView
          columnName={columnName}
          closePopper={(
            event: Event | React.SyntheticEvent<Element, Event>
          ) => {
            handleClosePopper(event);
          }}
          onFilterChange={(searchQuery) => {
            const { email, phoneNumber } = searchQuery;
            const clearFilter =
              email.trim().isEmpty() && phoneNumber.trim().isEmpty();
            const filterObject = {
              field: popperName,
              filters: [searchQuery],
            };
            const indexOfItem = selectedFilters.findIndex(
              (item) => item.field === popperName
            );
            var updatedFilters = [...selectedFilters];
            if (clearFilter) {
              // Filters on this columns has been removed
              if (indexOfItem !== -1) {
                // Item already available. Removing the item
                updatedFilters.splice(indexOfItem, 1);
              }
            } else {
              if (indexOfItem !== -1) {
                updatedFilters[indexOfItem] = filterObject;
              } else {
                updatedFilters.push(filterObject);
              }
            }
            setSelectedFilters(updatedFilters);
          }}
          previousSearch={previousSearch}
        />
      </CustomPopper>
    );
  };

  const getSearchTextForField = (field: string): string => {
    const appliedFilter = selectedFilters.find(
      (filter) => filter.field === field
    );

    if (appliedFilter && appliedFilter.filters.length > 0) {
      // First element in the filter will be the search text
      return appliedFilter.filters[0];
    }
    return "";
  };

  /// Handles the close of popper component
  const handleClosePopper = (event: Event | React.SyntheticEvent) => {
    // Helps to hide the popups
    setPopperName(null);
  };

  /// Handles the onClick of the "ClearAllFilters" button
  const onClearAllFilterButtonClick = () => {
    setSelectedFilters([]);
  };

  //////////////////// COMPONENTS ////////////////////////////////

  const getClearAllFiltersButton = () => {
    return (
      <MDBox mr="auto" flexShrink="0" pr={1}>
        <StyledButtonWithIcon
          iconPath={mdiFilterOff}
          onClick={onClearAllFilterButtonClick}
        >
          {`Clear All Filters (${selectedFilters.length})`}
        </StyledButtonWithIcon>
      </MDBox>
    );
  };

  /// Defines the custom headers
  const getCustomHeader = (params: GridColumnHeaderParams) => {
    const { colDef, field } = params;
    const { filterable, headerName } = colDef;
    var iconButtons: JSX.Element = <></>;
    const showFilterIcon = filterable;
    return (
      <MDBox>
        {
          <>
            <strong>{headerName.toUpperCase()}</strong>
            {showFilterIcon && (
              <IconButton
                sx={{ ml: "5px" }}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setAnchorElement(event.currentTarget);
                  handleFilterIconClick(field);
                }}
              >
                <Icon path={mdiFilter} size={0.72} />
              </IconButton>
            )}
          </>
        }
        {iconButtons}
      </MDBox>
    );
  };

  // Defines the custom cells in the grid
  const getCustomCells = (params: GridRenderCellParams) => {
    const { field, value } = params;

    if (field === "canonicalId") {
      return getLinkElement(`#${value}`, () => {
        onClickLinkElement(params);
      });
    } else if (field === "vuer") {
      return getUserCell(params);
    }

    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
      >
        <MDBox sx={textEllipsis}>{value}</MDBox>
      </MDBox>
    );
  };

  const getUserCell = (params: GridRenderCellParams) => {
    const { row } = params;
    const vuer: VuerData = row;
    return (
      <Grid
        container
        columnSpacing={3}
        alignItems="center"
        flexWrap={"nowrap"}
        pt={1.2}
        pb={0.8}
      >
        <Grid item pl="6px">
          <MDBox sx={avatarContainerStyle}>
            <UserImageAvatar
              imageId={vuer.profilePhotoFileId}
              size="md"
              shadow="sm"
            />
          </MDBox>
        </Grid>
        <Grid item>
          <MDBox
            height="100%"
            display="flex"
            flexDirection={"column"}
            mt={0.5}
            lineHeight={1}
          >
            {getLinkElement(vuer.name, () => {
              onClickLinkElement(params);
            })}
            <MDBox>
              <MDTypography variant="button" color="dark" fontWeight="light">
                {vuer.email}
              </MDTypography>
            </MDBox>
            <MDBox>
              {getLinkElement(vuer.phoneNumber, () => {
                onClickPhoneNumber(params);
              })}
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    );
  };

  /// Handles the navigation to vuer detail page
  const onClickLinkElement = (params: GridRenderCellParams) => {
    const vuer = params.row;
    if (vuer) {
      navigate(`${RoutePath.vuers}/${vuer.name}`, {
        state: {
          item: vuer,
        },
      });
    }
  };

  /// Handles the navigation to textline website
  const onClickPhoneNumber = (params: GridRenderCellParams) => {
    const vuer = params.row as VuerData;
    if (vuer) {
      const phoneNumber = vuer.phoneNumber;
      const name = vuer.name;
      const textLineURL = getTextlineURL(phoneNumber, name);
      window.open(textLineURL, "_blank");
    }
  };

  const renderedColumns = vuerColumnData.map((data) => {
    data.renderCell = getCustomCells;
    data.renderHeader = getCustomHeader;
    return data;
  });

  const handleOnRowsScrollEnd: DataGridPremiumProps["onRowsScrollEnd"] =
    async () => {
      if (
        rowData.length < metaValues.totalElements &&
        metaValues.pageNumber < metaValues.totalPages
      ) {
        await fetchVuerListFromServer(true);
      }
    };

  const getFooter = () => {
    return (
      <MDBox display="flex" alignItems="center">
        <DataGridBottomBar
          title={"Total Vuers"}
          totalItems={metaValues.totalElements}
        />
      </MDBox>
    );
  };

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No vuers found.
        </Typography>
      </MDBox>
    );
  };

  return (
    <MDBox>
      {popperName && getFilterPopper()}
      {showExportVuerDialog && getExportVuerDialog()}
      {/* Header */}
      <MDBox display={"flex"} pt={0.6} pb={1.4}>
        <MDBox width="100%" display="flex" maxWidth={"300px"}>
          <TextField
            fullWidth
            placeholder="Search by Vuer Name"
            sx={searchBarStyle}
            onChange={(event) => {
              setSearchStarted(true);
              setSearchInput(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search fontSize="medium" sx={{ color: "#344767" }} />
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox mr={0.4}>
          <CustomDropdown
            title={"Account Status"}
            selectedItem={selectedAccountStatus}
            availableValues={availableAccountStatusFilters.map((status) => {
              return { value: status, displayTitle: status };
            })}
            onChange={(newOption) => setSelectedAccountStatus(newOption)}
            prefixTitle={true}
          />
        </MDBox>
        <MDBox ml={"auto"} display="flex">
          {selectedFilters.length > 0 && getClearAllFiltersButton()}
          <MDButton
            variant="contained"
            color="success"
            onClick={() => {
              setShowExportVuerDialog(true);
            }}
            sx={{ fontSize: "14px", fontWeight: "bold" }}
            disabled={rowData.length <= 0}
          >
            Export
          </MDButton>
        </MDBox>
      </MDBox>
      {/* Grid */}
      <Card sx={surveysGridCardStyle}>
        <DataGridPremium
          apiRef={gridRef}
          columns={renderedColumns}
          rows={rowData}
          disableColumnMenu
          sx={{
            ...gridStyle,
          }}
          density="compact"
          disableRowSelectionOnClick
          slots={{
            footer: getFooter,
            noRowsOverlay: getNoRowsMessage,
          }}
          slotProps={{
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "linear-progress",
            },
          }}
          loading={loader}
          sortingMode="server"
          filterMode="server"
          rowHeight={140}
          onRowsScrollEnd={handleOnRowsScrollEnd}
        />
      </Card>
      <CustomSnackbar
        snackbarContent={snackBarContent}
        onClose={() => {
          setSnackBarContent(null);
        }}
      />
    </MDBox>
  );
};

export default VuersDataGrid;
