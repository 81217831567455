import {
  GENERIC_ERROR_MESSAGE,
  gridStyle,
  searchBarStyle,
  textEllipsis,
  TEXTFIELD_CHANGE_DELAY,
  WebServiceResponse,
  WebServiceStatus,
} from "@ivueit/vue-engine";
import { Search } from "@mui/icons-material";
import { Card, InputAdornment, TextField, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { surveysGridCardStyle } from "pages/surveys/styles/SurveyStyles";
import { useCallback, useEffect, useState } from "react";
import CreateGroupDialogContent from "./CreateGroupDialogContent";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { GridMetaData } from "pages/vuers/interface/interface";
import {
  DataGridPremium,
  DataGridPremiumProps,
  gridClasses,
  GridRenderCellParams,
  GridRowsProp,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import { usersGridColumnData } from "../utils/utils";
import DataGridBottomBar from "pages/vuers/components/BottomBar";
import { getLinkElement } from "pages/vuers/utils/helpers";
import { DEFAULT_PAGE_SIZE, RoutePath } from "./../../../constants";
import { defaultMetaData } from "pages/surveys/utils/constants";
import { CompanyGroup } from "pages/profile/utils/ProfileInterfaces";
import { alphanumericSort } from "utils/helpers/extensions";
import { getAllCompanyGroups } from "../services/services";

const UsersDataGrid = () => {
  const [openCreateGroupDialog, setOpenCreateGroupDialog] =
    useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [metaValues, setMetaValues] = useState<GridMetaData>(defaultMetaData);
  /// Contains original data from the api
  const [userListData, setUserListData] = useState<GridRowsProp>([]);
  /// Contains search result and is used as data source for the grid
  const [rowData, setRowData] = useState<GridRowsProp>([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  /// Reference for the grid
  const gridRef = useGridApiRef();

  const getCreateGroupDialog = () => {
    return (
      <CustomDialogBox
        title={"New Group"}
        width="520px"
        openDialog={openCreateGroupDialog}
      >
        <CreateGroupDialogContent
          closeDialog={(groupCreated) => {
            setOpenCreateGroupDialog(false);
            if (groupCreated) {
              setSnackBarContent({
                title: "Success!",
                message: "New company group created successfully",
                isError: false,
              });
              fetchCompanyGroupsFromServer(false);
            }
          }}
        />
      </CustomDialogBox>
    );
  };

  const parseCompanyGroupResults = (
    response: WebServiceResponse,
    isScrolling: boolean
  ) => {
    var gridData: CompanyGroup[] = [];
    if (response.status === WebServiceStatus.success) {
      const {
        companyGroups,
        meta,
      }: {
        companyGroups: CompanyGroup[];
        meta: GridMetaData;
      } = response.data;
      gridData = companyGroups;
      setMetaValues({
        pageSize: Number(meta.pageSize),
        pageNumber: Number(meta.pageNumber),
        totalPages: Number(meta.totalPages),
        totalElements: Number(meta.totalElements),
      });
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setLoader(false);
    const newRowData = isScrolling ? userListData.concat(gridData) : gridData;
    // Sorts the list by name
    const sortedList = newRowData.sort((a, b) =>
      alphanumericSort(a.name, b.name)
    );
    setUserListData(sortedList);
  };

  //Generating parameters for API
  const getParameterString = useCallback(
    (isScrolling: boolean): string => {
      // Default page number will be 1 if not scrolling
      const newPageNumber = isScrolling
        ? (metaValues.pageNumber + 1).toString()
        : "1";
      return `pagination.pageSize=${DEFAULT_PAGE_SIZE}&pagination.pageNumber=${newPageNumber}&showDeleted=${false}`;
    },
    [metaValues.pageNumber]
  );

  // Fetches the vuer list from the server
  const fetchCompanyGroupsFromServer = useCallback(
    async (isScrolling: boolean) => {
      setLoader(true);
      const parameters = getParameterString(isScrolling);
      const response = await getAllCompanyGroups(parameters);
      parseCompanyGroupResults(response, isScrolling);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  useEffect(() => {
    fetchCompanyGroupsFromServer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ///// SEARCH /////
  useEffect(() => {
    const handleSearchTextChange = async (searchInput: string) => {
      const rowData = userListData.filter((row) => {
        return row.name?.toLowerCase().includes(searchInput.toLowerCase());
      });
      setRowData(rowData);
    };
    /// It helps us to avoid frequent grid updates & state changes, on text change
    const delayAPIInvocation = setTimeout(() => {
      handleSearchTextChange(searchText.trim());
    }, TEXTFIELD_CHANGE_DELAY);

    return () => clearTimeout(delayAPIInvocation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, userListData]);

  //// GRID ////

  /// Handles the navigation to client detail page
  const onClickLinkElement = (params: GridRenderCellParams) => {
    const companyGroup = params.row;
    if (companyGroup) {
      navigate(`${RoutePath.users}/${companyGroup.name}`, {
        state: {
          item: companyGroup,
        },
      });
    }
  };

  // Defines the custom cells in the grid
  const getCustomCells = (params: GridRenderCellParams) => {
    const { field, value, row } = params;
    const companyGroup: CompanyGroup = row;
    let cellContent = value;
    switch (field) {
      case "name":
        return getLinkElement(value, () => {
          onClickLinkElement(params);
        });
      case "active":
        cellContent = companyGroup.valid ? "YES" : "NO";
        break;
      case "createdAt":
        cellContent =
          companyGroup.createdAt.formatUTCNanoSecondsToString("MM/DD/YYYY");
        break;
      default:
        break;
    }

    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
      >
        <MDBox sx={textEllipsis}>{cellContent}</MDBox>
      </MDBox>
    );
  };

  const renderedColumns = usersGridColumnData.map((data) => {
    data.renderCell = getCustomCells;
    return data;
  });

  const getFooter = () => {
    return (
      <MDBox display="flex" alignItems="center">
        <DataGridBottomBar
          title={"Total Users"}
          totalItems={
            searchText ? rowData.length : metaValues.totalElements
          }
        />
      </MDBox>
    );
  };

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No users found.
        </Typography>
      </MDBox>
    );
  };

  const handleOnRowsScrollEnd: DataGridPremiumProps["onRowsScrollEnd"] =
    async () => {
      if (
        rowData.length < metaValues.totalElements &&
        metaValues.pageNumber < metaValues.totalPages
      ) {
        await fetchCompanyGroupsFromServer(true);
      }
    };
  //// GRID ////
  return (
    <MDBox>
      {openCreateGroupDialog && getCreateGroupDialog()}
      <MDBox display={"flex"} pt={0.6} pb={1.4}>
        <MDBox width="100%" display="flex" maxWidth={"300px"}>
          <TextField
            fullWidth
            placeholder="Search by Group Name"
            sx={searchBarStyle}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search fontSize="medium" sx={{ color: "#344767" }} />
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox ml={"auto"}>
          <MDButton
            variant="contained"
            color="success"
            onClick={() => {
              setOpenCreateGroupDialog(true);
            }}
            sx={{ fontSize: "14px", fontWeight: "bold" }}
          >
            Create Group
          </MDButton>
        </MDBox>
      </MDBox>
      <Card sx={surveysGridCardStyle}>
        <DataGridPremium
          apiRef={gridRef}
          columns={renderedColumns}
          rows={rowData}
          disableColumnMenu
          sx={{
            ...gridStyle,
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          density="compact"
          disableRowSelectionOnClick
          slots={{
            footer: getFooter,
            noRowsOverlay: getNoRowsMessage,
          }}
          slotProps={{
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "linear-progress",
            },
          }}
          loading={loader}
          sortingMode="server"
          filterMode="server"
          getRowHeight={() => "auto"}
          onRowsScrollEnd={handleOnRowsScrollEnd}
        />
        <CustomSnackbar
          snackbarContent={snackBarContent}
          onClose={() => {
            setSnackBarContent(null);
          }}
        />
      </Card>
    </MDBox>
  );
};

export default UsersDataGrid;
