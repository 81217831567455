import { searchBarStyle } from "@ivueit/vue-engine";
import { TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import AdminsList from "./AdminsList";
import {
  Company,
  CompanyGroup,
  UserInfo,
} from "pages/profile/utils/ProfileInterfaces";
import { useEffect, useState } from "react";
import { PlaceholderComponent } from "components/CustomComponents/PlaceholderComponent";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  createOrgUser,
  getCompanyGroupsByID,
  removeOrgUser,
  whitelistAndInviteByEmail,
  whitelistEmailWithCompanyAdminRoles,
} from "../services/services";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { Portals } from "../utlis/interface";
import CustomDialogBox from "pages/components/CustomDialogBox";
import InviteDialogContent from "./InviteDialogContent";
import { CustomIndicator } from "pages/components/CustomIndicator";

const AdminsTabContent = ({
  company,
  usersList,
  fetchOrgUsers,
}: {
  company: Company;
  usersList?: UserInfo[];
  fetchOrgUsers: () => void;
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [companyList, setCompanyList] = useState<Company[]>([company]);
  const [openResendDialog, setOpenResendDialog] = useState<boolean>(false);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);

  const fetchCompanyGroups = async () => {
    setShowLoader(true);
    const response = await getCompanyGroupsByID(company.id);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as CompanyGroup;
      const companiesList = data.companies as Company[];
      if (companiesList && companiesList.length) {
        setCompanyList(companiesList);
      }
    } else {
      setCompanyList([...companyList]);
      const hasCompanyNotGrouped =
        response.error.toLowerCase() === "sql: no rows in result set";
      console.log(
        hasCompanyNotGrouped
          ? `${company.name} is not part of any company groups`
          : response.error
      );
    }
    setShowLoader(false);
  };

  const inviteOrgUser = async (
    email: string,
    inviteForAdminPortal: boolean,
    isExistingUser: boolean
  ) => {
    setShowLoader(true);
    const response = await whitelistAndInviteByEmail(
      email,
      company.id,
      inviteForAdminPortal
    );
    if (response.status === WebServiceStatus.success) {
      const data = response.data.recipient as string;
      if (data) {
        if (isExistingUser) {
          setSnackBar({
            title: "Success",
            message: "Resent invitation successfully",
            isError: false,
          });
          setOpenResendDialog(false);
        } else {
          await updateCompanyAdminRoles(inviteEmail);
        }
      }
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to send email invitation : ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const updateCompanyAdminRoles = async (email: string) => {
    setShowLoader(true);
    const response = await whitelistEmailWithCompanyAdminRoles(
      email,
      companyList
    );
    if (response.status === WebServiceStatus.success) {
      const value = response.data.value as boolean;
      if (value) {
        setSnackBar({
          title: "Success",
          message: "User created successfully",
          isError: false,
        });
        setOpenResendDialog(false);
        setInviteEmail("");
      }
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to update company roles for the newly created user: ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const createAndInviteOrgUser = async (inviteForAdminPortal: boolean) => {
    setShowLoader(true);
    const response = await createOrgUser(inviteEmail, company.id);
    if (response.status === WebServiceStatus.success) {
      await inviteOrgUser(inviteEmail, inviteForAdminPortal, false);
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to create new user : ${response.error}`,
        isError: true,
      });
    }
    fetchOrgUsers();
    setShowLoader(false);
  };

  const removeUser = async (userID: string) => {
    setShowLoader(true);
    const response = await removeOrgUser(userID, company.id);
    if (response.status === WebServiceStatus.success) {
      fetchOrgUsers();
      setSnackBar({
        title: "Success",
        message: "User removed successfully",
        isError: false,
      });
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to remove org user : ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getResendDialog = () => {
    return (
      <CustomDialogBox
        title={"Send Invite"}
        width="540px"
        openDialog={openResendDialog}
      >
        <InviteDialogContent
          closeDialog={() => {
            setOpenResendDialog(false);
          }}
          email={inviteEmail}
          onInvite={async (portal: string) => {
            const inviteForAdminPortal =
              portal.toLowerCase() === Portals.admin.toLowerCase();
            await createAndInviteOrgUser(inviteForAdminPortal);
          }}
        />
      </CustomDialogBox>
    );
  };

  useEffect(() => {
    fetchCompanyGroups();
  }, []);

  return (
    <MDBox px="16px" pt={4}>
      {openResendDialog && getResendDialog()}
      {showLoader && <CustomIndicator />}
      <MDBox mb={2}>
        <MDTypography sx={regularTextStyle}>Invite new member</MDTypography>
        <MDBox display="flex">
          <MDBox display="flex" flexDirection="column" width="360px">
            <TextField
              fullWidth
              sx={searchBarStyle}
              value={inviteEmail}
              placeholder="Enter Email"
              onChange={(event) => {
                const value = event.target.value as string;
                setInviteEmail(value);
              }}
            />
          </MDBox>
          <StyledButtonWithIcon
            disabled={inviteEmail.trim().isEmpty()}
            sx={{ ml: "5px", height: "40px" }}
            onClick={() => {
              setOpenResendDialog(true);
            }}
          >
            SEND INVITE
          </StyledButtonWithIcon>
        </MDBox>
      </MDBox>
      {usersList !== null ? (
        <AdminsList
          usersList={usersList}
          onResendInvite={async (email: string, portal: string) => {
            const inviteForAdminPortal =
              portal.toLowerCase() === Portals.admin.toLowerCase();
            await inviteOrgUser(email, inviteForAdminPortal, true);
          }}
          onRemoveUser={async (userID: string) => {
            await removeUser(userID);
          }}
        />
      ) : (
        <PlaceholderComponent label="Unable to fetch org user's list" />
      )}
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};

export default AdminsTabContent;
