import MDBox from "components/MDBox";
import VueDescription from "./VueDescription";
import {
  mdiCheckCircle,
  mdiCloseCircle,
  mdiTextBoxOutline,
} from "@mdi/js";
import { getVueStatus } from "../../vue-grid/utils/enums";
import {
  IndividualVue,
  VueHistory,
  VueHistoryType,
  VueSubmission,
  VueSubmissionUser,
} from "../utils/vue_detail_interface";
import { getAgingSLAValueInHours, getDay } from "../helpers/helper_methods";
import { useEffect, useState } from "react";
import { VueHistoryDialog } from "./VueHistoryDialog";
import { getUserByID, getVueSubmissionByID } from "../../services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { VuerMetaData } from "./VuerMetaData";
import { VuerBackgroundCheckState } from "pages/vuers/interface/interface";
import { getVuerBackgroundCheckState } from "pages/vuers/services/VuerServices";
import { BackgroundStatus } from "pages/vuers/utils/constants";

interface Props {
  individualVue: IndividualVue;
  canonicalID: string;
  vueHistory: VueHistory;
}

export const VueDetailMetaDataSection = (props: Props) => {
  const [openStatusDialog, setOpenStatusDialog] = useState<boolean>(false);
  const [vueSubmission, setVueSubmission] = useState<VueSubmission>(null);
  const [vuer, setVuer] = useState<VueSubmissionUser>(null);
  const [vuerBgStatus, setVuerBgStatus] = useState<string>(null);
  const { vue, vuerCompletedAt } = props.individualVue;
  const submissionID = props.individualVue.submissionId ?? "";
  const {
    title,
    arRequired,
    typeOf,
    cost,
    slaTarget,
    selectedDays,
    startsAt,
    endsAt,
    slaStartsAt,
    expiresAt,
    isInternal,
    streetAddress,
    addressTwo,
    city,
    stateProvinceAbbreviation,
    zipPostalCode,
    countryAbbreviation,
  } = vue;
  const isCompletedVue =
    getVueStatus(props.individualVue.status).toUpperCase() === "COMPLETED";
  const isInProgressVue =
    getVueStatus(props.individualVue.status).toUpperCase() === "IN PROGRESS";

  /// Status/History Dialog
  const getStatusDialog = () => {
    return (
      <VueHistoryDialog
        vueId={props.individualVue.vue.id}
        openDialog={openStatusDialog}
        handleCloseClick={() => {
          setOpenStatusDialog(false);
        }}
        title={title}
        type={VueHistoryType.statusHistory}
        vueHistory={props.vueHistory}
      />
    );
  };

  const getFormattedDateTime = (time: string) => {
    return (
      time?.formatUTCNanoSecondsToString("MM/DD/YYYY - hh:mm A", true) ?? ""
    );
  };

  const getSelectedDays = () => {
    const selectedDaysArray = JSON.parse(selectedDays).selectedDays as number[];
    const allDaysArray = [0, 1, 2, 3, 4, 5, 6];
    const areAllDaysSelected = allDaysArray.every((day) =>
      selectedDaysArray.includes(day)
    );
    return areAllDaysSelected
      ? "All"
      : selectedDaysArray.map((value) => getDay(value)).join(", ");
  };

  const getVuerCompletedValue = () => {
    return isCompletedVue && props.individualVue.vuerCompletedAt
      ? getFormattedDateTime(props.individualVue.vuerCompletedAt)
      : isCompletedVue && props.individualVue.lastUpdatedAt
      ? getFormattedDateTime(props.individualVue.lastUpdatedAt)
      : "NA";
  };

  const getAddress = () => {
    const addressComponents = [
      streetAddress,
      addressTwo,
      city,
      stateProvinceAbbreviation,
      zipPostalCode,
      countryAbbreviation,
    ];
    // Filter out undefined or empty components
    const validComponents = addressComponents.filter(
      (component) => component && component.trim() !== ""
    );
    // Construct the address string by joining valid components with commas
    const fullAddress = validComponents.join(", ");
    // If there are valid components, use the constructed address; otherwise, set it to an empty string
    return validComponents.length > 0 ? fullAddress : "";
  };

  const isVueSendBackToVuer = () => {
    if (props.vueHistory) {
      const listOfStatusHistory = props.vueHistory.submissions;
      const isVueSendBackToVuer = listOfStatusHistory.some((history) => {
        const { status, adminRejectionNotes, id } = history;
        const historyCount = listOfStatusHistory.length;
        return (
          status.toUpperCase() === "INPROGRESS" &&
          adminRejectionNotes !== "" &&
          historyCount > 0 &&
          id === listOfStatusHistory[historyCount - 1].id
        );
      });
      return isVueSendBackToVuer;
    }
  };

  const fetchVuerBackgroundCheckStatus = async (vuerID: string) => {
    const response = await getVuerBackgroundCheckState(vuerID);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as VuerBackgroundCheckState;
      const status = data.vuesVisibleOverride.toUpperCase();
      let bgStatus;
      if (data.id) {
        if (status === BackgroundStatus.forced_visible) {
          bgStatus = BackgroundStatus.proceed;
        } else if (status === BackgroundStatus.forced_not_visible) {
          bgStatus = BackgroundStatus.final_adverse;
        }
      } else {
        bgStatus = null;
      }
      setVuerBgStatus(bgStatus);
    } else {
      setVuerBgStatus(null);
    }
  };

  const fetchVuerData = async () => {
    const vueSubmissionResponse = await getVueSubmissionByID(submissionID);
    if (vueSubmissionResponse.status === WebServiceStatus.success) {
      const vueSubmission = vueSubmissionResponse.data as VueSubmission;
      const vuerID = vueSubmission.userId;
      const invalidUserID = "00000000-0000-0000-0000-000000000000";
      if (vuerID && vuerID !== invalidUserID) {
        const vuerResponse = await getUserByID(vuerID);
        if (vuerResponse.status === WebServiceStatus.success) {
          const vuerData = vuerResponse.data as VueSubmissionUser;
          setVuer(vuerData);
          fetchVuerBackgroundCheckStatus(vuerData.id);
        } else {
          setVuer(null);
        }
      } else if (vuerID === invalidUserID) {
        setVuer({ ...vuer, name: "System" });
      } else {
        setVuer(null);
      }
      setVueSubmission(vueSubmission);
    } else {
      setVueSubmission(null);
    }
  };

  const getVuerBgStatusIcon = () => {
    if (vuerBgStatus) {
      if (
        vuerBgStatus === BackgroundStatus.clear ||
        vuerBgStatus === BackgroundStatus.proceed
      ) {
        return mdiCheckCircle;
      } else if (vuerBgStatus === BackgroundStatus.final_adverse) {
        return mdiCloseCircle;
      }
    }
  };

  const getVuerBgStatusColor = () => {
    if (vuerBgStatus) {
      if (
        vuerBgStatus === BackgroundStatus.clear ||
        vuerBgStatus === BackgroundStatus.proceed
      ) {
        return "#4ba64f";
      } else if (vuerBgStatus === BackgroundStatus.final_adverse) {
        return "#AE1709";
      }
    }
  };

  useEffect(() => {
    if (submissionID) {
      fetchVuerData();
    }
  }, [submissionID]);

  return (
    <MDBox>
      {openStatusDialog && getStatusDialog()}
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        px={2}
        py={2}
        sx={{ overflowX: "auto" }}
      >
        <VueDescription title="Vue ID" value={`#${props.canonicalID}`} />
        <VueDescription
          title="STATUS"
          value={getVueStatus(props.individualVue.status).capitalizeWords()}
          iconPath={mdiTextBoxOutline}
          tagName={isVueSendBackToVuer() ? "SENT BACK" : ""}
          onClick={() => {
            setOpenStatusDialog(true);
          }}
        />
        {/* TODO: NEED TO UPDATE THIS */}
        <VueDescription title="Pending Notes" value={"-"} />
        <VueDescription
          title="Type"
          value={typeOf.capitalizeWords()}
          tagName={arRequired ? "AR" : ""}
        />
        <VueDescription title="Internal" value={isInternal ? "YES" : "NO"} />
        <VueDescription
          title="Vuer"
          value={vuer ? <VuerMetaData vuerData={vuer} /> : "None"}
          maxWidth="250px"
          iconPath={getVuerBgStatusIcon()}
          iconColor={getVuerBgStatusColor()}
        />
        <VueDescription title="Address" value={getAddress()} maxWidth="250px" />
        <VueDescription title="Cost" value={`$${cost}`} />
        {/* TODO: NEED TO UPDATE THIS */}
        <VueDescription title="Payout" value={"-"} />
        <VueDescription
          title="Age/SLA"
          value={getAgingSLAValueInHours(
            isCompletedVue,
            vuerCompletedAt,
            slaStartsAt,
            slaTarget
          )}
        />
        <VueDescription
          title="Publish At"
          value={getFormattedDateTime(slaStartsAt)}
        />
        <VueDescription title="Days Available" value={getSelectedDays()} />
        <VueDescription
          title="Times Available"
          value={`${startsAt.parsedTimeFromBackend(
            true
          )} - ${endsAt.parsedTimeFromBackend(true)}`}
        />
        {/* TODO: NEED TO UPDATE THIS */}
        <VueDescription
          title="Claim Time Limit in Minutes"
          value={"-" ?? "NA"}
        />
        <VueDescription
          title="AutoFail At"
          value={
            vueSubmission && isInProgressVue ? vueSubmission.autofailAt : "NA"
          }
        />
        <VueDescription
          title="Vuer Completed"
          value={getVuerCompletedValue()}
        />
        <VueDescription
          title="Completed"
          value={
            vueSubmission && isCompletedVue
              ? getFormattedDateTime(vueSubmission.vueCompletedAt)
              : "NA"
          }
        />
        <VueDescription
          title="Expires"
          value={(expiresAt && getFormattedDateTime(expiresAt.value)) ?? "NA"}
        />
      </MDBox>
    </MDBox>
  );
};
