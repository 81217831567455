import { DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  dialogText,
  regularTextStyle,
} from "pages/surveys/styles/SurveyStyles";
import { useState } from "react";
import { updateUserByID } from "../services/VuerServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { CustomIndicator, VuerData } from "@ivueit/vue-engine";

interface Props {
  vuer: VuerData;
  closeDialog: () => void;
  onSuccess: () => void;
}

const FailedVuesDialogContent = (props: Props) => {
  const { failedVues, id, vuesFailedForgiven } = props.vuer;
  const [newIgnoredCount, setNewIgnoredCount] =
    useState<string>(vuesFailedForgiven);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState<{
    description: string;
    failedVueIgnoredCount: string;
  }>({
    description: "",
    failedVueIgnoredCount: "",
  });

  const updateVuerDetail = async () => {
    const param = {
      VuesFailedForgiven: {
        value: parseInt(newIgnoredCount) > 0 ? newIgnoredCount : "0",
      },
    };
    setShowLoader(true);
    const response = await updateUserByID(id, param);
    if (response.status === WebServiceStatus.success) {
      props.onSuccess();
      props.closeDialog();
    } else {
      setSnackBar({
        title: "Attention!",
        message: `Unable to adjust Vue failures: ${response.error}`,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const onChangeFailedVuesIgnoredCount = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const enteredValue = event.target.value;
    const parsedEnteredValue = parseInt(event.target.value);
    const failedVueCount = parseInt(failedVues);
    setNewIgnoredCount(enteredValue);
    // Setting an error, if the entered new count is greater than available failed Vues count
    setError({
      ...error,
      failedVueIgnoredCount:
        parsedEnteredValue > failedVueCount
          ? "Value must be less than failed Vues count"
          : "",
    });
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <DialogContent>
        <MDBox mt={3} mb={3}>
          <MDTypography
            variant="body"
            sx={{ ...dialogText, fontWeight: "500", marginBottom: "10px" }}
          >
            Failed Vues : {failedVues}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" mb={1.4}>
          <MDBox flex={1}>
            <MDTypography sx={regularTextStyle}>
              Failed Vues Ignored
            </MDTypography>
            <MDInput
              placeholder="Enter ignored failed Vue count"
              value={newIgnoredCount}
              error={error.failedVueIgnoredCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeFailedVuesIgnoredCount(event);
              }}
              fullWidth
            />
            {error.failedVueIgnoredCount && (
              <MDTypography
                sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}
              >
                {error.failedVueIgnoredCount}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CLOSE
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            disabled={
              parseInt(newIgnoredCount) === parseInt(vuesFailedForgiven) ||
              parseInt(newIgnoredCount) > parseInt(failedVues)
            }
            onClick={async () => {
              await updateVuerDetail();
            }}
          >
            SAVE
          </MDButton>
        </>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default FailedVuesDialogContent;
