import { MailOutline, Phone } from "@mui/icons-material";
import { mdiFilterOff } from "@mdi/js";
import { Divider, InputAdornment, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ButtonWithIcon from "pages/components/ButtonWithIcon";
import { useEffect, useState } from "react";
import { TEXTFIELD_CHANGE_DELAY } from "../../../constants";

interface Props {
  previousSearch: SearchQuery;
  columnName: string;
  onFilterChange: (query: SearchQuery) => void;
  closePopper: (event: Event | React.SyntheticEvent<Element, Event>) => void;
}

export interface SearchQuery {
  email: string;
  phoneNumber: string;
}

const VuerDetailFilterView = ({
  previousSearch,
  columnName,
  onFilterChange,
  closePopper,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<SearchQuery>(previousSearch);
  const [searchStarted, setSearchStarted] = useState(false);

  // Handling the text search for the grid
  useEffect(() => {
    if (!searchStarted) {
      return;
    }
    /// The method delays the callback for 700 millseconds
    const delaySearchAction = setTimeout(() => {
      onFilterChange(searchQuery);
    }, TEXTFIELD_CHANGE_DELAY);
    return () => clearTimeout(delaySearchAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  /// Handles the onChange of the email search field
  const handleEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchStarted(true);
    const emailInput = event.target.value;
    setSearchQuery({ ...searchQuery, email: emailInput });
  };

  /// Handles the onChange of the email search field
  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchStarted(true);
    const phoneInput = event.target.value;
    setSearchQuery({ ...searchQuery, phoneNumber: phoneInput });
  };

  return (
    <>
      <MDBox px={2} pt={3}>
        <MDTypography fontWeight="bold" sx={{ fontSize: "14px" }}>
          Filter By:
        </MDTypography>
        <MDBox>
          <MDTypography fontWeight="bold" sx={{ fontSize: "14px" }}>
            Email
          </MDTypography>
          <TextField
            fullWidth
            placeholder={"Email"}
            value={searchQuery.email}
            InputLabelProps={{ shrink: true }}
            onChange={handleEmailInputChange}
            sx={{ py: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MailOutline fontSize="medium" sx={{ color: "#344767" }} />
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox>
          <MDTypography fontWeight="bold" sx={{ fontSize: "14px" }}>
            Phone Number
          </MDTypography>
          <TextField
            fullWidth
            type="tel"
            placeholder={"Phone Number"}
            value={searchQuery.phoneNumber}
            InputLabelProps={{ shrink: true }}
            onChange={handlePhoneInputChange}
            sx={{ py: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Phone fontSize="medium" sx={{ color: "#344767" }} />
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
      </MDBox>
      <Divider
        sx={{
          backgroundColor: "#A8B8D8",
          opacity: 0.5,
          height: "1x",
          backgroundImage: "none !important",
        }}
      />
      <MDBox px={3} pb={2}>
        <MDBox>
          <ButtonWithIcon
            iconPath={mdiFilterOff}
            fullWidth
            disabled={
              searchQuery.email.trim().isEmpty() &&
              searchQuery.phoneNumber.trim().isEmpty()
            }
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "#1A73E7",
              "&:disabled": {
                color: "#C7CCD0",
                borderColor: "#C7CCD0",
                opacity: 3,
              },
              "&:hover": { color: "#1A73E7", borderColor: "#C7CCD0" },
            }}
            onClick={(event: Event | React.SyntheticEvent<Element, Event>) => {
              // Sending an empty string to reset the search filters
              onFilterChange({
                email: "",
                phoneNumber: "",
              });
              closePopper(event);
            }}
          >
            CLEAR FILTERS
          </ButtonWithIcon>
        </MDBox>
      </MDBox>
    </>
  );
};

export default VuerDetailFilterView;
