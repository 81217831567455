import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

interface VueBottomBarProps {
    totalVues: number,
    openCount: number,
    inProgressCount: number,
    inReviewCount: number,
    completedCount: number,
}

const VueBottomBar = (props: VueBottomBarProps) => {
    return (
        <Grid container display="flex" px={4} pb={1.1} pt={0.6} pl={5} sx={{
            borderTop: "0.5px solid #A8B8D8"
        }}>
            <Grid item xs={12} md={12} lg={2} display="flex" alignItems="center" >
                <MDTypography
                    variant="h5"
                    fontWeight="bold"
                >SUMMARY
                </MDTypography>
            </Grid>
            <Grid item flex={1}>
                <MDTypography
                    variant="button"
                    color="textLight"
                    fontWeight="regular"
                >Total Vues
                </MDTypography>
                <MDTypography
                    variant="h5"
                >{props.totalVues}
                </MDTypography>
            </Grid>
            <Grid item flex={1}>
                <MDTypography
                    variant="button"
                    color="textLight"
                    fontWeight="regular"
                >Open
                </MDTypography>
                <MDTypography
                    variant="h5"
                >
                {isNaN(Number(props.openCount)) || Number(props.openCount) === 0 ? "-" : +props.openCount}
                </MDTypography>
            </Grid>
            <Grid item flex={1}>
                <MDTypography
                    variant="button"
                    color="textLight"
                    fontWeight="regular"
                >In Progress
                </MDTypography>
                <MDTypography
                    variant="h5"
                >
                 {isNaN(Number(props.inProgressCount)) || Number(props.inProgressCount) === 0 ? "-" : +props.inProgressCount}
                </MDTypography>
            </Grid>
            <Grid item flex={1}>
                <MDTypography
                    variant="button"
                    color="textLight"
                    fontWeight="regular"
                >In Review
                </MDTypography>
                <MDTypography
                    variant="h5"
                >
                 {isNaN(Number(props.inReviewCount)) || Number(props.inReviewCount) === 0 ? "-" : +props.inReviewCount}
                </MDTypography>
            </Grid>
            <Grid item flex={1}>
                <MDTypography
                    variant="button"
                    color="textLight"
                    fontWeight="regular"
                >Completed
                </MDTypography>
                <MDTypography
                    variant="h5"
                >
                {isNaN(Number(props.completedCount)) || Number(props.completedCount) === 0 ? "-" : +props.completedCount}
                </MDTypography>
            </Grid>
        </Grid>
    )
}

export default VueBottomBar;
