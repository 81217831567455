import { escalatedButtonStyle } from "@ivueit/vue-engine";
import {
  mdiPencil,
  mdiRepeatVariant,
  mdiAlert,
  mdiTag,
  mdiFileCode,
  mdiAttachment,
  mdiInformation,
  mdiTextBoxEdit,
  mdiCancel,
  mdiCheckCircle,
  mdiAlertCircle,
  mdiClockEdit,
  mdiFileSend,
  mdiArrowLeftCircle,
} from "@mdi/js";
import { MDBox, MDButton } from "../../vue-grid/helpers/imports";
import { StyledButtonWithIcon } from "./MediaActionBar";
import { getVueStatus } from "../../vue-grid/utils/enums";
import TooltipWrapper from "components/CustomComponents/TooltipWrapper";
import { btnToolTipStyle } from "../../styles/VueDetailStyles";

export enum VueDetailActionType {
  export,
  editsHistory,
  editVue,
  reissue,
  makeUrgent,
  escalated,
  tagVue,
  takeBack,
  viewMetadata,
  viewAttachments,
  complete,
  sendBackToVuer,
  fail,
  markPending,
  cancel,
}

interface Props {
  vueStatus: string;
  showEscalatedButton: boolean;
  disableMakeUrgent: boolean;
  isVueAlreadyMarkedAsUrgent: boolean;
  showAttachments: boolean;
  onClick: (type: VueDetailActionType) => void;
}

export const VueDetailActionBar = (props: Props) => {
  const { vueStatus, showAttachments, showEscalatedButton, onClick } = props;
  const isCompletedVue = getVueStatus(vueStatus).toUpperCase() === "COMPLETED";
  const isOpenOrInProgressVue =
    getVueStatus(vueStatus).toUpperCase() === "IN PROGRESS" ||
    getVueStatus(vueStatus).toUpperCase() === "OPEN";
  const isInReviewVue = getVueStatus(vueStatus).toUpperCase() === "IN REVIEW";
  const showCancelButton =
    getVueStatus(vueStatus).toUpperCase() !== "COMPLETED" &&
    getVueStatus(vueStatus).toUpperCase() !== "CANCELLED";
  const isInReviewOrInProgressVue =
    getVueStatus(vueStatus).toUpperCase() === "IN REVIEW" ||
    getVueStatus(vueStatus).toUpperCase() === "IN PROGRESS";

  return (
    <MDBox
      flexShrink="0"
      display="flex"
      flexDirection="column"
      alignItems="stretch"
    >
      <MDBox display="flex" flexDirection="column" alignItems="stretch">
        <MDBox display="flex" mr="12px">
          <MDBox display="flex" flex="1">
            <MDButton
              variant="contained"
              color="info"
              onClick={() => {
                onClick(VueDetailActionType.export);
              }}
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                width: "100%",
                whiteSpace: "nowrap",
              }}
            >
              EXPORT
            </MDButton>
          </MDBox>
          <MDBox display="flex" flex="1">
            <StyledButtonWithIcon
              iconPath={mdiTextBoxEdit}
              sx={{ width: "100%", whiteSpace: "nowrap", ml: "12px" }}
              onClick={() => {
                onClick(VueDetailActionType.editsHistory);
              }}
            >
              EDIT HISTORY
            </StyledButtonWithIcon>
          </MDBox>
        </MDBox>
        <MDBox display="flex" mt="12px" mr="12px">
          {isOpenOrInProgressVue && (
            <MDBox display="flex" flex="1">
              <StyledButtonWithIcon
                iconPath={mdiPencil}
                sx={{ mr: "12px", width: "100%", whiteSpace: "nowrap" }}
                onClick={() => {
                  onClick(VueDetailActionType.editVue);
                }}
              >
                EDIT
              </StyledButtonWithIcon>
            </MDBox>
          )}
          {isCompletedVue && (
            <MDBox display="flex" flex="1">
              <StyledButtonWithIcon
                iconPath={mdiRepeatVariant}
                sx={{ mr: "12px", width: "100%", whiteSpace: "nowrap" }}
                onClick={() => {
                  onClick(VueDetailActionType.reissue);
                }}
                disabled={!isCompletedVue}
              >
                Reissue
              </StyledButtonWithIcon>
            </MDBox>
          )}
          {showEscalatedButton && (
            <MDBox display="flex" flex="1">
              <StyledButtonWithIcon
                iconPath={mdiAlert}
                sx={{
                  ...escalatedButtonStyle,
                  mr: "12px",
                  width: "100%",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  onClick(VueDetailActionType.escalated);
                }}
              >
                ESCALATED
              </StyledButtonWithIcon>
            </MDBox>
          )}
          <MDBox display="flex" flex="1" mr={1.6} sx={btnToolTipStyle}>
            <TooltipWrapper
              title={
                props.isVueAlreadyMarkedAsUrgent
                  ? "Selected Vue is already marked as urgent"
                  : "Selected Vue is not in the Open status."
              }
              disableHoverListener={!props.disableMakeUrgent}
            >
              <StyledButtonWithIcon
                iconPath={mdiInformation}
                onClick={() => {
                  onClick(VueDetailActionType.makeUrgent);
                }}
                sx={{ width: "100%", whiteSpace: "nowrap" }}
                disabled={props.disableMakeUrgent}
              >
                MAKE URGENT
              </StyledButtonWithIcon>
            </TooltipWrapper>
          </MDBox>
          <MDBox display="flex" flex="1">
            <StyledButtonWithIcon
              iconPath={mdiTag}
              onClick={() => {
                onClick(VueDetailActionType.tagVue);
              }}
              sx={{ width: "100%", whiteSpace: "nowrap" }}
            >
              TAG VUE
            </StyledButtonWithIcon>
          </MDBox>
          {isInReviewOrInProgressVue && (
            <MDBox display="flex" flex="1" ml={1.6}>
              <StyledButtonWithIcon
                iconPath={mdiArrowLeftCircle}
                sx={{ width: "100%", whiteSpace: "nowrap" }}
                onClick={() => {
                  onClick(VueDetailActionType.takeBack);
                }}
              >
                TAKE BACK
              </StyledButtonWithIcon>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <MDBox display="flex" mt={1.6} mr="12px">
        <MDBox display="flex" flex="1">
          <StyledButtonWithIcon
            iconPath={mdiFileCode}
            onClick={() => {
              onClick(VueDetailActionType.viewMetadata);
            }}
            sx={{ width: "100%", whiteSpace: "nowrap" }}
          >
            VIEW METADATA
          </StyledButtonWithIcon>
        </MDBox>
        {showAttachments && (
          <MDBox ml={1.6} display="flex" flex="1">
            <StyledButtonWithIcon
              iconPath={mdiAttachment}
              onClick={() => {
                onClick(VueDetailActionType.viewAttachments);
              }}
              sx={{ width: "100%", whiteSpace: "nowrap" }}
            >
              VIEW ATTACHMENTS
            </StyledButtonWithIcon>
          </MDBox>
        )}
        {isInReviewVue && (
          <MDBox display="flex" flex="1">
            <StyledButtonWithIcon
              iconPath={mdiCheckCircle}
              sx={{ width: "100%", whiteSpace: "nowrap", ml: "12px" }}
              onClick={() => {
                onClick(VueDetailActionType.complete);
              }}
            >
              COMPLETE
            </StyledButtonWithIcon>
          </MDBox>
        )}
        {showCancelButton && (
          <MDBox display="flex" flex="1" ml={1.6}>
            <StyledButtonWithIcon
              iconPath={mdiCancel}
              sx={{ width: "100%", whiteSpace: "nowrap" }}
              onClick={() => {
                onClick(VueDetailActionType.cancel);
              }}
            >
              CANCEL
            </StyledButtonWithIcon>
          </MDBox>
        )}
      </MDBox>
      {isInReviewVue && (
        <MDBox display="flex" mt={1.6} mb={1} mr="12px">
          <MDBox display="flex" flex="1">
            <StyledButtonWithIcon
              iconPath={mdiFileSend}
              sx={{ width: "100%", whiteSpace: "nowrap" }}
              onClick={() => {
                onClick(VueDetailActionType.sendBackToVuer);
              }}
            >
              SEND BACK TO VUER
            </StyledButtonWithIcon>
          </MDBox>
          <MDBox display="flex" flex="1">
            <StyledButtonWithIcon
              iconPath={mdiAlertCircle}
              sx={{ ml: "12px", width: "100%", whiteSpace: "nowrap" }}
              onClick={() => {
                onClick(VueDetailActionType.fail);
              }}
            >
              FAIL
            </StyledButtonWithIcon>
          </MDBox>
          <MDBox display="flex" flex="1">
            <StyledButtonWithIcon
              iconPath={mdiClockEdit}
              sx={{ ml: "12px", width: "100%", whiteSpace: "nowrap" }}
              onClick={() => {
                onClick(VueDetailActionType.markPending);
              }}
            >
              MARK PENDING
            </StyledButtonWithIcon>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
};
