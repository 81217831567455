import { REQUEST_TYPE } from "pages/profile/services/ProfileServices";
import { DownloadFile } from "pages/profile/utils/ProfileInterfaces";
import {
  IndividualVuesAPIServices,
  VueBatchAPIServices,
  VueFileAPIServices,
  VueGridAPIServices,
  VueSurveyAPIServices,
  WebServiceResponse,
  WebServiceStatus,
} from "utils/services/AppUrls";
import {
  downloadPhotoFile,
  performGetRequest,
  performPostRequest,
  performPutRequest,
} from "utils/services/NetworkHandler";

///////// VUE GRID APIS  ////////////

export const getClientVues = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = VueGridAPIServices.getClientVues + "?" + parameters;
  return await performGetRequest(url);
};

export const getAllVueTagNameToFilter = async (
  companyIds: string[]
): Promise<WebServiceResponse> => {
  var url = VueGridAPIServices.getAllVueTagNamesToFilter + "?ids=";
  const allIds = companyIds.join("&ids=");
  url += allIds;
  return await performGetRequest(url);
};

export const getAllMediaTagNameToFilter = async (
  companyIds: string[]
): Promise<WebServiceResponse> => {
  var url = VueGridAPIServices.getAllMediaTagNamesToFilter + "?ids=";
  const allIds = companyIds.join("&ids=");
  url += allIds;
  return await performGetRequest(url);
};

export const getMyVuesGridPreferences =
  async (): Promise<WebServiceResponse> => {
    return await performGetRequest(VueGridAPIServices.getMyVuesGridPreferences);
  };

export const setMyVuesGridColumnWidth = async (columnData: {
  columnName: string;
  columnWidth: number;
}): Promise<WebServiceResponse> => {
  return await performPostRequest(
    VueGridAPIServices.setMyVuesGridColumnWidth,
    columnData
  );
};

export const setMyVuesGridPreferences = async (
  columnNames: string[]
): Promise<WebServiceResponse> => {
  return await performPostRequest(VueGridAPIServices.setMyVuesGridColumnOrder, {
    columnNames: columnNames,
  });
};

// Makes Vue as urgent
export const setVuesAsUrgent = async (
  vueIds: string[]
): Promise<WebServiceResponse> => {
  return await performPostRequest(VueGridAPIServices.setVuesAsUrgent, {
    ids: vueIds,
  });
};

/// Reissue selected Vues
export const reissueVues = async (
  vueIds: string[]
): Promise<WebServiceResponse> => {
  return await performPostRequest(VueGridAPIServices.reissueVue, {
    ids: vueIds,
  });
};

// Cancel selected vues
export const cancelVues = async (data: object): Promise<WebServiceResponse> => {
  const url = `${VueGridAPIServices.cancelVues}`;
  return await performPostRequest(url, data);
};

// Export PDF
export const generatePresignedFileURL = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VueGridAPIServices.exportPDF}/${id}`;
  return await performGetRequest(url);
};

/// Downloads the Vue image
export const downloadVueImage = async (imageId: string) => {
  try {
    const response = await downloadPhotoFile(
      `${VueGridAPIServices.downloadVueImage}/${imageId}`
    );
    if (response.status === WebServiceStatus.success) {
      const responseData = response.data as DownloadFile;
      return responseData;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

//////// INDIVIDUAL VUE APIS /////////

export const fetchIndividualVueWithCanonicalID = async (
  canonicalID: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.getIndividualVueByCanonicalId}/${canonicalID}`;
  return await performGetRequest(url);
};

export const fetchVueTagsByVueId = async (
  vueId: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.getVueTagsByVueId}/${vueId}`;
  return await performGetRequest(url);
};

export const updateVueTagsForVue = async (
  vueId: string,
  tagIds: string[]
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.replaceVueTagsForVue}/${vueId}/tags`;
  const newTags = { tagIds: tagIds };
  return await performPostRequest(url, newTags);
};

export const replacePhotoTags = async (
  fileIds: string[],
  stepStateId: string,
  tagIds: string[]
): Promise<WebServiceResponse> => {
  const url = IndividualVuesAPIServices.replaceMediaTags;
  const data = {
    tagIds: tagIds,
    fileIds: fileIds,
  };
  const params = stepStateId
    ? { ...data, surveyStepStateId: stepStateId }
    : data;
  return await performPostRequest(url, params);
};

export const getSurveyTemplateById = async (
  templateId: string
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.getSurveyTemplateById}/${templateId}`;
  return await performGetRequest(url);
};

export const getSurveyStepState = async (
  submissionId: string,
  stepId: string
): Promise<WebServiceResponse> => {
  const url = `${VueSurveyAPIServices.getSurveyTemplateStepState}/${submissionId}/${stepId}`;
  return await performGetRequest(url);
};

export const getPhotoFileInfo = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VueFileAPIServices.getPhotoFileInfo}/${id}/info`;
  return await performGetRequest(url);
};

/// We are using this to download the photo details & not the getPhotoFileInfo
export const downloadPhotoSectionFile = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VueFileAPIServices.downloadPhotoFile}/${id}`;
  return await performGetRequest(url);
};

export const updatePhotoFileInfo = async (
  photoId: string,
  vueId: string,
  editedInfos: REQUEST_TYPE
): Promise<WebServiceResponse> => {
  const url = `${VueFileAPIServices.updatePhotoFile}/${photoId}/info`;
  const params = {
    ...editedInfos,
    vueId: vueId,
  };
  return await performPostRequest(url, params);
};

export const uploadEditedPhoto = async (
  originalPhotoId: string,
  data: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(VueFileAPIServices.uploadEditedPhoto, {
    originalPhotoId: originalPhotoId,
    data: data,
  });
};

export const cancelPhotoEdit = async (
  originalPhotoId: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(VueFileAPIServices.deletePhotoEdit, {
    id: originalPhotoId,
  });
};

export const getAllPhotoEscalationReasonsByVueID = async (
  vueId: string
): Promise<WebServiceResponse> => {
  return await performGetRequest(
    `${VueGridAPIServices.getClientVues}/${vueId}/reasons`
  );
};

export const editVueDetails = async (
  vueDetail: any
): Promise<WebServiceResponse> => {
  return await performPostRequest(`${VueGridAPIServices.editVue}`, vueDetail);
};

// Add/Update Video Services

export const generatePresignedVideoFileURL = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VueFileAPIServices.generatePresignedVideoURL}/${id}`;
  return await performGetRequest(url);
};

export const getMediaTags = async (): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.getMediaTags}`;
  return await performGetRequest(url);
};

export const getVideoFileInfo = async (
  videoId: string
): Promise<WebServiceResponse> => {
  const url = `${VueFileAPIServices.handleVideoFileInfo}/${videoId}/info`;
  return await performGetRequest(url);
};

export const updateVideoFileInfo = async (
  videoId: string,
  vueId: string,
  editedData: REQUEST_TYPE
): Promise<WebServiceResponse> => {
  const url = `${VueFileAPIServices.handleVideoFileInfo}/${videoId}/info`;
  const params = {
    ...editedData,
    vueId: vueId,
  };
  return await performPostRequest(url, params);
};

export const regenerateReportsBySubmissionId = async (
  submissionId: string
): Promise<WebServiceResponse> => {
  // Generates the URL by replacing placeholder id with vue id
  const url = `${VueFileAPIServices.regenerateReports.replace(
    `:id`,
    submissionId
  )}`;
  return await performPostRequest(url);
};

export const getVueHistory = async (
  vueId: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.getVueHistory}/${vueId}`;
  return await performGetRequest(url);
};

export const completeVueSubmission = async (
  vueSubmisssionId: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.completeVueSubmission}`;
  const data = {
    id: vueSubmisssionId,
  };
  return await performPostRequest(url, data);
};

export const rejectToVuerVueSubmission = async (
  vueSubmisssionId: string,
  reason: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.rejectToVuerVueSubmission}`;
  const data = {
    id: vueSubmisssionId,
    reason: reason,
  };
  return await performPostRequest(url, data);
};

export const takeBackVueSubmission = async (
  vueSubmisssionId: string,
  reason: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.takeBackVueSubmission}`;
  const data = {
    id: vueSubmisssionId,
    reason: reason,
  };
  return await performPostRequest(url, data);
};

export const failVueSubmission = async (
  vueSubmisssionId: string,
  reason: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.failVueSubmission}`;
  const data = {
    id: vueSubmisssionId,
    reason: reason,
  };
  return await performPostRequest(url, data);
};

export const updateVueSubmissionPending = async (
  vueSubmisssionId: string,
  pendingNotes: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.updateVueSubmissionPending}`;
  const data = {
    id: vueSubmisssionId,
    pending: true,
    pendingNotes: pendingNotes,
  };
  return await performPostRequest(url, data);
};

export const cancelVueSubmission = async (
  vueSubmisssionId: string,
  reason: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.cancelVueSubmission}`;
  const data = {
    id: vueSubmisssionId,
    reason: reason,
  };
  return await performPostRequest(url, data);
};

export const cancelVue = async (
  vueId: string,
  reason: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.cancelVue}/${vueId}`;
  const data = {
    reason: reason,
  };
  return await performPutRequest(url, data);
};

export const getUserByID = async (
  userID: string
): Promise<WebServiceResponse> => {
  const url = `${IndividualVuesAPIServices.getUserById}/${userID}`;
  return await performGetRequest(url);
};

export const getVueSubmissionByID = async (
  submissionID: string
): Promise<WebServiceResponse> => {
  const url = IndividualVuesAPIServices.getVueSubmissionByID.replace(
    "{id}",
    submissionID
  );
  return await performGetRequest(url);
};
