import { Divider, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { VueSurveyData } from "../utils/vue_detail_interface";
import { getSurveyTemplateById } from "pages/dashboard/home/vues/services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { VueSurveyContent } from "./VueSurveyContent";
import { VueSurveyContentWithoutStepState } from "./VueSurveyContentWithoutStepState";
import Icon from "@mdi/react";
import { mdiInformation } from "@mdi/js";
import logo7 from "assets/images/logo7.png";

interface Props {
  vueOverview: string;
  vueDescription: string;
  surveyTemplateId: string;
  submissionId: string;
}

export const VueSurveySection = ({
  surveyTemplateId,
  submissionId,
  vueOverview,
  vueDescription,
}: Props) => {
  const [vueSurveyData, setVueSurveyData] = useState<VueSurveyData>();
  useEffect(() => {
    const fetchSurveyTemplate = async () => {
      if (surveyTemplateId.isEmpty()) {
        return;
      }

      const response = await getSurveyTemplateById(surveyTemplateId);
      if (response.status === WebServiceStatus.success) {
        // Extracts survey and groups from the response
        const { survey, groups, conditions, stepConditions } = response.data;
        setVueSurveyData({
          survey: survey,
          groups: groups,
          conditions: conditions,
          stepConditions: stepConditions,
        });
      }
    };

    fetchSurveyTemplate();
  }, [submissionId, surveyTemplateId]);

  const getSurveyScoreSection = () => {
    return (
      <MDBox
        ml="auto"
        display="flex"
        flexShrink="0"
        sx={{ pt: "40px", pr: "20px" }}
      >
        <MDBox display="flex" flexDirection="column" marginRight="15px">
          <MDTypography
            fontWeight="regular"
            sx={{ fontSize: "14px", color: "#6E7491" }}
          >
            iVueit Survey Score
          </MDTypography>
          <MDBox display="flex" flexDirection="row" marginLeft="auto">
            <Tooltip
              title="This score is based on a weighting scale assigned to specific questions in your survey."
              arrow
              placement="left"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: "12px",
                    "&.MuiTooltip-tooltip": {
                      backgroundColor: "#344767 !important",
                      padding: "8px",
                      borderRadius: "2px",
                      lineHeight: "14px",
                      maxWidth: "300px",
                      textAlign: "left",
                    },
                    ".MuiTooltip-arrow": {
                      color: "#344767 !important",
                    },
                  },
                },
              }}
            >
              <span>
                <Icon
                  path={mdiInformation}
                  size={0.8}
                  style={{ marginTop: "10px" }}
                />
              </span>
            </Tooltip>
            <MDTypography
              sx={{
                fontSize: "24px",
                ml: "10px",
                fontWeight: "500",
              }}
            >
              78%
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox>
          <img src={logo7} alt="ivue" width="30px" height="auto" />
        </MDBox>
      </MDBox>
    );
  };

  return (
    <>
      <MDBox display="flex">
        <MDBox>
          {vueSurveyData && (
            <MDBox sx={{ pt: "20px", px: "20px" }}>
              <MDTypography variant="h4" sx={{ fontSize: "22px", pb: "10px" }}>
                {vueSurveyData.survey.name}
              </MDTypography>
              {vueOverview && vueOverview.isNotEmpty() && (
                <MDTypography variant="h5" sx={{ fontSize: "18px", pb: "8px" }}>
                  Vue Overview:&nbsp;
                  <span style={{ fontWeight: "400" }}>
                    <ReactMarkdown linkTarget="_blank">
                      {vueOverview}
                    </ReactMarkdown>
                  </span>
                </MDTypography>
              )}
              {vueDescription && vueDescription.isNotEmpty() && (
                <MDTypography variant="h4" sx={{ fontSize: "18px" }}>
                  Vue Specific Instruction:&nbsp;
                  <span style={{ fontWeight: "400" }}>
                    <ReactMarkdown linkTarget="_blank">
                      {vueDescription}
                    </ReactMarkdown>
                  </span>
                </MDTypography>
              )}
            </MDBox>
          )}
        </MDBox>
        {/* Hiding this score section as it is an upcoming feature */}
        <MDBox display="none">{getSurveyScoreSection()}</MDBox>
      </MDBox>
      <Divider
        sx={{ backgroundColor: "#c7ccd0", height: "1px", borderWidth: 1 }}
      />
      {vueSurveyData && submissionId?.isEmpty() && (
        <VueSurveyContentWithoutStepState surveyData={vueSurveyData} />
      )}
      {vueSurveyData && submissionId?.isNotEmpty() && (
        <VueSurveyContent
          key={"survey-content"}
          surveyData={vueSurveyData}
          submissionId={submissionId}
        />
      )}
    </>
  );
};
