import {
  mdiTag,
  mdiAccountEdit,
  mdiEyeOff,
  mdiAlertOutline,
  mdiEye,
  mdiTrayArrowDown,
} from "@mdi/js";
import { Grid, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import ButtonWithIcon from "pages/components/ButtonWithIcon";
import { styled } from "@mui/material/styles";
import { escalatedButtonStyle } from "../../styles/VueDetailStyles";

/// Styles applied to the "ButtonWithIcon"
export const StyledButtonWithIcon = styled(ButtonWithIcon)(({ theme }) => ({
  paddingLeft: "14px",
  paddingRight: "14px",
  "&:disabled": {
    color: "#C7CCD0",
    borderColor: "#C7CCD0",
  },
  svg: {
    width: "18px !important",
    height: "18px !important",
  },
  cursor: "pointer",
}));

export enum PhotoActionButtonType {
  tag,
  edit,
  escalate,
  deescalate,
  hide,
  download,
}

interface Props {
  hasEscalated: boolean;
  hidePhoto: boolean;
  onButtonClick: (type: PhotoActionButtonType) => void;
  isStepTypeVideo: boolean;
}

export const MediaActionBar = ({
  hasEscalated,
  hidePhoto,
  onButtonClick,
  isStepTypeVideo,
}: Props) => {
  return (
    <MDBox display="flex" justifyContent="space-between" mt={1.5} pb={1.5}>
      <Grid container spacing={1}>
        <Grid item>
          <StyledButtonWithIcon
            iconPath={mdiTag}
            onClick={() => {
              onButtonClick(PhotoActionButtonType.tag);
            }}
          >
            TAG
          </StyledButtonWithIcon>
        </Grid>
        {!isStepTypeVideo && (
          <Grid item>
            <StyledButtonWithIcon
              iconPath={mdiAccountEdit}
              onClick={() => {
                onButtonClick(PhotoActionButtonType.edit);
              }}
            >
              EDIT
            </StyledButtonWithIcon>
          </Grid>
        )}
        <Grid item>
          <StyledButtonWithIcon
            iconPath={mdiAlertOutline}
            sx={hasEscalated ? { ...escalatedButtonStyle } : null}
            onClick={() => {
              onButtonClick(
                hasEscalated
                  ? PhotoActionButtonType.deescalate
                  : PhotoActionButtonType.escalate
              );
            }}
          >
            {hasEscalated ? "ESCALATED" : "ESCALATE"}
          </StyledButtonWithIcon>
        </Grid>
        <Grid item>
          <StyledButtonWithIcon
            iconPath={hidePhoto ? mdiEyeOff : mdiEye}
            onClick={() => {
              onButtonClick(PhotoActionButtonType.hide);
            }}
          >
            {hidePhoto ? "SHOW" : "HIDE"}
          </StyledButtonWithIcon>
        </Grid>
        <Grid item>
          <StyledButtonWithIcon
            iconPath={mdiTrayArrowDown}
            onClick={() => {
              onButtonClick(PhotoActionButtonType.download);
            }}
          >
            DOWNLOAD
          </StyledButtonWithIcon>
        </Grid>
      </Grid>
    </MDBox>
  );
};
