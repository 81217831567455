import { DialogContent } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import MDBox from "components/MDBox";

export const CustomIndicator = () => {
  return (
    <Dialog open={true} onClose={null}>
      <DialogContent>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={30} color="success" />
        </MDBox>
      </DialogContent>
    </Dialog>
  );
};
