import {
  VuerPageServicesAPIServices,
  WebServiceResponse,
} from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";

/// VUER SCREEN

/**
 * @deprecated GetUsers is deprecated in favor of SearchUsers
 */
export const getAllVuers = async (
  parameterString: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.getUsers}?${parameterString}`;
  return await performGetRequest(url);
};

/**
 * @deprecated FindUser is deprecated in favor of SearchUsers (when searching by nameQueryString or canonicalID)
 */
export const findUser = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.findUsers}?${parameters}`;
  return await performGetRequest(url);
};

export const searchUsers = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.searchUsers}?${parameters}`;
  return await performGetRequest(url);
};

export const fetchBlacklistedPhoneNumbers = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.blacklistedPhone}/blacklisted?${parameters}`;
  return await performGetRequest(url);
};

export const generateVuerListExport = async (
  phoneNumbers: string[]
): Promise<WebServiceResponse> => {
  const parameters = { blacklistedPhoneNumbers: phoneNumbers };
  const url = `${VuerPageServicesAPIServices.generateVuerExport}`;
  return await performPostRequest(url, parameters);
};

/// VUER DETAIL SCREEN
export const getUserBalance = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.getUsers}/${id}/balance`;
  return await performGetRequest(url);
};

export const isSMSWhitelisted = async (
  phoneNumber: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.verifyPhoneNumberStatus}/whitelist/status/${phoneNumber}`;
  return await performGetRequest(url);
};

export const blacklistSMS = async (
  phoneNumber: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.verifyPhoneNumberStatus}/${phoneNumber}/blacklist`;
  return await performPostRequest(url);
};

export const whitelistSMS = async (
  phoneNumber: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.verifyPhoneNumberStatus}/${phoneNumber}/whitelist`;
  return await performPostRequest(url);
};

export const getVuerBackgroundCheckState = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.getbackgroundCheckStatus}/${id}/backgroundcheckstate`;
  return await performGetRequest(url);
};

export const removeIllicitVuerProfilePhoto = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.getUsers}/${id}/profile-photo/remove`;
  return await performPostRequest(url);
};

export const getNearbyUsers = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.findUsers}/nearby?${parameters}`;
  return await performGetRequest(url);
};

export const updateUserByID = async (
  userId: string,
  params: any
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.getUsers}/${userId}/update`;
  return await performPostRequest(url, params);
};

export const createManualTransaction = async (
  userId: string,
  params: any
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.getUsers}/${userId}/transaction`;
  return await performPostRequest(url, params);
};

export const setUserType = async (
  userId: string,
  params: any
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.getUsers}/${userId}/type`;
  return await performPostRequest(url, params);
};

export const getTransactionsByUserID = async (
  id: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.getUsers}/${id}/transactions`;
  return await performGetRequest(url);
};

export const getVuesWithSubmissionStatusByUserID = async (
  id: string,
  parameters: string
): Promise<WebServiceResponse> => {
  const url = `${VuerPageServicesAPIServices.getSubmissionStatus}/${id}?${parameters}`;
  return await performGetRequest(url);
};
