import { Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import { mdiPlus } from "@mdi/js";
import { UsersGridContainer } from "../styles/UsersStyles";
import UsersList from "./UsersList";
import DataGridBottomBar from "pages/vuers/components/BottomBar";
import { useState, useEffect } from "react";
import {
  addOrgUserToCompanyGroup,
  getCompanyGroup,
  getOrgUsersByCompanyGroupId,
} from "../services/services";
import { Company, UserInfo } from "pages/profile/utils/ProfileInterfaces";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  CustomIndicator,
  GENERIC_ERROR_MESSAGE,
  WebServiceStatus,
} from "@ivueit/vue-engine";
import { GridMetaData } from "pages/vuers/interface/interface";
import { defaultMetaData } from "pages/surveys/utils/constants";
import { UsersSearchComponent } from "./UsersSearchComponent";
import { PlaceholderComponent } from "components/CustomComponents/PlaceholderComponent";
import { whitelistEmailWithCompanyAdminRoles } from "pages/clients/services/services";
interface Props {
  companyGroupId: string;
}

const UsersListTabContent = ({ companyGroupId }: Props) => {
  const [companyList, setCompanyList] = useState<Company[]>([]);
  // List of users in the group
  const [userList, setUserList] = useState<UserInfo[]>([]);
  // This is to store the new user info which we wish to add to the company group
  const [userToAdd, setUserToAdd] = useState<UserInfo | null>(null);
  const [metaValues, setMetaValues] = useState<GridMetaData>(defaultMetaData);
  const [loader, setLoader] = useState(false);
  const [snackBarContent, setSnackBarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [showInactive, setShowInactive] = useState<boolean>(false);

  const fetchAllUsersByCompanyGroup = async () => {
    setLoader(true);
    const response = await getOrgUsersByCompanyGroupId(
      companyGroupId,
      `pagination.pageSize=100&pagination.pageNumber=1&showDeleted=${showInactive}`
    );
    if (response.status === WebServiceStatus.success) {
      const {
        orgUsers,
        meta,
      }: {
        orgUsers: UserInfo[];
        meta: GridMetaData;
      } = response.data;
      setMetaValues(meta);
      setUserList(orgUsers);
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchAllUsersByCompanyGroup();
    fetchCompanyGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyGroupId, showInactive]);

  const fetchCompanyGroups = async () => {
    setLoader(true);
    const response = await getCompanyGroup(companyGroupId);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.companies as Company[];
      setCompanyList(data);
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: `Unable to fetch Company groups : ${response.error}`,
        isError: true,
      });
    }
    setLoader(false);
  };

  const addUserToCompanyGroup = async () => {
    setLoader(true);
    const response = await addOrgUserToCompanyGroup(
      userToAdd.id,
      companyGroupId
    );
    if (response.status === WebServiceStatus.success) {
      await whiteListEmailAndAddAdminRole();
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setLoader(false);
  };

  const whiteListEmailAndAddAdminRole = async () => {
    setLoader(true);
    const response = await whitelistEmailWithCompanyAdminRoles(
      userToAdd.email,
      companyList
    );
    if (response.status === WebServiceStatus.success) {
      await fetchAllUsersByCompanyGroup();
      setUserToAdd(null);
      setSnackBarContent({
        title: "Attention!",
        message: "User added successfully!",
        isError: false,
      });
    } else {
      setSnackBarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setLoader(false);
  };

  return (
    <MDBox px="16px" pt={4}>
      {loader && <CustomIndicator />}
      <MDBox mb={2}>
        <MDBox display="flex" alignItems="center">
          <MDBox>
            <MDTypography sx={regularTextStyle}>Add User</MDTypography>
            <MDBox display="flex">
              <MDBox display="flex" flexDirection="column" width="360px">
                <UsersSearchComponent
                  user={userToAdd}
                  onSuccess={(user?: UserInfo) => {
                    setUserToAdd(user);
                  }}
                />
              </MDBox>
              <StyledButtonWithIcon
                sx={{
                  ml: "5px",
                  minWidth: "unset",
                  px: "11px",
                  height: "40px",
                }}
                disabled={!userToAdd}
                onClick={addUserToCompanyGroup}
                iconPath={mdiPlus}
              />
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center" mt={2.2} ml={4}>
            <Switch
              value={showInactive}
              onChange={() => {
                setShowInactive(!showInactive);
              }}
            />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ userSelect: "none" }}
            >
              Show Inactive
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox sx={UsersGridContainer}>
        {userList.length > 0 ? (
          <>
            <UsersList
              orgUsers={userList}
              companyGroupId={companyGroupId}
              onRefresh={fetchAllUsersByCompanyGroup}
            />
            <DataGridBottomBar
              title={"Total Active Users"}
              totalItems={userList.length}
              sx={{
                position: "sticky",
                bottom: "0",
                zIndex: "125",
                background: "white",
                marginTop: "auto",
              }}
            />
          </>
        ) : (
          <MDBox m="auto">
            <PlaceholderComponent label="No users available" />
          </MDBox>
        )}
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBarContent}
        onClose={() => {
          setSnackBarContent(null);
        }}
      />
    </MDBox>
  );
};

export default UsersListTabContent;
