import {
  CardMedia,
  CardContent,
  Grid,
  Card,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@mdi/react";
import { mdiArrowExpand } from "@mdi/js";
import MDTypography from "components/MDTypography";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { useState, useEffect } from "react";
import { MDBox, MDButton, ModalPopUp } from "../../vue-grid/helpers/imports";
import {
  blurImageStyle,
  cardContentStyle,
  cardStyle,
  photoSectionBadgeStyle,
  photoSectionCardStyle,
  escalatedCardStyle,
  mapToggleButtonStyle,
  loaderStyle,
} from "../../styles/VueDetailStyles";
import { EscalateDialogContent } from "./EscalateDialogContent";
import { MediaActionBar, PhotoActionButtonType } from "./MediaActionBar";
import { useVueProvider } from "pages/dashboard/home/vues/context/VueProvider";
import { tooltipStyles } from "../../vue-grid/components/VueActionBar";
import CustomImageEditor from "pages/dashboard/home/vues/vue_detail/components/image_editor/CustomImageEditor";
import { ImagePopUp } from "./ImagePopUp";
import { EscalationSummary } from "./EscalationSummary";
import {
  IndividualVue,
  PhotoFileKeys,
  PhotoFileInfo,
  VideoFileInfo,
} from "../utils/vue_detail_interface";
import {
  generatePresignedVideoFileURL,
  regenerateReportsBySubmissionId,
  updatePhotoFileInfo,
  updateVideoFileInfo,
  uploadEditedPhoto,
} from "../../services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { IndividualVueActionType } from "../utils/reducer";
import { REQUEST_TYPE } from "pages/profile/services/ProfileServices";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { compressBase64Image } from "utils/helpers/extensions";
import {
  GENERIC_ERROR_MESSAGE,
  MAX_FILE_SIZE_IN_MB,
} from "../../../../../../constants";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { VideoPlayer } from "pages/dashboard/home/vues/vue_detail/components/VideoPlayer";
import { TagItem, TagPhotoDialogContent } from "./TagPhotoDialogContent";
import VideoThumbnail from "./VideoThumbnail";
import { downloadFileToDevice, CustomDialogBox } from "@ivueit/vue-engine";

interface Props {
  photoFileInfo?: PhotoFileInfo;
  isStepTypeVideo?: boolean;
  videoFileInfo?: VideoFileInfo;
}

export const VueMediaSection = ({
  photoFileInfo,
  isStepTypeVideo,
  videoFileInfo,
}: Props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [mediaTagsList, setMediaTagsList] = useState<TagItem[]>([]);
  const [showTagPhotoDialog, setShowTagPhotoDialog] = useState<boolean>(false);
  const [showEscalateDialog, setShowEscalateDialog] = useState<boolean>(false);
  const [showDeEscalateDialog, setShowDeEscalateDialog] =
    useState<boolean>(false);
  const [showPhotoEditor, setShowPhotoEditor] = useState<boolean>(false);
  /// Dialog which shows the photo
  const [openMaximizePhotoDialog, setOpenMaximizeDialogPhoto] =
    useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [videoFileURL, setVideoFileURL] = useState("");
  /// Details related to photo stored in context
  const {
    individualVue,
    allCompanyMediaTags,
    dispatchIndividualVueAction,
    storeLatestSelectedVueImage,
    latestSelectedVueImage,
  } = useVueProvider();
  const { id, canonicalId } = (individualVue as IndividualVue).vue;
  const submissionId = (individualVue as IndividualVue).submissionId;

  useEffect(() => {
    let escalatedMediasList = [];
    if (photoFileInfo) {
      escalatedMediasList = individualVue.photos.filter(
        (photo: PhotoFileInfo) => photo.escalated && !photo.adminEscalated
      );
    } else if (videoFileInfo) {
      escalatedMediasList = individualVue.videos.filter(
        (video: VideoFileInfo) => video.escalated && !video.adminEscalated
      );
    }
    if (escalatedMediasList.length === 0) {
      dispatchIndividualVueAction({
        type: IndividualVueActionType.editVueEscalation,
        payload: {
          ...individualVue,
          vue: {
            ...individualVue.vue,
            escalated: false,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualVue.photos, individualVue.videos]);

  useEffect(() => {
    let appliedMediaTags: string[] = [];
    if (photoFileInfo) {
      appliedMediaTags = photoFileInfo.appliedMediaTags as string[];
    } else if (videoFileInfo) {
      appliedMediaTags = videoFileInfo.appliedMediaTags as string[];
    } else {
      return;
    }
    if (appliedMediaTags.length > 0) {
      const listOfParsedTagNames: TagItem[] = appliedMediaTags.map((tag) => {
        const parsedObj = JSON.parse(tag);
        return parsedObj;
      });
      setMediaTagsList(listOfParsedTagNames);
    } else {
      setMediaTagsList([]);
    }
  }, [photoFileInfo, videoFileInfo]);

  useEffect(() => {
    const getVideoURL = async () => {
      const response = await generatePresignedVideoFileURL(videoFileInfo.id);
      if (response.status === WebServiceStatus.success) {
        const { url } = response.data;
        setVideoFileURL(url);
      }
    };

    if (isStepTypeVideo && videoFileInfo) {
      // Fetches the video URL
      getVideoURL();
    }
  }, [videoFileInfo, isStepTypeVideo]);

  /// Handles the click on "Action Bars"
  const onActionBarButtonClick = async (type: PhotoActionButtonType) => {
    switch (type) {
      case PhotoActionButtonType.tag:
        setShowTagPhotoDialog(true);
        break;
      case PhotoActionButtonType.edit:
        if (!isStepTypeVideo) {
          setShowPhotoEditor(true);
        }
        break;
      case PhotoActionButtonType.escalate:
        setShowEscalateDialog(true);
        break;
      case PhotoActionButtonType.deescalate:
        setShowDeEscalateDialog(true);
        break;
      case PhotoActionButtonType.hide:
        handleMediaVisibility();
        break;
      case PhotoActionButtonType.download:
        let imageURL = "";
        let fileNameArray: string[] = [];
        if (isStepTypeVideo) {
          imageURL = videoFileURL;
          fileNameArray = videoFileInfo.filename.split("/");
        } else {
          imageURL = `data:${photoFileInfo.mimeType};base64,${photoFileInfo.data}`;
          fileNameArray = photoFileInfo.filename.split("/");
        }
        const fileName = fileNameArray[fileNameArray.length - 1];
        const mimeType = isStepTypeVideo
          ? videoFileInfo.mimeType
          : photoFileInfo.mimeType;
        try {
          await downloadFileToDevice(imageURL, fileName, mimeType);
        } catch (error) {
          setSnackbarContent({
            title: "Attention!",
            message: "Failed to download the media",
            isError: true,
          });
        }
        break;
      default:
        break;
    }
  };

  const updatePhotoFile = async (
    request: REQUEST_TYPE,
    actionType: IndividualVueActionType
  ) => {
    setShowLoader(true);
    const response = await updatePhotoFileInfo(photoFileInfo.id, id, request);
    if (response.status === WebServiceStatus.success) {
      const photoFile = {
        ...response.data,
        data: photoFileInfo.data,
        stepStateId: photoFileInfo.stepStateId,
        serialNumber: photoFileInfo.serialNumber,
      } as PhotoFileInfo;
      dispatchIndividualVueAction({
        type: actionType,
        payload: {
          ...individualVue,
          photos: [photoFile],
        },
      });
      /// Updating the locally stored photo props
      if (latestSelectedVueImage) {
        const key = Object.keys(latestSelectedVueImage)[0];
        const value = latestSelectedVueImage[key];
        if (value && key === canonicalId && value.id === photoFile.id) {
          storeLatestSelectedVueImage(canonicalId, photoFile);
        }
      }
      // Regenerating pdf reports on updating the photo details
      await regeneratePDFReports();
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const updateVideoFile = async (
    request: REQUEST_TYPE,
    actionType: IndividualVueActionType
  ) => {
    setShowLoader(true);
    const response = await updateVideoFileInfo(videoFileInfo.id, id, request);
    if (response.status === WebServiceStatus.success) {
      const videoFile = {
        ...response.data,
        stepStateId: videoFileInfo.stepStateId,
        serialNumber: videoFileInfo.serialNumber,
      } as VideoFileInfo;
      dispatchIndividualVueAction({
        isStepTypeVideo: true,
        type: actionType,
        payload: {
          ...individualVue,
          videos: [videoFile],
        },
      });
      /// Updating the locally stored video props
      if (latestSelectedVueImage) {
        const key = Object.keys(latestSelectedVueImage)[0];
        const value = latestSelectedVueImage[key];
        if (value && key === canonicalId && value.id === videoFile.id) {
          storeLatestSelectedVueImage(canonicalId, videoFile);
        }
      }
      // Regenerating pdf reports on updating the video details
      await regeneratePDFReports();
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const regeneratePDFReports = async () => {
    setShowLoader(true);
    const response = await regenerateReportsBySubmissionId(submissionId);
    if (response.status === WebServiceStatus.success) {
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getPhotoEditor = () => {
    if (!photoFileInfo) {
      // Ignores the request when photoFile is not available
      return <></>;
    }
    return (
      <CustomDialogBox
        title="Edit Photo"
        openDialog={showPhotoEditor}
        width="100%"
        disableEnforceFocus // Required to fix CP-766
      >
        <CustomImageEditor
          photoFileInfo={photoFileInfo}
          saveButtonClick={async (imageData) => {
            await uploadEditedFile(imageData, photoFileInfo);
            setShowPhotoEditor(false);
          }}
          cancelButtonClick={() => {
            setShowPhotoEditor(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  const uploadEditedFile = async (
    imageData: string,
    originalPhotoInfo: PhotoFileInfo
  ) => {
    setShowLoader(true);
    const originalPhotoId = originalPhotoInfo.id;
    const compressedData = await compressBase64Image(
      imageData,
      MAX_FILE_SIZE_IN_MB,
      1024
    );
    const reader = new FileReader();
    reader.onloadend = async (event) => {
      const dataURL = reader.result as string;
      const extractedUrl = dataURL.split(",")[1];
      const photoEditResponse = await uploadEditedPhoto(
        originalPhotoId,
        extractedUrl
      );
      setShowLoader(false);
      if (photoEditResponse.status === WebServiceStatus.error) {
        setSnackbarContent({
          title: "Attention!",
          message: photoEditResponse.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      } else {
        const { assignedId } = photoEditResponse.data;
        if (assignedId) {
          var updatedPhoto = { ...photoFileInfo };
          updatedPhoto.data = imageData.replace("data:image/jpeg;base64,", "");
          dispatchIndividualVueAction({
            type: IndividualVueActionType.editMediaEscalation,
            payload: {
              ...individualVue,
              photos: [updatedPhoto],
            },
          });
        }
        await regeneratePDFReports();
      }
    };
    reader.readAsDataURL(compressedData);
    setShowLoader(false);
  };

  const onSaveTags = (updatedTags: string[]) => {
    const mediaFile = isStepTypeVideo
      ? { ...videoFileInfo }
      : { ...photoFileInfo };
    const updatedMediaFile = {
      ...mediaFile,
      appliedMediaTags: updatedTags,
    };
    let payload = {};
    if (isStepTypeVideo) {
      payload = {
        ...individualVue,
        videos: [updatedMediaFile],
      };
    } else {
      payload = {
        ...individualVue,
        photos: [updatedMediaFile],
      };
    }
    dispatchIndividualVueAction({
      isStepTypeVideo: isStepTypeVideo,
      type: IndividualVueActionType.editMediaTags,
      payload: payload,
    });
    /// Updating the locally stored photo props
    if (photoFileInfo && latestSelectedVueImage) {
      const key = Object.keys(latestSelectedVueImage)[0];
      const value: PhotoFileInfo = latestSelectedVueImage[key];
      if (value && key === canonicalId && value.id === updatedMediaFile.id) {
        storeLatestSelectedVueImage(canonicalId, updatedMediaFile);
      }
    }
  };

  /// Tag vue dialog
  const getTagPhotoDialog = () => {
    var infoId = "";
    var stepStateId = "";
    if (photoFileInfo) {
      infoId = photoFileInfo.id;
      stepStateId = photoFileInfo.stepStateId;
    } else if (videoFileInfo) {
      infoId = videoFileInfo.id;
      stepStateId = videoFileInfo.stepStateId;
    }

    return (
      <CustomDialogBox
        title="Tag Photo"
        width="440px"
        openDialog={showTagPhotoDialog}
      >
        <TagPhotoDialogContent
          mediaIdsList={[infoId]}
          stepStateId={stepStateId}
          allCompanyMediaTags={allCompanyMediaTags}
          previouslyAddedTags={mediaTagsList}
          cancelButtonClick={() => {
            setShowTagPhotoDialog(false);
          }}
          saveButtonClick={(updatedTags: string[]) => {
            onSaveTags(updatedTags);
            setShowTagPhotoDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  const getEscalateDialog = () => {
    return (
      <CustomDialogBox
        title={`Escalate ${isStepTypeVideo ? "Video" : "Photo"}`}
        width="420px"
        openDialog={showEscalateDialog}
      >
        <EscalateDialogContent
          closeDialog={(hasEscalated: boolean, reason: string) => {
            if (hasEscalated) {
              const param = {
                [PhotoFileKeys.escalated]: { value: hasEscalated },
                [PhotoFileKeys.escalationNotes]: { value: reason },
              };
              if (isStepTypeVideo) {
                updateVideoFile(
                  param,
                  IndividualVueActionType.editMediaEscalation
                );
              } else {
                updatePhotoFile(
                  param,
                  IndividualVueActionType.editMediaEscalation
                );
              }
              if (!individualVue.vue.escalated) {
                dispatchIndividualVueAction({
                  type: IndividualVueActionType.editVueEscalation,
                  payload: {
                    ...individualVue,
                    vue: {
                      ...individualVue.vue,
                      escalated: true,
                    },
                  },
                });
              }
            }
            setShowEscalateDialog(false);
          }}
          label={`Please enter a reason for Escalating this ${
            isStepTypeVideo ? "video" : "photo"
          }`}
          characterLength={71}
        />
      </CustomDialogBox>
    );
  };

  const deEscalatePhoto = () => {
    const param = {
      [PhotoFileKeys.escalated]: { value: false },
      [PhotoFileKeys.escalationNotes]: { value: "" },
    };
    if (isStepTypeVideo) {
      updateVideoFile(param, IndividualVueActionType.editMediaEscalation);
    } else {
      updatePhotoFile(param, IndividualVueActionType.editMediaEscalation);
    }
    setShowDeEscalateDialog(false);
  };

  /// Generates actions for de-escalate dialog box
  const getDeEscalateDialogActions = () => {
    const actions = [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={() => {
          setShowDeEscalateDialog(false);
        }}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={deEscalatePhoto}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        {`DE-ESCALATE ${isStepTypeVideo ? "VIDEO" : "PHOTO"}`}
      </MDButton>,
    ];
    return actions;
  };

  const getDeEscalatePhotoDialog = () => {
    return (
      <CustomDialogBox
        title={`De-escalate ${isStepTypeVideo ? "Video" : "Photo"}`}
        width="500px"
        openDialog={showDeEscalateDialog}
      >
        <ModalPopUp
          content={
            <EscalationSummary
              escalatedPhoto={photoFileInfo}
              escalatedVideo={videoFileInfo}
              isStepTypeVideo={isStepTypeVideo}
              showDeEscalateButton={false}
            />
          }
          closeDialog={() => {
            setShowDeEscalateDialog(false);
          }}
          actions={getDeEscalateDialogActions()}
        />
      </CustomDialogBox>
    );
  };

  const getTypography = (label: string) => {
    return (
      <MDTypography sx={cardContentStyle} variant="caption">
        {label}
      </MDTypography>
    );
  };

  /// This returns the Tags for the vue
  const getTags = () => {
    return (
      <>
        {mediaTagsList.slice(0, 2).map((tag: TagItem, index: number) => (
          <CustomBadge
            key={index}
            content={tag.name}
            max={parseInt(tag.name)}
          />
        ))}
        {mediaTagsList.length > 2 && (
          <Tooltip
            title={mediaTagsList.slice(2).map((tag: TagItem, index: number) => {
              return (
                <span key={index}>
                  <b key={tag.name}>
                    {tag.name}
                    <br />
                  </b>
                </span>
              );
            })}
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: { ...tooltipStyles },
              },
            }}
            disableHoverListener={false}
          >
            <span>
              <MDTypography
                sx={{
                  pl: 0.8,
                  fontSize: "14px",
                  fontWeight: "regular",
                  color: "#FFFFFF",
                }}
              >
                +{mediaTagsList.length - 2} more
              </MDTypography>
            </span>
          </Tooltip>
        )}
      </>
    );
  };

  // Prepares the card style for the UI
  const getCardStyle = () => {
    var style = { ...cardStyle };
    if (getEscalatedValue()) {
      style = { ...style, ...escalatedCardStyle };
    }
    if (shouldHideMedia()) {
      style = { ...style, ...blurImageStyle };
    }
    return style;
  };

  const getTimeString = (value: string): string => {
    // Time in nano seconds
    return value.formatUTCNanoSecondsToString("MM/DD/YYYY - hh:mm A", true);
  };

  const handleMediaVisibility = async () => {
    const visibility = isStepTypeVideo
      ? !videoFileInfo.adminHide
      : !photoFileInfo.adminHide;
    const param = {
      [PhotoFileKeys.adminHide]: { value: visibility },
    };
    if (isStepTypeVideo) {
      updateVideoFile(param, IndividualVueActionType.editMediaVisibility);
    } else {
      updatePhotoFile(param, IndividualVueActionType.editMediaVisibility);
    }
  };

  const getVideoPlayerComponent = () => {
    return (
      <MDBox className="heightFix">
        {videoFileURL.isNotEmpty() ? (
          <MDBox position="relative">
            {getTagComponent()}
            <MDBox display="flex" justifyContent="center" className="heightFix">
              {shouldHideMedia() ? (
                <VideoThumbnail
                  thumbnailFileId={videoFileInfo.thumbnailFileId}
                  imageWidth={"100%"}
                  imageHeight={"42vh"}
                />
              ) : (
                <VideoPlayer
                  sourceURL={videoFileURL}
                  shouldPlay={true}
                  mimeType={videoFileInfo.mimeType}
                />
              )}
            </MDBox>
          </MDBox>
        ) : (
          <MDTypography textAlign="center" sx={{ mt: 16 }}>
            Unable to load the video
          </MDTypography>
        )}
      </MDBox>
    );
  };

  const getTagComponent = () => {
    if (mediaTagsList.length <= 0) {
      return <></>;
    }
    return (
      <MDBox
        display="flex"
        position="absolute"
        top="0"
        width="100%"
        alignItems="center"
        height="70px"
        bgColor="rgba(0, 0, 0, 0.5)"
        zIndex={125}
        pl={1}
      >
        <MDBox sx={photoSectionBadgeStyle}>{getTags()}</MDBox>
      </MDBox>
    );
  };

  const getImageComponent = () => {
    return (
      <MDBox
        position="relative"
        flexGrow="1"
        display="flex"
        className="heightFix"
      >
        {getTagComponent()}
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow="1"
        >
          <CardMedia
            component="img"
            width="auto"
            image={`data:${photoFileInfo.mimeType};base64,${photoFileInfo.data}`}
            alt="Vue"
            sx={{ objectFit: "contain", maxWidth: "100%" }}
          />
        </MDBox>
        <MDButton
          sx={mapToggleButtonStyle}
          onClick={() => {
            setOpenMaximizeDialogPhoto(true);
          }}
          iconOnly
        >
          <Icon path={mdiArrowExpand} size={1.1} />
        </MDButton>
      </MDBox>
    );
  };

  const getMediaComponent = () => {
    return isStepTypeVideo ? getVideoPlayerComponent() : getImageComponent();
  };

  const getEscalatedValue = () => {
    if (photoFileInfo) {
      return photoFileInfo.escalated || photoFileInfo.adminEscalated;
    } else if (videoFileInfo) {
      return videoFileInfo.escalated || videoFileInfo.adminEscalated;
    }
    return false;
  };

  const shouldHideMedia = () => {
    if (photoFileInfo) {
      return photoFileInfo.adminHide;
    } else if (videoFileInfo) {
      return videoFileInfo.adminHide;
    }
    return false;
  };

  const getCardContentSection = () => {
    let serialNumber = "";
    let address = "";
    let capturedAt = "";
    if (photoFileInfo) {
      serialNumber = photoFileInfo.serialNumber;
      address = photoFileInfo.address;
      capturedAt = photoFileInfo.capturedAt ?? "";
    } else if (videoFileInfo) {
      serialNumber = videoFileInfo.serialNumber;
      address = videoFileInfo.address;
      capturedAt = videoFileInfo.capturedAt ?? "";
    }
    return (
      <CardContent sx={{ padding: "13px 10px 12px 15px" }}>
        <Grid
          container
          py={1}
          sx={{ ...photoSectionCardStyle, flexWrap: "nowrap" }}
        >
          <Grid item sx={{ flexShrink: "0" }}>
            {getTypography(serialNumber)}
          </Grid>
          <Grid item pl={3} pr={1.4}>
            <MDBox display="flex" flexDirection="column">
              {capturedAt.isNotEmpty &&
                getTypography(getTimeString(capturedAt))}
              {getTypography(address)}
            </MDBox>
          </Grid>
        </Grid>
      </CardContent>
    );
  };

  return (
    <>
      {photoFileInfo || videoFileInfo ? (
        <>
          {showLoader && <CustomIndicator />}
          {showPhotoEditor && getPhotoEditor()}
          {showEscalateDialog && getEscalateDialog()}
          {showTagPhotoDialog && getTagPhotoDialog()}
          {showDeEscalateDialog && getDeEscalatePhotoDialog()}
          <Card sx={getCardStyle()}>
            {getMediaComponent()}
            {getCardContentSection()}
          </Card>
          <MediaActionBar
            onButtonClick={onActionBarButtonClick}
            hasEscalated={getEscalatedValue()}
            hidePhoto={shouldHideMedia()}
            isStepTypeVideo={isStepTypeVideo}
          />
          {openMaximizePhotoDialog && photoFileInfo && (
            <ImagePopUp
              imgPath={`data:${photoFileInfo.mimeType};base64,${photoFileInfo.data}`}
              onClose={() => {
                setOpenMaximizeDialogPhoto(false);
              }}
            />
          )}
        </>
      ) : (
        <CircularProgress color="success" sx={loaderStyle} />
      )}
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </>
  );
};
