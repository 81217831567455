import MDBox from "components/MDBox";
import { QuestionAnswerComponent } from "./QuestionAnswerComponent";
import { GroupedQAComponent } from "./GroupedQAComponent";
import { useEffect, useState } from "react";
import {
  SurveyGroupWithStep,
  SurveyStepState,
  VueSurvey,
  VueSurveyData,
} from "../utils/vue_detail_interface";
import { getSurveyStepState } from "pages/dashboard/home/vues/services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { SurveyStep } from "@ivueit/vue-engine";

interface Props {
  surveyData: VueSurveyData;
  submissionId: string;
}

export const VueSurveyContent = ({ surveyData, submissionId }: Props) => {
  const [vueSurveyData, setVueSurveyData] = useState<VueSurveyData>();
  const { survey, groups } = surveyData;
  const { steps } = survey;

  const getSurveyContents = () => {
    const allSteps = vueSurveyData?.survey?.steps ?? [];

    if (allSteps.length <= 0) {
      return <></>;
    }

    var elementArray: JSX.Element[] = [];
    // Contains the group Ids for which the elements have already generated
    var existingGroups: string[] = [];
    allSteps.forEach((step) => {
      if (step.groupId.isEmpty()) {
        // Step is NOT part of any groups
        step.instances.forEach((instance) => {
          // Generating the survey question
          const nonGroupElement = getSurveyQuestionFromStep(step, instance);
          elementArray.push(nonGroupElement);
        });
      } else {
        // Finding group that contins current step
        const group = vueSurveyData.groups.find(
          (item) => item.id === step.groupId
        );

        // Ensures that the group is NOT already rendered
        if (group && !existingGroups.includes(group.id)) {
          // Traversing through each instance to display them as group
          step.instances.forEach((instance, index) => {
            const children = group.steps.map((groupStep) => {
              // Finding the correct answer for the step
              const answer = groupStep.instances[index];
              return getSurveyQuestionFromStep(groupStep, answer);
            });

            const groupedElement = (
              <GroupedQAComponent
                key={`${group.id}-${instance.groupInstanceIndex}`}
                groupName={`${group.groupTitle} ${
                  instance.groupInstanceIndex + 1
                }`}
                element={children}
              />
            );
            elementArray.push(groupedElement);
          });
          // Adds the current group id to the array, for filtering
          existingGroups.push(group.id);
        }
      }
    });

    return (
      <MDBox sx={{ pt: "10px", pb: "20px", px: "20px" }}>{elementArray}</MDBox>
    );
  };

  const getSurveyQuestionFromStep = (
    step: SurveyStep,
    stepState: SurveyStepState
  ): JSX.Element => {
    return stepState.visible ? (
      <QuestionAnswerComponent
        key={stepState.id}
        surveyStep={step}
        submissionId={submissionId}
        stepState={stepState}
      />
    ) : (
      <></>
    );
  };

  useEffect(() => {
    const updateSurvey = (steps: SurveyStep[]) => {
      var newSurvey = survey as VueSurvey;
      newSurvey.steps = steps;
      var groupsWithStepId: SurveyGroupWithStep[] = [];
      // Traversing through each group to find the steps that matches to the group
      groups.forEach((group) => {
        const stepsInTheGroup = steps.filter(
          (step) => step.groupId === group.id
        );
        // Updates the groups with step ID and convert the object model
        groupsWithStepId.push({ ...group, steps: stepsInTheGroup });
      });
      setVueSurveyData({ survey: newSurvey, groups: groupsWithStepId, });
    };

    const fetchSurveyStepState = async () => {
      var surveySteps: SurveyStep[] = [];
      const responses = await Promise.all(
        steps.map((step) => getSurveyStepState(submissionId, step.id))
      );
      responses.forEach((response, index) => {
        if (response.status === WebServiceStatus.success) {
          const step = steps[index];
          const { instances } = response.data;
          const stepInstances = instances as SurveyStepState[];
          step.instances = stepInstances;
          // Adding items into the survey step array, at same position
          surveySteps[index] = step;
          // Updates the suvery object once all steps are downloaded
          if (surveySteps.length === steps.length) {
            updateSurvey(surveySteps);
          }
        }
      });
    };

    fetchSurveyStepState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyData, submissionId]);

  return getSurveyContents();
};
