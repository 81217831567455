import { TEXTFIELD_CHANGE_DELAY, WebServiceStatus } from "@ivueit/vue-engine";
import {
  AutocompleteOption,
  CustomAutoCompleteComponent,
} from "components/CustomComponents/CustomAutoCompleteComponent";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import {
  SEARCH_ORG_USERS_PAGE_NUMBER,
  SEARCH_ORG_USERS_PAGE_SIZE,
} from "pages/surveys/utils/constants";
import { useState, useMemo, useCallback, useEffect } from "react";
import { searchOrgUsers } from "../services/services";

interface Props {
  user?: UserInfo;
  onSuccess: (user?: UserInfo) => void;
}

export const UsersSearchComponent = (props: Props) => {
  const { onSuccess, user } = props;
  const [searchText, setSearchText] = useState<string>("");
  const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<UserInfo[]>([]);
  const [userIsTyping, setUserIsTyping] = useState<boolean>(false);
  const userEmailsList: AutocompleteOption[] = useMemo(
    () =>
      usersList.map((user) => {
        return {
          id: user.id,
          value: user.email,
        };
      }),
    [usersList]
  );

  // This executes when an option is selected from the drop-down
  const handleOptionSelect = (
    selectedValue: AutocompleteOption,
    reason: string
  ) => {
    if (reason === "clear") {
      setSearchText("");
      onSuccess(null);
      return;
    }
    setUserIsTyping(false);
    const userEmail = selectedValue.value as string;
    const selectedUser = usersList.filter(
      (user) => user.email.toLowerCase() === userEmail.toLowerCase()
    )[0];
    onSuccess(selectedUser);
    setUsersList([selectedUser]);
  };

  const handleInputChange = (newValue: string) => {
    const sameUser = newValue.toLowerCase() === user?.email.toLowerCase() ?? "";
    if (sameUser) return;
    setUserIsTyping(true);
    setSearchText(newValue);
    if (!newValue) {
      setUsersList([]);
    }
  };

  const handleSearchUsers = useCallback(async () => {
    if (!searchText) {
      return;
    }
    setIsFetchingUsers(true);
    const parameters = `page.pageNumber=${SEARCH_ORG_USERS_PAGE_NUMBER}&page.pageSize=${SEARCH_ORG_USERS_PAGE_SIZE}&query=${searchText}`;
    const response = await searchOrgUsers(parameters);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.orgUsers as UserInfo[];
      setUsersList(data);
    } else {
      setUsersList([]);
    }
    setIsFetchingUsers(false);
  }, [searchText]);

  useEffect(() => {
    if (user) {
      setSearchText(user.email);
    } else {
      setSearchText("");
    }
  }, [user]);

  useEffect(() => {
    if (userIsTyping && searchText) {
      const delaySearch = setTimeout(() => {
        handleSearchUsers();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearch);
    }
  }, [handleSearchUsers, userIsTyping, searchText]);

  return (
    <CustomAutoCompleteComponent
      optionsList={userEmailsList}
      noOptionsText={!isFetchingUsers ? "No users available" : "Searching..."}
      value={
        user && {
          id: user.id,
          value: user.email,
        }
      }
      inputValue={searchText}
      onOptionSelect={(event, selectedValue, reason) => {
        handleOptionSelect(selectedValue, reason);
      }}
      onInputChange={(event, newValue, reason) => {
        handleInputChange(newValue);
      }}
      placeholderText="Enter user name"
      isLoading={isFetchingUsers}
    />
  );
};
