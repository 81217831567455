export const pdfLogoStyle = {
  height: "150px",
  width: "auto",
};

export const customTagItemStyle = {
  padding: "5px 10px 5px 0px",
};

export const clientAvatarContainerStyle = {
  width: "74px",
  height: "74px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const tagItemStyle = {
  display: "flex",
  alignItems: "center",
  flexBasis: "33.33%",
  flexShrink: "0",
  padding: "0px 30px 30px 30px",
  ":nth-child(3n + 1), :nth-child(3n + 0)": {
    background: "#F8F8F8",
  },
  ":nth-child(1), :nth-child(2), :nth-child(3)": {
    paddingTop: "30px",
  },
  ".MuiBadge-badge": {
    background: "#C7CCD0 !important",
    fontSize: "16px",
    color: "#191919",
  },
};

export const listSyles = {
  flex: "1",
  padding: "0px",
  display: "flex",
  flexWrap: "wrap",
};
