import { DialogContent, DialogActions, List, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  formControlStyles,
  regularTextStyle,
  scrollBarStyle,
  tableCellStyle,
  tableConStyle,
  tableHeadCellStyle,
  tableRowHeadStyle,
  tableRowStyle,
} from "../styles/SurveyStyles";
import Icon from "@mdi/react";
import {
  CustomDropdown,
  CustomDropdownOption,
  PlaceholderComponent,
  SurveyConditions,
  SurveyStep,
  SurveyStepType,
  getSurveyStepType,
} from "@ivueit/vue-engine";
import { mdiDelete, mdiPlus } from "@mdi/js";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import { VueSurveyData } from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";
import { useEffect, useState } from "react";
import {
  createSurveyCondition,
  deleteSurveyCondition,
  updateSurveyCondition,
} from "../services/SurveyServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { ConditionOperator } from "../types/interfaces";
import { canLinkTheStep, getSupportedOperatorList } from "../utils/helpers";
import {
  COMBINE_LOGIC_AND,
  COMBINE_LOGIC_OR,
  allOperators,
} from "../utils/constants";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";

// Defines an condition rule for a step
interface ConditionRule {
  stepConditionId: string;
  conditionLogic: string;
  conditionId: string;
  parentStepId: string;
  conditionOperator: string;
  conditionValue: string;
}

// Defines an condition rule for a step
interface ConditionOption {
  value: string;
  description: string;
}

// Contains all required data to update a conditon row
interface UpdateConditionRowParam {
  stepConditionId: string;
  condition: SurveyConditions;
  conditionLogic: string;
}

// Interface helps to create new condition, contains all required data for the condition
interface NewCondition {
  parentStepId: string;
  operator: string;
  conditionValue: string;
  supportedOperators: ConditionOperator[];
  supportedValues: string[];
}

interface Props {
  isReadOnly?: boolean;
  surveyData: VueSurveyData;
  selectedStep: SurveyStep;
  closeDialog: () => void;
  refreshSurveyData: () => void;
}

const ConditionsDialogContent = ({
  isReadOnly,
  surveyData,
  selectedStep,
  closeDialog,
  refreshSurveyData,
}: Props) => {
  const { stepConditions, conditions, survey } = surveyData;
  const { steps } = survey;

  const [apiInProgress, setAPIInProgress] = useState(false);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  // Defines the basic combine logic. Used to hold value for `ANY` or `ALL` options (COMBINE_LOGIC_AND/COMBINE_LOGIC_OR)
  const [combineLogic, setCombineLogic] = useState("AND");
  // Defines the condition logic used for the step
  const [conditionLogic, setConditionLogic] = useState("");
  // List contains the conditions added to the step
  const [conditionRules, setConditionRules] = useState<ConditionRule[]>([]);
  // Defines the parameters to update a condition
  // Setting value to this state triggers API call to update the parameter
  const [updateConditionParam, setUpdateConditionParam] =
    useState<UpdateConditionRowParam | null>(null);

  // Defines the parameters to create a new condition
  const [newConditionParam, setNewConditionParam] =
    useState<NewCondition | null>(null);

  useEffect(() => {
    const loadConditions = () => {
      var newConditionRules: ConditionRule[] = [];
      // let parentSelected: SurveyStep;
      // Filtering all step conditions to find out the condition for selected step
      const currentStepCondition = stepConditions.find(
        (stepCondition) => stepCondition.childStepId === selectedStep.id
      );
      if (currentStepCondition) {
        // Condition added for step. Updating the condition Logic
        setConditionLogic(currentStepCondition.conditionLogic);
        currentStepCondition.conditionIds.forEach((conditionId, index) => {
          conditions.forEach((condition) => {
            if (condition.id === conditionId) {
              newConditionRules = [
                ...newConditionRules,
                {
                  stepConditionId: currentStepCondition.id,
                  conditionLogic: currentStepCondition.conditionLogic,
                  conditionId: condition.id,
                  parentStepId: condition.parentStepId,
                  conditionOperator: condition.operator,
                  conditionValue: condition.value,
                },
              ];
            }
          });
        });
      }
      setConditionRules(newConditionRules);

      // Setting up the condition rules
      if (newConditionRules.length > 0) {
        // Setting the combine logic, when the page is loaded
        if (newConditionRules[0].conditionLogic.includes("OR")) {
          setCombineLogic("OR");
        } else if (newConditionRules[0].conditionLogic.includes("AND")) {
          setCombineLogic("AND");
        }
      }
    };
    loadConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyData]);

  // Useffect used to update the condition logic whenever there is a change in value
  useEffect(() => {
    // Updates survey condition
    const updateSurveyConditionRow = async () => {
      const { stepConditionId, condition, conditionLogic } =
        updateConditionParam;

      const parameters = {
        condition: condition,
        conditionLogic: conditionLogic,
      };
      setAPIInProgress(true);
      const response = await updateSurveyCondition(stepConditionId, parameters);
      if (response.status === WebServiceStatus.success) {
        refreshSurveyData();
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: response.error,
          isError: true,
        });
      }
      setAPIInProgress(false);
    };

    if (updateConditionParam) {
      updateSurveyConditionRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateConditionParam]);

  // Generating condition options with existing rule
  const getConditionOptions = (rule: ConditionRule) => {
    let conditionOptions: ConditionOption[] = [];
    survey.steps.forEach((thisStep) => {
      //Only allow conditions from fixed value step types, items before list, and/or by proper group association
      if (canLinkTheStep(selectedStep, thisStep)) {
        if (thisStep.id === rule.parentStepId) {
          conditionOptions.unshift({
            value: thisStep.id,
            description: thisStep.description,
          });
        } else if (thisStep.id !== selectedStep.id) {
          conditionOptions.push({
            value: thisStep.id,
            description: thisStep.description,
          });
        }
      }
    });
    return conditionOptions;
  };

  // Generating condition operators with existing rule
  const getConditionOperators = (rule: ConditionRule) => {
    let allOperators: ConditionOperator[] = [];
    const parentSelected = steps.find(
      (surveyStep) => surveyStep.id === rule.parentStepId
    );
    if (parentSelected) {
      allOperators = getSupportedOperatorList(parentSelected);
    }

    return allOperators;
  };

  // Generates the condition values based on the give parent step Id
  // Finds the parent survey step and generates the condition based on parent type
  const getConditionValues = (parentStepId: string) => {
    let conditionValues: string[] = [];
    // Parent step for the current condition rule
    const parentStepForCondition = survey.steps.find(
      (thisStep) => thisStep.id === parentStepId
    );
    // Identifying the step type
    const currentStepType = getSurveyStepType(parentStepForCondition.stepType);
    // generating condition values based on step type
    switch (currentStepType) {
      case SurveyStepType.select:
      case SurveyStepType.multiSelect:
        conditionValues = JSON.parse(parentStepForCondition.options).options;
        break;
      case SurveyStepType.bool:
        conditionValues = ["Yes", "No"];
        break;
      case SurveyStepType.slider:
        // Generating an array of numbers with Range slider.min & slider.max
        conditionValues = [
          ...Array(parentStepForCondition.sliderMax + 1).keys(),
        ]
          .slice(parentStepForCondition.sliderMin)
          .map((item) => item.toString()); // Converting the values to string
        break;
      default:
        break;
    }
    return conditionValues;
  };

  // Changes the parent by updating the question
  // Sets default values to the condition & answer options
  const updateParentSelection = async (
    rule: ConditionRule,
    parentStepId: string,
    index: number
  ) => {
    // finds the selected parent step
    const parentSelected = survey.steps.find(
      (thisStep) => thisStep.id === parentStepId
    );
    if (parentSelected) {
      // Fetches all available condition values
      const allValues = getConditionValues(parentStepId);
      // Setting the first option as default
      const conditionValue = allValues.length > 0 ? allValues[0] : "";
      // Contains the operators available for the selected parent
      const operatorsForParent = getSupportedOperatorList(parentSelected);
      const newOperator = `${operatorsForParent[0].serverValue}`;
      // Generating the parameters to update the condition
      const parameter: UpdateConditionRowParam = {
        condition: {
          id: rule.conditionId,
          parentStepId: parentStepId,
          operator: newOperator,
          value: conditionValue,
        },
        conditionLogic: conditionLogic,
        stepConditionId: rule.stepConditionId, // We are going to update this step condition
      };

      // Updating the state, will trigger the API call
      setUpdateConditionParam(parameter);

      var newConditionRules = [...conditionRules];
      // Updating values that have been changed
      newConditionRules[index] = {
        ...rule,
        parentStepId: parentStepId,
        conditionOperator: newOperator,
        conditionValue: conditionValue,
      };
      // Updating the condition rules
      setConditionRules(newConditionRules);
    }
  };

  // Changes the operator based on the selection
  const updateOperatorSelection = async (
    rule: ConditionRule,
    newOperator: string,
    index: number
  ) => {
    // Generating the parameters to update the condition
    const parameter: UpdateConditionRowParam = {
      condition: {
        id: rule.conditionId,
        parentStepId: rule.parentStepId,
        operator: newOperator,
        value: rule.conditionValue,
      },
      conditionLogic: conditionLogic,
      stepConditionId: rule.stepConditionId, // We are going to update this step condition
    };

    // Updating the state, will trigger the API call
    setUpdateConditionParam(parameter);

    var newConditionRules = [...conditionRules];
    // Updating values that have been changed
    newConditionRules[index] = {
      ...rule,
      conditionOperator: newOperator,
    };
    // Updating the condition rules
    setConditionRules(newConditionRules);
  };

  // Changes the condition value based on the selection
  const updateValueSelection = async (
    rule: ConditionRule,
    newValue: string,
    index: number
  ) => {
    // Generating the parameters to update the condition
    const parameter: UpdateConditionRowParam = {
      condition: {
        id: rule.conditionId,
        parentStepId: rule.parentStepId,
        operator: rule.conditionOperator,
        value: newValue,
      },
      conditionLogic: conditionLogic,
      stepConditionId: rule.stepConditionId, // We are going to update this step condition
    };

    // Updating the state, will trigger the API call
    setUpdateConditionParam(parameter);

    var newConditionRules = [...conditionRules];
    // Updating values that have been changed
    newConditionRules[index] = {
      ...rule,
      conditionValue: newValue,
    };
    // Updating the condition rules
    setConditionRules(newConditionRules);
  };

  //////// SERVICE CALLS ////////

  // Updates the condition logic with new combine logic
  const updateConditionLogic = async (newCombineLogic: string) => {
    // Logic update API calls only when it has child condition rules
    if (conditionRules.length <= 0) {
      return;
    }
    // replacing the condition logic with new
    var newConditionLogic = conditionLogic.replace(
      /AND/g,
      "" + newCombineLogic
    );
    newConditionLogic = newConditionLogic.replace(/OR/g, "" + newCombineLogic);
    setConditionLogic(newConditionLogic);

    const rule = conditionRules[0];
    // Generating the parameters to update the condition
    const parameter: UpdateConditionRowParam = {
      condition: {
        id: rule.conditionId,
        parentStepId: rule.parentStepId,
        operator: rule.conditionOperator,
        value: rule.conditionValue,
      },
      conditionLogic: newConditionLogic,
      stepConditionId: rule.stepConditionId,
    };

    // Updating the state, will trigger the API call
    setUpdateConditionParam(parameter);
  };

  // Removes the condition
  const removeCondition = async (rule: ConditionRule) => {
    const { conditionId, stepConditionId } = rule;

    var newConditionLogic = conditionLogic;
    // Removing all possible usage of conditionId and stepConditionId from the step
    //remove selcted condition id from condtion logic
    //conditionId not first in list
    newConditionLogic = newConditionLogic.replace(
      'AND "' + conditionId + '"',
      ""
    );
    newConditionLogic = newConditionLogic.replace(
      'OR "' + conditionId + '"',
      ""
    );
    //condtion id first in list
    newConditionLogic = newConditionLogic.replace(
      '"' + conditionId + '" AND ',
      ""
    );
    newConditionLogic = newConditionLogic.replace(
      '"' + conditionId + '" OR ',
      ""
    );
    //only condition id remaining
    newConditionLogic = newConditionLogic.replace('"' + conditionId + '"', "");
    // Updates the condition logic with new change
    setConditionLogic(newConditionLogic);
    setAPIInProgress(true);
    // Performs the deletion
    const response = await deleteSurveyCondition(stepConditionId, {
      conditionIdToDelete: conditionId,
      updatedConditionLogic: newConditionLogic,
    });

    if (response.status === WebServiceStatus.success) {
      refreshSurveyData();
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setAPIInProgress(false);
  };

  // Adds new condition to the step
  const addNewCondition = async () => {
    if (!canAddNewCondition()) {
      return;
    }

    const newConditionLogic = conditionLogic.isNotEmpty()
      ? `${conditionLogic} ${combineLogic} "newConditionId"`
      : `"newConditionId"`;

    const parameters = {
      condition: {
        operator: newConditionParam.operator,
        parentStepId: newConditionParam.parentStepId,
        value: newConditionParam.conditionValue,
      },
      conditionLogic: newConditionLogic,
    };
    setAPIInProgress(true);
    const response = await createSurveyCondition(selectedStep.id, parameters);
    if (response.status === WebServiceStatus.success) {
      refreshSurveyData();
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setAPIInProgress(false);
  };

  // Verifies whether we can add new condition to the page
  const canAddNewCondition = () => {
    if (!newConditionParam || newConditionParam?.conditionValue.isEmpty()) {
      setSnackbarContent({
        title: "Attention!",
        message: "Please complete all selections before adding new condition.",
        isError: true,
      });
      return false;
    } else if (newConditionParam.operator?.isEmpty()) {
      setSnackbarContent({
        title: "Attention!",
        message: "Please select an operator.",
        isError: true,
      });
      return false;
    } else if (newConditionParam.conditionValue?.isEmpty()) {
      setSnackbarContent({
        title: "Attention!",
        message: "Please select the condition value.",
        isError: true,
      });
      return false;
    }
    return true;
  };

  //////// COMPONENT GENERATION ////////

  // Generate header for conditions added to the list
  const getConditionListHeader = () => {
    return (
      <MDBox sx={tableConStyle}>
        <MDBox sx={tableRowHeadStyle}>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>QUESTION</MDTypography>
          </MDBox>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>CONDITION</MDTypography>
          </MDBox>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>ANSWER</MDTypography>
          </MDBox>
          {/* Empty placeholder to fill the header section*/}
          {!isReadOnly && (
            <MDBox
              sx={{
                ...tableHeadCellStyle,
                flexBasis: "80px",
                flexGrow: "0",
                flexShrink: "0",
              }}
            />
          )}
        </MDBox>
      </MDBox>
    );
  };

  // Generates the rows for conditions added for the step
  const getConditionListItems = () => {
    const components = conditionRules.map((rule, index) => {
      return (
        <MDBox
          key={`${rule.conditionId}-${index}`}
          sx={{
            ...tableRowStyle,
            textTransform: "none",
            "&:last-child": { borderBottom: 0 },
          }}
        >
          {/* Parent Select Option */}
          {generateParentSelectOption(rule, index)}
          {/* Operator Select Option */}
          {generateOperatorSelectOption(rule, index)}
          {/* Answer Select Option */}
          {generateAnswerSelectOption(rule, index)}
          {/* Delete Condition Button */}
          {!isReadOnly && (
            <MDBox
              sx={{
                ...tableCellStyle,
                flexBasis: "80px",
                justifyContent: "center",
                flexGrow: "0",
                flexShrink: "0",
              }}
            >
              <IconButton
                color="error"
                onClick={() => {
                  removeCondition(rule);
                }}
                disabled={apiInProgress}
              >
                <Icon path={mdiDelete} size={0.85} />
              </IconButton>
            </MDBox>
          )}
        </MDBox>
      );
    });

    return components;
  };

  // Generates the select box and it's options for Parent Condition
  const generateParentSelectOption = (rule: ConditionRule, index: number) => {
    // Generating condition options for parent select option
    const conditionOptions = getConditionOptions(rule);
    const selectedParent = conditionOptions.find(
      (option) => option.value === rule.parentStepId
    );
    // Updating currently selected item from the list
    const selectedItem = selectedParent
      ? {
          value: selectedParent.value,
          displayTitle: selectedParent.description,
        }
      : null;
    return (
      <MDBox sx={{ ...tableCellStyle, minWidth: 0 }}>
        <MDBox display="flex" flex={1} pr={2} minWidth="0">
          <CustomDropdown
            title={""}
            availableValues={conditionOptions.map((e) => {
              return { value: e.value, displayTitle: e.description };
            })}
            selectedItem={selectedItem}
            onChange={(newParent) => {
              const newParentId = newParent.value;
              updateParentSelection(rule, newParentId, index);
            }}
            prefixTitle={false}
            disabled={isReadOnly || apiInProgress}
          />
        </MDBox>
      </MDBox>
    );
  };

  // Generates the select box and it's options for Operator option
  const generateOperatorSelectOption = (rule: ConditionRule, index: number) => {
    const conditionOperators = getConditionOperators(rule);
    const availableValues = conditionOperators.map((operator) => {
      return { value: operator.serverValue, displayTitle: operator.value };
    });
    // finding the selected item from the list
    const selectedItem = availableValues.find(
      (operator) => operator.value === rule.conditionOperator
    );

    return (
      <MDBox sx={tableCellStyle}>
        <MDBox display="flex" flex={1} pr={2}>
          <CustomDropdown
            title={""}
            availableValues={availableValues}
            selectedItem={selectedItem}
            onChange={(newValue) => {
              const selectedOperator = newValue.value;
              updateOperatorSelection(rule, selectedOperator, index);
            }}
            prefixTitle={false}
            disabled={isReadOnly || apiInProgress}
          />
        </MDBox>
      </MDBox>
    );
  };

  // Generates the select box and answers
  const generateAnswerSelectOption = (rule: ConditionRule, index: number) => {
    // Fetching the condition values for the selected parent step
    const conditionValues = getConditionValues(rule.parentStepId);
    const availableOptions = conditionValues.map((op) => {
      return { value: op.toString(), displayTitle: op.toString() };
    });
    const selectedItem = availableOptions.find(
      (value) => value.value.toUpperCase() === rule.conditionValue.toUpperCase()
    );
    return (
      <MDBox sx={tableCellStyle}>
        <MDBox display="flex" flex={1} pr={2}>
          <CustomDropdown
            title={""}
            selectedItem={selectedItem}
            availableValues={availableOptions}
            onChange={async (newValue) => {
              updateValueSelection(rule, newValue.value, index);
            }}
            prefixTitle={false}
            disabled={isReadOnly || apiInProgress}
          />
        </MDBox>
      </MDBox>
    );
  };

  // Invokes when a new parent question is selected
  const onChangeNewQuestion = (parentStepId: string) => {
    // finds the selected parent step
    const parentSelected = survey.steps.find(
      (thisStep) => thisStep.id === parentStepId
    );
    if (parentSelected) {
      const allValues = getConditionValues(parentStepId);
      const conditionValue = allValues.length > 0 ? allValues[0] : "";
      // Contains the operators available for the selected parent
      const operatorsForParent = getSupportedOperatorList(parentSelected);
      const newOperator = `${operatorsForParent[0].serverValue}`;

      var conditionParam = { ...newConditionParam };
      conditionParam.parentStepId = parentStepId;
      conditionParam.operator = newOperator;
      conditionParam.conditionValue = conditionValue;
      conditionParam.supportedOperators = operatorsForParent;
      conditionParam.supportedValues = allValues;
      setNewConditionParam(conditionParam);
    }
  };

  // generate new question section
  const getNewQuestionSection = () => {
    // Finding out the available options
    const availableOptions = survey.steps
      .filter((stepToCheck) => {
        // Filters the array to the one that supports condition types
        return canLinkTheStep(selectedStep, stepToCheck);
      })
      .map((thisStep) => {
        return {
          value: thisStep.id,
          displayTitle: thisStep.description.toString(),
        };
      });

    const selectedItem = availableOptions.find(
      (option) => option.value === newConditionParam?.parentStepId
    );

    return (
      <MDBox flex={1} sx={{...formControlStyles, ".MuiMenuItem-root": {maxWidth: "70vw", whiteSpace: "normal !important"}}}>
        <MDTypography sx={regularTextStyle}>Select Question</MDTypography>
        <CustomDropdown
          title={""}
          availableValues={availableOptions}
          onChange={(newValue) => {
            const parentStepId = newValue.value;
            onChangeNewQuestion(parentStepId);
          }}
          prefixTitle={false}
          selectedItem={selectedItem}
        />
      </MDBox>
    );
  };

  // Generates the operator section
  const getNewOperatorSection = () => {
    const availableOperators =
      newConditionParam?.supportedOperators ?? allOperators;
    const availableOptions = availableOperators.map((op) => {
      return {
        value: op.serverValue.toString(),
        displayTitle: op.value.toString(),
      };
    });
    var selectedItem = availableOptions.find(
      (option) => option.value === newConditionParam?.operator
    );
    const disabled =
      !newConditionParam || newConditionParam?.parentStepId.isEmpty();
    return (
      <MDBox flex={1} sx={formControlStyles}>
        <MDTypography sx={regularTextStyle}>Select Condition</MDTypography>
        <CustomDropdown
          title={""}
          availableValues={availableOptions}
          onChange={(newValue) => {
            // Updates the condition param only when it's available
            if (newConditionParam) {
              var conditionParam = { ...newConditionParam };
              conditionParam.operator = newValue.value;
              setNewConditionParam(conditionParam);
            }
          }}
          prefixTitle={false}
          selectedItem={selectedItem}
          disabled={disabled || apiInProgress}
        />
      </MDBox>
    );
  };

  // Generates the new answer section
  const getNewAnswerSection = () => {
    const allValues = newConditionParam?.supportedValues ?? [];
    const availableOptions = allValues.map((value) => {
      return { value: value, displayTitle: value };
    });
    const selectedItem = availableOptions.find(
      (item) => item.value === newConditionParam?.conditionValue
    );
    const disabled =
      !newConditionParam || newConditionParam?.parentStepId.isEmpty();
    return (
      <MDBox flex={1} sx={formControlStyles}>
        <MDTypography sx={regularTextStyle}>Select Answer</MDTypography>
        <CustomDropdown
          title={""}
          availableValues={availableOptions}
          onChange={(newValue) => {
            // Updates the condition param only when it's available
            if (newConditionParam) {
              var conditionParam = { ...newConditionParam };
              conditionParam.conditionValue = newValue.value;
              setNewConditionParam(conditionParam);
            }
          }}
          prefixTitle={false}
          value={selectedItem}
          disabled={disabled || apiInProgress}
        />
      </MDBox>
    );
  };

  // generates the top most section that contains the combine logic dropdown
  const getCombineLogicSection = () => {
    return (
      <MDBox
        p={2.2}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        sx={{ borderBottom: "1px solid #C7CCD0" }}
      >
        <MDTypography variant="h6">
          Show "{selectedStep.description}" when{" "}
        </MDTypography>
        <MDBox sx={{ ".MuiInputBase-root": { minWidth: "unset" } }} mr={0.8}>
          <CustomDropdown
            title={""}
            availableValues={[COMBINE_LOGIC_AND, COMBINE_LOGIC_OR]}
            onChange={onChangeCombineLogic}
            prefixTitle={false}
            disabled={isReadOnly || apiInProgress}
            selectedItem={
              combineLogic === "AND" ? COMBINE_LOGIC_AND : COMBINE_LOGIC_OR
            }
          />
        </MDBox>
        <MDTypography variant="h6"> apply :</MDTypography>
      </MDBox>
    );
  };

  // Invoked when the combine logic has changed
  const onChangeCombineLogic = (option: CustomDropdownOption) => {
    setCombineLogic(option.value);
    updateConditionLogic(option.value);
  };

  return (
    <>
      {getCombineLogicSection()}
      <DialogContent sx={{ ...scrollBarStyle, minHeight: "40vh" }}>
        {/* Conditions Generation  */}
        <MDBox pb={3}>
          {getConditionListHeader()}
          {conditionRules.length > 0 ? (
            <List sx={tableConStyle}>{getConditionListItems()}</List>
          ) : (
            <MDBox mx="auto" pt="100px">
              <PlaceholderComponent
                label={"Please add a condition from the below list"}
                fontSize="16px"
              />
            </MDBox>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid #C7CCD0" }}>
        <MDBox flexGrow={1}>
          {!isReadOnly && (
            <MDBox mb={4} display="flex" alignItems="end">
              {getNewQuestionSection()}
              {getNewOperatorSection()}
              {getNewAnswerSection()}

              <MDBox>
                <StyledButtonWithIcon
                  sx={{ minWidth: "unset", px: "11px" }}
                  onClick={() => {
                    addNewCondition();
                  }}
                  disabled={apiInProgress}
                >
                  <Icon path={mdiPlus} size={1} />
                </StyledButtonWithIcon>
              </MDBox>
            </MDBox>
          )}
          <MDBox display="flex" justifyContent="end">
            <MDButton
              key={1}
              variant="outlined"
              color="dark"
              size="medium"
              onClick={closeDialog}
              sx={{
                borderColor: "#C7CCD0",
                padding: "11px 16px",
                fontSize: "14px",
                fontWeight: "bold",
                "&:hover": { borderColor: "#C7CCD0" },
              }}
              disabled={apiInProgress}
            >
              CLOSE
            </MDButton>
          </MDBox>
        </MDBox>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </>
  );
};

export default ConditionsDialogContent;
