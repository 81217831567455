import DashboardContentLayout from "layouts/DashboardContentLayout";
import { useLocation } from "react-router-dom";
import VuerDetailContent from "./VuerDetailContent";
import { VuerData } from "@ivueit/vue-engine";

const VuersDetail = () => {
  const location = useLocation();
  const vuer = location.state.item as VuerData;

  return (
    <DashboardContentLayout>
      <VuerDetailContent vuer={vuer} />
    </DashboardContentLayout>
  );
};

export default VuersDetail;
