import { DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";
import {
  dialogText,
  inputNumberStyle,
  regularTextStyle,
} from "../styles/SurveyStyles";
import { useCallback, useEffect, useState } from "react";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  createSurveyGroups,
  getSurveyGroups,
  updateSurveyGroups,
  updateSurveyTemplateStep,
} from "../services/SurveyServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { SurveyGroupResponse } from "../types/enums";
import { SurveyStep, searchBarStyle } from "@ivueit/vue-engine";
interface Props {
  step: SurveyStep;
  closeDialog: (doRefresh: boolean) => void;
}

const GroupDialogContent = (props: Props) => {
  const [groupData, setGroupData] = useState({
    title: "",
    minValue: "0",
    maxValue: "0",
  });
  const { title, minValue, maxValue } = groupData;
  // Currently unused
  const [showLoader, setShowLoader] = useState(false);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [error, setError] = useState({
    groupName: "",
    minValue: "",
    maxValue: "",
  });

  const validateGroupData = (): boolean => {
    const min = minValue.trim();
    const max = maxValue.trim();
    const minInt = parseInt(min);
    const maxInt = parseInt(max);
    if (!title.trim() || isNaN(minInt)) {
      return false;
    } else if (minInt < 0) {
      return false;
    } else if (maxInt < 0) {
      return false;
    } else if (maxInt > 0 && minInt > maxInt) {
      return false;
    }
    return true;
  };

  const onChangeMinValue = (newValue: string) => {
    let min = newValue;
    const max = parseInt(maxValue);
    setGroupData({ ...groupData, minValue: min });
    const errorMessage =
      !min || (max > 0 && parseInt(min) > max) ? "Enter a valid min value" : "";
    setError({
      ...error,
      minValue: errorMessage,
      maxValue: max > parseInt(min) && "",
    });
  };

  const onChangeMaxValue = (newValue: string) => {
    let max = newValue;
    const min = parseInt(minValue);
    setGroupData({ ...groupData, maxValue: max });
    const errorMessage =
      parseInt(max) > 0 && min > parseInt(max) ? "Enter a valid max value" : "";
    setError({
      ...error,
      maxValue: errorMessage,
      minValue: min < parseInt(max) && "",
    });
  };

  const onChangeGroupValue = (newGroupName: string) => {
    let title = newGroupName;
    setGroupData({ ...groupData, title: title });
    setError({
      ...error,
      groupName: !title ? "Group name must not be empty." : "",
    });
  };

  const getSurveyGroup = useCallback(async () => {
    const groupID = props.step.groupId ?? "";
    if (groupID) {
      setShowLoader(true);
      const response = await getSurveyGroups(groupID);
      if (response.status === WebServiceStatus.success) {
        const responseData = response.data as SurveyGroupResponse;
        const { groupTitle, minInstances, maxInstances } = responseData;
        setGroupData({
          title: groupTitle,
          minValue: minInstances.toString(),
          maxValue: maxInstances.toString(),
        });
      } else {
        setSnackBar({
          title: "Attention!",
          message: "Unable to fetch Group details.",
          isError: true,
        });
      }
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step.groupId]);

  const createGroup = async () => {
    setShowLoader(true);
    const params = {
      groupTitle: title,
      minInstances: minValue,
      maxInstances: maxValue ? maxValue : "0",
    };
    const response = await createSurveyGroups(params);
    if (response.status === WebServiceStatus.success) {
      const responseData = response.data as SurveyGroupResponse;
      const { id } = responseData;
      updateSurveyStep(id);
    } else {
      setSnackBar({
        title: "Attention!",
        message: "Unable to create Group.",
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const updateGroup = async () => {
    setShowLoader(true);
    const params = {
      groupTitle: title,
      minInstances: minValue,
      maxInstances: maxValue ? maxValue : "0",
    };
    const groupID = props.step.groupId ?? "";
    const response = await updateSurveyGroups(groupID, params);
    if (response.status === WebServiceStatus.success) {
      props.closeDialog(true);
    } else {
      setSnackBar({
        title: "Attention!",
        message: "Unable to update Group.",
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const updateSurveyStep = async (newGroupID: string) => {
    const stepID = props.step.id;
    if (stepID) {
      setShowLoader(true);
      const requestBody = { ...props.step, groupId: newGroupID };
      const response = await updateSurveyTemplateStep(stepID, requestBody);
      if (response.status === WebServiceStatus.success) {
        props.closeDialog(true);
      } else {
        setSnackBar({
          title: "Attention!",
          message: "Unable to update step with the newly created group.",
          isError: true,
        });
      }
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getSurveyGroup();
  }, [getSurveyGroup]);

  return (
    <>
      <DialogContent>
        <MDBox mt={3}>
          <MDBox mb={3}>
            <MDTypography
              variant="body"
              sx={{ ...dialogText, fontWeight: "500", marginBottom: "10px" }}
            >
              Two substitutions will be made on this text when displayed:
            </MDTypography>
            <MDTypography variant="body" sx={dialogText}>
              1 - All lone # characters will be replaced with the group instance
              number.
            </MDTypography>
            <MDTypography variant="body" sx={dialogText}>
              2 - All ## will be replaced with a # character.
            </MDTypography>
            <MDTypography
              variant="body"
              sx={{ ...dialogText, fontWeight: "500", marginTop: "10px" }}
            >
              Additionally, if this does not contain a lone # character, then a
              space and the group instance number will be automatically appended
              at the end.
            </MDTypography>
          </MDBox>
          <MDBox mb={1.4}>
            <MDBox display={"flex"}>
              <MDTypography
                variant="button"
                fontWeight="bold"
                sx={{ fontSize: 14 }}
              >
                {"Group Title"}
              </MDTypography>
              <RequiredFieldMarker />
            </MDBox>
            <MDInput
              placeholder="Group Title"
              error={error.groupName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeGroupValue(event.target.value);
              }}
              value={groupData.title}
              fullWidth
            />
            {error.groupName && (
              <MDTypography
                sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}
              >
                {error.groupName}
              </MDTypography>
            )}
          </MDBox>
          <MDBox display="flex">
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>
                Minimum Occurrences
              </MDTypography>
              <MDInput
                type="number"
                placeholder="Min"
                error={error.minValue}
                value={groupData.minValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChangeMinValue(event.target.value);
                }}
                sx={{
                  ...searchBarStyle,
                  ...inputNumberStyle,
                }}
                onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.blur()
                }
                fullWidth
              />
              {error.minValue && (
                <MDTypography
                  sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}
                >
                  {error.minValue}
                </MDTypography>
              )}
            </MDBox>
            <MDBox ml={1.2} flex={1}>
              <MDTypography sx={regularTextStyle}>
                Maximum Occurrences (0 for unlimited)
              </MDTypography>
              <MDInput
                type="number"
                placeholder="Max"
                error={error.maxValue}
                value={groupData.maxValue}
                sx={{
                  ...searchBarStyle,
                  ...inputNumberStyle,
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChangeMaxValue(event.target.value);
                }}
                onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.blur()
                }
                fullWidth
              />
              {error.maxValue && (
                <MDTypography
                  sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}
                >
                  {error.maxValue}
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog(false);
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            disabled={!validateGroupData()}
            onClick={() => {
              props.step.groupId ? updateGroup() : createGroup();
            }}
          >
            {props.step.groupId ? "UPDATE" : "CREATE"} GROUP
          </MDButton>
        </>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default GroupDialogContent;
