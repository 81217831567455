/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useLayoutEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from "layouts/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "layouts/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar } from "context";
import CustomLogoutButton from "pages/dashboard/components/CustomLogoutButton";
import { breadcrumbConfig } from "../../constants";

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

function DashboardNavbar({
  absolute = false,
  light = false,
  isMini = false,
}: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode } = controller;
  const [setOpenMenu] = useState<any>(false);
  const routesFromURL = useLocation().pathname.split("/").slice(1);
  // Hiding notification Icon. Can be removed when the feature is implemented
  const showNotificationIcon = false;

  /// Breadcrumbs
  const getBreadCrumbs = () => {
    for (const key in breadcrumbConfig) {
      if (routesFromURL.includes(key)) {
        const { routeItems, newlyExtendedItem } = breadcrumbConfig[key];
        var data: string[];
        if (routesFromURL.includes("media-hub")) {
          data =
            // Album detail screen
            routesFromURL.length > 2
              ? [...routeItems, routesFromURL[2]]
              : // Gallery screen
                [...routeItems];
        } else if (routesFromURL.length > 2) {
          data = [...routeItems, routesFromURL[1], newlyExtendedItem];
        } else if (routesFromURL.length > 1) {
          data = [...routeItems, routesFromURL[1]];
        } else {
          data = routeItems;
        }
        return data;
      }
    }
    return routesFromURL;
  };

  useLayoutEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = white.main;

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={navbarContainer}>
        <MDBox color="inherit" mb={{ xs: 0, md: 0 }} sx={() => navbarRow()}>
          <Breadcrumbs
            title={getBreadCrumbs()[getBreadCrumbs().length - 1]}
            route={getBreadCrumbs()}
            light={light}
          />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={() => navbarRow()}>
            {showNotificationIcon && (
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleOpenMenu}
                >
                  <MDBadge
                    badgeContent={0}
                    color="badgeError"
                    size="xs"
                    circular
                  >
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </MDBadge>
                </IconButton>
              </MDBox>
            )}
            <MDBox pl={3} sx={{ width: "fit-content" }}>
              <CustomLogoutButton />
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default DashboardNavbar;
