import { DialogActions, DialogContent } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import { useEffect, useState } from "react";

interface Props {
  company: Company;
  closeDialog: () => void;
  onSuccess: (updatedInfo: Company) => void;
}

const EditContactInfoDialogContent = (props: Props) => {
  const defaultErrorState = {
    accountManager: "",
    primaryEmail: "",
    billingEmail: "",
    address: "",
    accountManagerEmail: "",
    accountManagerPhoneNumber: "",
  };
  const [hasValidData, setHasValidData] = useState(true);
  const [companyInfo, setCompanyInfo] = useState(props.company);
  const [error, setError] = useState(defaultErrorState);
  const {
    accountManager,
    primaryEmail,
    billingEmail,
    address,
    accountManagerEmail,
    accountManagerPhoneNumber,
  } = companyInfo;

  useEffect(() => {
    const checkValidity = () => {
      let newError = { ...defaultErrorState };
      let isValid = true;

      if (!accountManager) {
        newError = {
          ...error,
          accountManager: "Please enter a valid account manager name.",
        };
        isValid = false;
      }
      if (
        !primaryEmail ||
        (primaryEmail.trim() !== props.company.primaryEmail.trim() &&
          !primaryEmail?.trim().isValidEmail())
      ) {
        newError = { ...error, primaryEmail: "Please enter a valid email." };
        isValid = false;
      }
      if (
        !billingEmail ||
        (billingEmail.trim() !== props.company.billingEmail.trim() &&
          !billingEmail?.trim().isValidEmail())
      ) {
        newError = { ...error, billingEmail: "Please enter a valid email." };
        isValid = false;
      }
      if (
        accountManagerEmail.trim() !==
          props.company.accountManagerEmail.trim() &&
        !accountManagerEmail?.trim().isValidEmail()
      ) {
        newError = {
          ...error,
          accountManagerEmail: "Please enter a valid email.",
        };
        isValid = false;
      }
      if (
        accountManagerPhoneNumber.trim() !==
          props.company.accountManagerPhoneNumber.trim() &&
        !accountManagerPhoneNumber
          ?.trim()
          .formatPhoneNumber()
          .isValidUSPhoneNumber()
      ) {
        newError = {
          ...error,
          accountManagerPhoneNumber: "Please enter a valid phone number.",
        };
        isValid = false;
      }

      setHasValidData(isValid);
      setError(newError);
    };
    // if (didChangeCompanyInfo()) {
    checkValidity();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo]);

  const didChangeCompanyInfo = () => {
    // Negating to mention that the company data is changed
    return !(
      accountManager.trim() === props.company.accountManager.trim() &&
      primaryEmail.trim() === props.company.primaryEmail.trim() &&
      billingEmail.trim() === props.company.billingEmail.trim() &&
      accountManagerEmail.trim() === props.company.accountManagerEmail.trim() &&
      accountManagerPhoneNumber.trim() ===
        props.company.accountManagerPhoneNumber.trim() &&
      address.trim() === props.company.address.trim()
    );
  };

  const getErrorTypography = (errorMessage: string) => {
    return (
      <MDTypography
        key={Math.random()}
        sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}
      >
        {errorMessage}
      </MDTypography>
    );
  };

  return (
    <>
      <DialogContent>
        <MDBox px={1.4} pt={1}>
          <MDBox pt={2}>
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>
                iVueit Account Manager
                <RequiredFieldMarker />
              </MDTypography>
              <MDInput
                value={accountManager}
                error={error.accountManager}
                placeholder="Enter Account Manager Name"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCompanyInfo({
                    ...companyInfo,
                    accountManager: event.target.value,
                  });
                }}
                fullWidth
              />
              {error.accountManager && getErrorTypography(error.accountManager)}
            </MDBox>
          </MDBox>
          <MDBox pt={2}>
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>
                iVueit Account Manager Email
              </MDTypography>
              <MDInput
                value={accountManagerEmail}
                error={error.accountManagerEmail}
                placeholder="Enter Account Manager Email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCompanyInfo({
                    ...companyInfo,
                    accountManagerEmail: event.target.value,
                  });
                }}
                fullWidth
              />
              {error.accountManagerEmail &&
                getErrorTypography(error.accountManagerEmail)}
            </MDBox>
          </MDBox>
          <MDBox pt={2}>
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>
                iVueit Account Manager Phone
              </MDTypography>
              <MDInput
                value={accountManagerPhoneNumber}
                error={error.accountManagerPhoneNumber}
                placeholder="Enter Account Manager Phone"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCompanyInfo({
                    ...companyInfo,
                    accountManagerPhoneNumber: event.target.value,
                  });
                }}
                fullWidth
              />
              {error.accountManagerPhoneNumber &&
                getErrorTypography(error.accountManagerPhoneNumber)}
            </MDBox>
          </MDBox>
          <MDBox pt={2}>
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>
                Primary Contact Email
                <RequiredFieldMarker />
              </MDTypography>
              <MDInput
                value={primaryEmail}
                error={error.primaryEmail}
                placeholder="Enter Primary Contact Email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCompanyInfo({
                    ...companyInfo,
                    primaryEmail: event.target.value,
                  });
                }}
                fullWidth
              />
              {error.primaryEmail && getErrorTypography(error.primaryEmail)}
            </MDBox>
          </MDBox>
          <MDBox pt={2}>
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>
                Billing Contact Email
                <RequiredFieldMarker />
              </MDTypography>
              <MDInput
                value={billingEmail}
                error={error.billingEmail}
                placeholder="Enter Billing Contact Email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCompanyInfo({
                    ...companyInfo,
                    billingEmail: event.target.value,
                  });
                }}
                fullWidth
              />
              {error.billingEmail && getErrorTypography(error.billingEmail)}
            </MDBox>
          </MDBox>
          <MDBox pt={2}>
            <MDBox flex={1}>
              <MDTypography sx={regularTextStyle}>Address</MDTypography>
              <MDInput
                value={address}
                error={error.address}
                placeholder="Enter Address"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCompanyInfo({
                    ...companyInfo,
                    address: event.target.value,
                  });
                }}
                fullWidth
                multiline
                rows="2"
              />
              {error.address && getErrorTypography(error.address)}
            </MDBox>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            disabled={!hasValidData || !didChangeCompanyInfo()}
            onClick={() => {
              props.onSuccess(companyInfo);
              props.closeDialog();
            }}
          >
            SAVE
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default EditContactInfoDialogContent;
